import React from 'react';

import { Draggable } from '@hello-pangea/dnd';

import Box from '@mui/material/Box';

import { CardProps } from 'src/pages/kanban/Card';
import CardContent from './CardContent';

const Card = (props: CardProps) => {
    const { card, index, onCardClick } = props;

    return (
        <Draggable draggableId={String(card.id)} index={index} isDragDisabled={card.has_incomplete_checklists}>
            {(provided, snapshot) => (
                <Box
                    onClick={() => onCardClick(card)}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    sx={{
                        '&:hover': {
                            cursor: card.has_incomplete_checklists ? 'pointer' : 'grab',
                        },
                    }}
                >
                    <CardContent card={card} isDragging={snapshot.isDragging} />
                </Box>
            )}
        </Draggable>
    );
};

export default Card;
