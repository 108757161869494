import React from 'react';

import dayjs from 'dayjs';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TollTip from '@mui/material/Tooltip';

import CircleIcon from '@mui/icons-material/FiberManualRecord';

import { TlContentProps } from './TlItemContent.d';

const Separator = () => (
    <CircleIcon
        sx={{
            fontSize: 5,
            color: '#777',
        }}
    />
);

const TlItemContent = (props: TlContentProps) => {
    const { date, column, user, icon: Icon, iconColor, children, text, tooltip } = props;

    const getContent = () => {
        if (text) {
            return <Typography variant={'body2'} children={text} />;
        }

        if (children) {
            return children;
        }
    };

    return (
        <Stack direction={'row'} spacing={2}>
            <TollTip arrow placement={'left'} title={tooltip}>
                <Avatar
                    sx={{
                        mt: 0.5,
                        width: 32,
                        height: 32,
                        bgcolor: iconColor,
                    }}
                >
                    <Icon fontSize={'small'} />
                </Avatar>
            </TollTip>

            <Stack direction={'column'} flex={1} spacing={0.2}>
                <Stack direction={'row'} spacing={0.75} alignItems={'center'}>
                    {'board' in props && props.board && (
                        <>
                            <Typography variant={'caption'} fontWeight={500} children={props.board.name} />
                            <Separator />
                        </>
                    )}

                    <Typography
                        variant={'caption'}
                        fontWeight={500}
                        children={dayjs(date).format('DD/MM/YYYY HH:mm')}
                    />
                    <Separator />

                    <Typography variant={'caption'} fontWeight={500} color={'text.secondary'} children={column.name} />
                    <Separator />

                    <Typography
                        variant={'caption'}
                        fontWeight={400}
                        color={'text.secondary'}
                        children={user.username}
                    />
                </Stack>

                {getContent()}
            </Stack>
        </Stack>
    );
};

export default TlItemContent;
