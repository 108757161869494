import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import CheckListIcon from '@mui/icons-material/Checklist';
import DescriptionIcon from '@mui/icons-material/FormatAlignJustify';
import HistoryIcon from '@mui/icons-material/History';
import TimelineIcon from '@mui/icons-material/Timeline';

import CardSection from 'src/pages/kanban/CardModal/CardSection';
import ChecklistSection from 'src/pages/kanban/CardModal/ChecklistSection';
import ColumnField from 'src/pages/kanban/CardModal/ColumnField';
import DescriptionField from 'src/pages/kanban/CardModal/DescriptionField';
import LabelsField from 'src/pages/kanban/CardModal/LabelsField';
import SectionDates from './SectionDates';
import SectionInfo from './SectionInfo';
import SectionResponsibles from './SectionResponsibles';
import TimelineSection from 'src/pages/kanban/CardModal/TlSection';

import { CardModalContentProps } from './CardModalContent.d';

const CardModalContent = (props: CardModalContentProps) => {
    const { boardType, boardId, cardId, cardDetail, timelineItems, refetchCard, setCardMode } = props;

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Stack direction={'column'} spacing={4}>
                    <Box sx={{ pl: 3.5 }}>
                        <ColumnField
                            boardId={boardId}
                            cardId={cardId}
                            column={cardDetail.column}
                            refetchCard={refetchCard}
                        />
                    </Box>
                    <Box pl={3.5}>
                        <LabelsField
                            boardId={boardId}
                            cardId={cardId}
                            cardLabels={cardDetail.labels}
                            refetchCard={refetchCard}
                        />
                    </Box>
                    <CardSection icon={DescriptionIcon} title={'Descrição'}>
                        <DescriptionField
                            boardId={boardId}
                            cardId={cardId}
                            description={cardDetail.description}
                            refetchCard={refetchCard}
                        />
                    </CardSection>
                    <CardSection icon={CheckListIcon} title={'Checklist'}>
                        <ChecklistSection
                            boardId={boardId}
                            cardId={cardId}
                            refetchCard={refetchCard}
                            current_checklists={cardDetail.current_checklists}
                        />
                    </CardSection>
                    <CardSection
                        icon={TimelineIcon}
                        title={'Timeline'}
                        actions={
                            <Tooltip arrow placement={'top'} title={'Visualizar histórico completo da entidade.'}>
                                <Button
                                    size={'small'}
                                    color={'secondary'}
                                    onClick={() => setCardMode('log')}
                                    startIcon={<HistoryIcon />}
                                    children={'Completo'}
                                />
                            </Tooltip>
                        }
                    >
                        <TimelineSection
                            boardId={boardId}
                            timeline={timelineItems}
                            cardId={cardId}
                            refetchCard={refetchCard}
                        />
                    </CardSection>
                </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
                <Stack direction={'column'} spacing={1}>
                    <SectionInfo boardType={boardType} boardId={boardId} card={cardDetail} refetchCard={refetchCard} />
                    <SectionDates boardId={boardId} card={cardDetail} refetchCard={refetchCard} />
                    <SectionResponsibles boardId={boardId} card={cardDetail} refetchCard={refetchCard} />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CardModalContent;
