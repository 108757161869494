import React, { useRef, useState } from 'react';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { ColumnFieldProps } from './ColumnField.d';
import ColumnMoveMenu from './ColumnMoveMenu';

const ColumnField = (props: ColumnFieldProps) => {
    const { cardId, boardId, column, refetchCard } = props;

    /**
     * COLUMN MENU MODAL
     */
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLAnchorElement | null>(null);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack
                direction={'row'}
                spacing={0.6}
                alignItems={'center'}
                sx={{
                    pt: 0.5,
                    color: '#555',
                }}
            >
                <Typography variant={'body2'} children={'na coluna'} />
                <Typography
                    ref={anchor}
                    component={Link}
                    variant={'body2'}
                    color={'inherit'}
                    onClick={() => setOpen(true)}
                    sx={{
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        textDecorationColor: '#BBB',
                    }}
                >
                    {column ? column.name : <Skeleton animation={'wave'} />}
                </Typography>
            </Stack>

            <ColumnMoveMenu
                anchor={anchor.current}
                open={open}
                onClose={() => {
                    refetchCard();
                    setOpen(false);
                }}
                boardId={boardId}
                cardId={cardId}
                column={column}
            />
        </>
    );
};

export default ColumnField;
