import React, { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import useTenantAPI from 'src/services/api_tenant';
import CrudTransferSelect from 'src/components/crud/fields/CrudTransferSelect';

import { PermissionsDialogProps } from './PermissionsDialog.d';

const PermissionsDialog = (props: PermissionsDialogProps) => {
    const { open, setOpen, modalEntity } = props;

    const api_tenant = useTenantAPI();

    /**
     * HANDLE SEARCH PERMISSIONS
     */
    const [values, setValues] = useState({
        id: modalEntity?.id ?? null,
        permissions: [],
    });

    useEffect(() => {
        if (open && modalEntity && modalEntity.id) {
            setValues({
                id: modalEntity?.id ?? null,
                permissions: [],
            });
        }
    }, [modalEntity]);

    /**
     * HANDLE PERMISSION MODAL BUTTONS
     */
    const handleModalClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        api_tenant
            .patch(`/api/permissions/profiles/${modalEntity.id}/permissions/`, values)
            .then(() => {
                enqueueSnackbar('Permissões atualizadas com sucesso!', { variant: 'success' });
                handleModalClose();
            })
            .catch(() => {
                enqueueSnackbar('Erro ao atualizar permissões!', { variant: 'error' });
            });
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} maxWidth={'lg'} fullWidth>
            <DialogTitle textAlign={'center'} children={`Permissões de ${modalEntity?.name ?? ''}`} />

            <DialogContent>
                <Stack
                    sx={{
                        width: '100%',
                        minWidth: {
                            xs: '300px',
                            sm: '360px',
                            md: '400px',
                        },
                        gap: '1.5rem',
                        mt: 2,
                    }}
                >
                    <CrudTransferSelect
                        values={values}
                        setValues={setValues}
                        column={{
                            accessorKey: 'permissions',
                            header: 'Permissões',
                            enableEditing: true,
                            field: {
                                type: 'transfer',
                                itemsEndpoint: (values) => `/api/permissions/profiles/${values.id}/permissions/`,
                                descriptionsEndpoint: '/api/permissions/descriptions/',
                            },
                        }}
                    />
                </Stack>
            </DialogContent>

            <DialogActions
                sx={{
                    p: '1.25rem',
                }}
            >
                <Button onClick={handleModalClose} children={'Fechar'} />
                <Button variant={'contained'} onClick={handleSubmit} children={'Salvar'} />
            </DialogActions>
        </Dialog>
    );
};

export default PermissionsDialog;
