import useTenantAPI from 'src/services/api_tenant';
import { ModuleList } from './modules.d';

/**
 * Identifier for the modules data on sessionStorage
 * @type {string}
 */
export const MODULES_KEY = '@ecotrack-modules';

/**
 * Set modules data in sessionStorage
 * @param {string} modules - The modules data to be stored in sessionStorage
 */
export const setModulesData = (modules: ModuleList) => {
    sessionStorage.setItem(MODULES_KEY, JSON.stringify(modules));
};

/**
 * Get modules data from sessionStorage
 * @returns {string | null} - Returns the modules data if present in sessionStorage, null otherwise
 */
export const getCachedModulesData = () => {
    const data = sessionStorage.getItem(MODULES_KEY);
    return data ? JSON.parse(data) : null;
};

/**
 * Remove modules data from sessionStorage
 */
export const removeModulesData = () => {
    sessionStorage.removeItem(MODULES_KEY);
};

/**
 * Check if the enableKey is enabled
 * @param {string} enableKey - The enableKey name
 * @returns {boolean} - Returns true if the enableKey is enabled, false otherwise
 */
export const isModuleEnabled = (enableKey: string) => {
    const modulesData = getCachedModulesData();
    return modulesData && modulesData[enableKey];
};

/**
 * Get modules data from sessionStorage or API
 * @returns {string | null} - Returns the modules data if present in sessionStorage, null otherwise
 */
export const getModulesData = async () => {
    let modulesData = getCachedModulesData();

    if (modulesData == null) {
        modulesData = await fetchModulesData();
    }

    return modulesData;
};

/**
 * Fetch modules data from the API
 */
export const fetchModulesData = async () => {
    const api_tenant = useTenantAPI();
    return await api_tenant
        .get('/api/companies/modules/')
        .then((res) => {
            setModulesData(res.data.results);
            return res.data.results;
        })
        .catch((err) => {
            removeModulesData();
        });
};
