import React, { MouseEvent, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Key from '@mui/icons-material/Key';

import useTenantAPI from 'src/services/api_tenant';

import Breadcrumb from 'src/components/Breadcrumb';

const UserSettings = () => {
    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState<Record<string, any>>({});
    const [errors, setErrors] = useState<Record<string, any>>({});

    /**
     * GET USER DATA ON PAGE LOAD
     */
    useEffect(() => {
        api_tenant.get('api/users/my/').then((res) =>
            setValues({
                username: res.data.username,
                email: res.data.email,
            })
        );
    }, []);

    /**
     * EVENT HANDLERS
     */
    const handleSubmit = async () => {
        try {
            await api_tenant.patch('api/users/my/', values);
            enqueueSnackbar('Informações atualizadas.', { variant: 'success' });
            setErrors({});
        } catch (error: any) {
            setErrors(error.response.data);
            enqueueSnackbar(`Erro ao atualizar informações.`, { variant: 'error' });
        }
    };

    const handleCancel = () => {
        window.location.reload();
    };

    /**
     * PASSWORD MODAL CONTROL
     */
    const [passwordValues, setPasswordValues] = useState({ password: '' });
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowPassword(true);
    };

    const handleMouseUpPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowPassword(false);
    };

    const handleMouseDownPasswordConfirm = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowPasswordConfirm(true);
    };

    const handleMouseUpPasswordConfirm = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowPasswordConfirm(false);
    };

    const handlePasswordSubmit = async () => {
        try {
            await api_tenant.patch('api/users/my/', passwordValues);
            setOpenPasswordModal(false);
            enqueueSnackbar('Senha atualizada.', { variant: 'success' });
            setErrors({});
        } catch (error: any) {
            setErrors(error.response.data);
            enqueueSnackbar(`Erro ao atualizar senha.`, { variant: 'error' });
        }
    };

    const handlePasswordCancel = () => {
        setOpenPasswordModal(false);
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: { xs: 0, md: 1 } }}>
                    <Breadcrumb items={['Usuário', 'Configurações']} />
                </Box>
            </Stack>

            <Divider sx={{ mb: 3, bgcolor: 'secondary.light' }} />

            <Stack alignItems={'center'}>
                <Grid container spacing={2} sx={{ width: { xs: '100%', md: '80%', xl: '60%' } }}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant={'h5'} sx={{ mb: 2 }}>
                            Meu usuário
                        </Typography>
                        <Typography>As informações do seu perfil.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <Stack
                                sx={{
                                    width: '100%',
                                    minWidth: { xs: '300px', sm: '360px', md: '400px' },
                                    gap: '1.5rem',
                                }}
                            >
                                <TextField
                                    sx={{ width: { sx: '100%', md: '50%' } }}
                                    type="text"
                                    key={'username'}
                                    label={'Usuário'}
                                    name={'username'}
                                    value={values.username || ''}
                                    onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                                    error={errors?.hasOwnProperty('username') ?? false}
                                    helperText={errors ? errors['username'] ?? '' : ''}
                                />
                                <TextField
                                    type="email"
                                    key={'email'}
                                    label={'E-mail'}
                                    name={'email'}
                                    value={values.email || ''}
                                    onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                                    error={errors?.hasOwnProperty('email') ?? false}
                                    helperText={errors ? errors['email'] ?? '' : ''}
                                />
                                <Stack direction={'row'} gap={2}>
                                    <Button variant="outlined" color="primary" onClick={handleCancel}>
                                        Cancelar
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                                        Salvar
                                    </Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 4, bgcolor: 'secondary.light' }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ width: { xs: '100%', md: '80%', xl: '60%' } }}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant={'h5'} sx={{ mb: 2 }}>
                            Crendênciais
                        </Typography>
                        <Typography>Altere sua senha sempre que precisar.</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setOpenPasswordModal(true);
                            }}
                        >
                            <Key sx={{ mr: 2 }} />
                            Alterar Senha
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 4, bgcolor: 'secondary.light' }} />
                    </Grid>
                </Grid>
            </Stack>

            <Dialog open={openPasswordModal}>
                <DialogTitle textAlign="center">Alterar Senha</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                                gap: '1.5rem',
                                mt: 2,
                            }}
                        >
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                key={'password'}
                                label={'Senha'}
                                name={'password'}
                                onChange={(e) =>
                                    setPasswordValues({
                                        ...passwordValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onMouseUp={handleMouseUpPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={errors?.hasOwnProperty('password') ?? false}
                                helperText={errors ? errors.password ?? '' : ''}
                            />
                            <TextField
                                type={showPasswordConfirm ? 'text' : 'password'}
                                key={'password_confirm'}
                                label={'Confirmação'}
                                name={'password_confirm'}
                                onChange={(e) =>
                                    setPasswordValues({
                                        ...passwordValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onMouseUp={handleMouseUpPasswordConfirm}
                                                onMouseDown={handleMouseDownPasswordConfirm}
                                            >
                                                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={errors?.hasOwnProperty('password_confirm') ?? false}
                                helperText={errors ? errors.password_confirm ?? '' : ''}
                            />
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={handlePasswordCancel}>Cancelar</Button>
                    <Button onClick={handlePasswordSubmit} variant="contained" color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserSettings;
