import React, { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';

import { DriverCard } from 'src/pages/kanban/Kanban.d';
import { Column as CrudColumn } from 'src/components/crud/Crud.d';
import { DriverCardCreateModalProps } from './DriverCardCreateModal.d';

import { endpoint as personEndpoint, name as personName, Person } from 'src/pages/registry/persons/Persons';
import {
    columns as vehicleColumns,
    endpoint as vehicleEndpoint,
    name as vehicleName,
    Vehicle,
} from 'src/pages/registry/vehicles';

const name = {
    singular: 'Card de Motorista',
    plural: 'Cards de Motorista',
};

const formatPayload = (fd: FormData, id: number, values: Record<string, any>) => {
    fd.append('name', values.person.full_name);
    return fd;
};

const personColumns = [
    {
        accessorKey: 'first_name',
        header: 'Nome',
    },
    {
        accessorKey: 'last_name',
        header: 'Sobrenome',
    },
    {
        accessorKey: 'integration_id',
        header: 'ID de Integração',
        filterLookup: 'exact',
    },
    {
        accessorKey: 'instructors',
        header: 'Instrutores',
        hideColumn: true,
        field: {
            type: 'search',
            multiple: true,
            name: 'instructors',
            endpoint: '/api/persons/',
            default: [],
            labelKey: (option: Person) => (option ? `${option.first_name} ${option.last_name}` : ''),
            queryParams: {
                is_instructor: true,
            },
        },
    },
] as CrudColumn<Person>[];

const DriverCardCreateModal = (props: DriverCardCreateModalProps) => {
    const { boardId, open, setOpen, refetch } = props;

    /**
     * PERSON MODAL
     */
    const [personOpen, setPersonOpen] = useState(false);

    /**
     * VEHICLE MODAL
     */
    const [vehicleOpen, setVehicleOpen] = useState(false);

    /**
     * COLUMNS
     */
    const addCardColumns: CrudColumn<DriverCard>[] = useMemo(
        () => [
            {
                accessorKey: 'person',
                header: 'Pessoa',
                field: {
                    type: 'search',
                    name: 'person',
                    endpoint: 'api/persons/',
                    labelKey: 'full_name',
                    default: '',
                    inputAction: {
                        label: <AddIcon />,
                        onClick: () => setPersonOpen(true),
                    },
                },
            },
            {
                accessorKey: 'vehicle',
                header: 'Veículo',
                field: {
                    type: 'search',
                    name: 'vehicle',
                    endpoint: 'api/vehicles/',
                    labelKey: (op: Vehicle) => (op ? `${op.integration_id} - ${op.license_plate}` : ''),
                    default: '',
                    inputAction: {
                        label: <AddIcon />,
                        onClick: () => setVehicleOpen(true),
                    },
                },
            },
        ],
        []
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            {/** CREATE CARD MODAL */}
            <CreateUpdateModal
                // STRUCTURE
                name={name}
                endpoint={`api/kanban/boards/${boardId}/cards/create/`}
                columns={addCardColumns}
                // MODAL CONTROL
                open={open}
                setOpen={setOpen}
                // ACTIONS
                refetch={refetch}
                formatPayload={formatPayload}
            />

            {/** PERSON MODAL */}
            <CreateUpdateModal
                // STRUCTURE
                name={personName}
                endpoint={personEndpoint}
                columns={personColumns}
                // MODAL CONTROL
                open={personOpen}
                setOpen={setPersonOpen}
                // ACTIONS
                refetch={refetch}
            />

            {/** VEHICLE MODAL */}
            <CreateUpdateModal
                // STRUCTURE
                name={vehicleName}
                endpoint={vehicleEndpoint}
                columns={vehicleColumns}
                // MODAL CONTROL
                open={vehicleOpen}
                setOpen={setVehicleOpen}
                // ACTIONS
                refetch={refetch}
            />
        </>
    );
};

export default DriverCardCreateModal;
