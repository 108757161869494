import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';

import useTenantAPI from 'src/services/api_tenant';
import { ApiSelectProps } from 'src/components/ApiSelect';

const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'hasAction',
})<{ hasAction: boolean }>(({ hasAction }) => ({
    ...(hasAction
        ? {
              '& .MuiOutlinedInput-root': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
              },
          }
        : {}),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    textTransform: 'lowercase',
}));

const ApiSelect = (props: ApiSelectProps) => {
    const {
        endpoint,
        label,
        placeholder,
        onChange,
        value,
        error,
        helperText,
        multiple,
        getOptionLabel,
        readOnly,
        required,
        inputAction,
        queryParams,
        size = 'medium',
        variant = 'outlined',
    } = props;

    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const typingTimeoutRef = useRef<any>(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (searchText === '' || (value && getOptionLabel(value) === searchText)) {
            setLoading(false);
            return;
        }

        setLoading(true);

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(async () => {
            const api_tenant = useTenantAPI();
            const response = await api_tenant.get(endpoint, {
                params: {
                    ...queryParams,
                    search: searchText,
                },
            });
            setOptions(response.data.results);
            setLoading(false);
        }, 750);

        return () => {
            clearTimeout(typingTimeoutRef.current);
        };
    }, [searchText, endpoint]);

    const handleInputChange = (event: SyntheticEvent, newSearchText: string) => {
        setSearchText(newSearchText);
    };

    return (
        <Autocomplete
            size={size}
            multiple={multiple}
            filterOptions={(x) => x}
            openText={'Abrir'}
            closeText={'Fechar'}
            clearText={'Remover'}
            loadingText={'Carregando...'}
            noOptionsText={searchText !== '' ? 'Sem opções para o filtro.' : 'Pesquisar...'}
            loading={loading}
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => getOptionLabel(option)}
            value={value}
            onInputChange={handleInputChange}
            onChange={onChange}
            disabled={readOnly}
            renderInput={(params) => (
                <FormGroup row>
                    <StyledTextField
                        {...params}
                        size={size}
                        label={label}
                        placeholder={placeholder}
                        variant={variant}
                        required={required && (multiple ? value.length === 0 : !value)}
                        error={error}
                        helperText={helperText}
                        hasAction={!!inputAction}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color={'inherit'} size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        sx={{
                            flex: 10,
                        }}
                    />

                    {inputAction && (
                        <StyledButton
                            variant={'contained'}
                            disableElevation
                            sx={{
                                backgroundColor: '#444',
                                '&:hover': {
                                    backgroundColor: '#111',
                                },
                            }}
                            onClick={inputAction.onClick}
                        >
                            {inputAction.label}
                        </StyledButton>
                    )}
                </FormGroup>
            )}
        />
    );
};

export default ApiSelect;
