import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { NavItemProps } from 'src/components/sidebar/NavItem';

import { canAccessRoute } from 'src/services/storage/permissions';
import { isModuleEnabled } from 'src/services/storage/modules';

const NavItem = (props: NavItemProps) => {
    const { title, route, icon: Icon, image, onClick, setShow, permissions = [], superuser = false } = props;

    const navigate = useNavigate();

    // TODO: Cache this information
    if (route.enable_key && !isModuleEnabled(route.enable_key)) {
        return null;
    }

    if (!canAccessRoute(permissions, superuser, route)) {
        return null;
    }

    const handleClick = () => {
        if (onClick) {
            onClick();
            return;
        }

        setShow(false);

        if (route.path) {
            navigate(route.path);
        }
    };

    return (
        <Button
            disableElevation
            color={'secondary'}
            onClick={handleClick}
            sx={{
                minHeight: 40,
                maxHeight: 40,
                display: 'flex',
                justifyContent: 'start',
                gap: 1,
                '&:hover': {
                    backgroundColor: '#2e3650',
                },
            }}
        >
            {Icon && <Icon sx={{ color: 'white', fontSize: '0.9rem' }} />}
            {image && <img src={image} width={20} height={20} alt={'sidebar'} />}
            <Typography
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                fontSize={'0.9rem'}
                sx={{
                    color: 'white',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                }}
            >
                {title}
            </Typography>
        </Button>
    );
};

export default NavItem;
