import React, { SyntheticEvent, useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FilterIcon from '@mui/icons-material/FilterList';

import useTenantAPI from 'src/services/api_tenant';

import TlItem from 'src/pages/kanban/CardModal/TlItem';
import ApiSelectField from 'src/components/ApiSelect';

import { TlContentProps } from './TlContent.d';
import { ColumnBase, EntityTimelineItem, TimelineItem, UserBase } from 'src/pages/kanban/Kanban.d';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export const TIMELINE_TYPES = {
    all: 'Todos',
    register: 'Registros',
    move: 'Movimentações',
    labelset: 'Etiquetas',
    memberset: 'Membros',
    log: 'Edições',
    reminder: 'Lembretes',
};

const TlContent = (props: TlContentProps) => {
    const { timeline, endpoint, columnsEndpoint } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * FILTER VALUES CONTROL
     */
    const [user, setUser] = useState<UserBase | ''>('');
    const [column, setColumn] = useState<ColumnBase | ''>('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [type, setType] = useState<string>('all');

    /**
     * FILTER STATE CONTROL
     */
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredTimeline, setFilteredTimeline] = useState<TimelineItem[]>(timeline);

    useEffect(() => {
        setFilteredTimeline(timeline);
    }, [timeline]);

    const fetchTimeline = async (newType: string = type) => {
        setIsLoading(true);

        if (newType != type) {
            setType(newType);
        }

        let params: Record<string, any> = {
            user__id: user ? user.id : null,
            column__id: column ? column.id : null,
            item_type: newType === 'all' ? null : newType,
            date__gte: startDate ? dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss') : null,
            date__lte: endDate ? dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss') : null,
        };

        // remove null filters
        params = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== null));

        return api_tenant
            .get(endpoint, { params })
            .then((res) => setFilteredTimeline(res.data.results))
            .catch((err) => enqueueSnackbar('Erro ao filtrar.', { variant: 'error' }))
            .finally(() => setIsLoading(false));
    };

    /**
     * ITEM FIELD
     */
    const Item = (itemProps: { item: TimelineItem | EntityTimelineItem }) => {
        const { item } = itemProps;

        if ('boardId' in props && 'cardId' in props && 'refetchCard' in props) {
            return (
                <TlItem
                    key={item.id}
                    item={item}
                    boardId={props.boardId}
                    cardId={props.cardId}
                    refetchCard={props.refetchCard}
                />
            );
        }

        if ('board' in item && item.board) {
            return <TlItem key={item.id} item={item} board={item.board} />;
        }

        return <></>;
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Stack direction={'row'} alignItems={'center'} spacing={0.5} flexWrap={'wrap'}>
                    <Typography
                        variant={'body2'}
                        sx={{
                            pr: 0.5,
                            color: 'text.secondary',
                        }}
                        children={'Mostrar:'}
                    />
                    {Object.entries(TIMELINE_TYPES).map(([key, value]) => (
                        <Button
                            key={key}
                            size={'small'}
                            color={'secondary'}
                            variant={type === key ? 'contained' : 'outlined'}
                            onClick={() => fetchTimeline(type !== key ? key : 'all')}
                            sx={{
                                fontSize: '0.7rem',
                                minWidth: 'auto',
                                py: 0.1,
                                px: 0.75,
                            }}
                            children={value}
                        />
                    ))}
                    {isLoading && <CircularProgress size={14} />}
                </Stack>

                <Button
                    size={'small'}
                    variant={'text'}
                    color={'secondary'}
                    startIcon={<FilterIcon />}
                    onClick={() => setOpen(!open)}
                    children={'Filtros'}
                />
            </Stack>

            <TransitionGroup>
                {open && (
                    <Collapse>
                        <Stack
                            sx={{
                                p: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 1,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction={'row'} spacing={2}>
                                        <DateTimePicker
                                            value={startDate ? dayjs(startDate) : null}
                                            onChange={(e) => setStartDate(e?.toDate() ?? null)}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    variant: 'standard',
                                                    placeholder: 'Data Inicial',
                                                },
                                            }}
                                            sx={{
                                                flex: 1,
                                            }}
                                        />
                                        <Typography children={'até'} />
                                        <DateTimePicker
                                            value={endDate ? dayjs(endDate) : null}
                                            onChange={(e) => setEndDate(e?.toDate() ?? null)}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    variant: 'standard',
                                                    placeholder: 'Data Final',
                                                },
                                            }}
                                            sx={{
                                                flex: 1,
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ApiSelectField
                                        size={'small'}
                                        placeholder={'Usuário'}
                                        endpoint={'/api/users/'}
                                        queryParams={{
                                            is_instructor: true,
                                        }}
                                        value={user}
                                        onChange={(e: SyntheticEvent, val: UserBase) => setUser(val)}
                                        getOptionLabel={(op: UserBase) => op?.username ?? ''}
                                        variant={'standard'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <ApiSelectField
                                        size={'small'}
                                        placeholder={'Coluna'}
                                        endpoint={columnsEndpoint}
                                        value={column}
                                        onChange={(e: SyntheticEvent, val: ColumnBase) => setColumn(val)}
                                        getOptionLabel={(op: ColumnBase) => op?.name ?? ''}
                                        variant={'standard'}
                                    />
                                </Grid>
                            </Grid>
                            <Divider
                                sx={{
                                    mt: 2,
                                }}
                            />
                            <Stack
                                direction={'row'}
                                justifyContent={'flex-end'}
                                spacing={1}
                                sx={{
                                    pt: 2,
                                }}
                            >
                                <Button
                                    size={'small'}
                                    variant={'text'}
                                    onClick={() => {
                                        setUser('');
                                        setColumn('');
                                        setType('all');
                                        setStartDate(null);
                                        setEndDate(null);
                                    }}
                                    children={'Limpar'}
                                />
                                <Button
                                    size={'small'}
                                    variant={'contained'}
                                    disabled={isLoading}
                                    startIcon={isLoading && <CircularProgress size={16} />}
                                    onClick={() => fetchTimeline()}
                                    children={'Aplicar'}
                                />
                            </Stack>
                        </Stack>
                    </Collapse>
                )}
            </TransitionGroup>

            <Stack spacing={3} sx={{ pt: 2 }}>
                {filteredTimeline.map((item) => (
                    <Item key={item.id} item={item} />
                ))}
            </Stack>
        </>
    );
};

export default TlContent;
