import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import useTenantAPI from 'src/services/api_tenant';

import ActionsMenu from 'src/pages/kanban/ActionsMenu';
import CardBoard from 'src/pages/kanban/CardBoard';
import CardCreateModal from 'src/pages/kanban/CardCreateModal';
import CardFiltersMenu from 'src/pages/kanban/CardFiltersMenu';
import CardModal from 'src/pages/kanban/CardModal';
import SearchField from 'src/pages/kanban/SearchField';

import { Card, Column } from 'src/pages/kanban/Kanban.d';
import { KanbanBoardProps } from './KanbanBoard.d';

const KanbanBoard = (props: KanbanBoardProps) => {
    const { boardType, initialFilters } = props;

    const { boaId } = useParams();
    const api_tenant = useTenantAPI();

    /**
     * ADD CARD MODAL CONTROL
     */
    const [addCardOpen, setAddCardOpen] = useState(false);

    /**
     * UPDATE CARD MODAL CONTROL
     */
    const [updateCardOpen, setUpdateCardOpen] = useState(true);
    const [updateCardEntity, setUpdateCardEntity] = useState<Card | null>(null);

    const handleOpenCard = (card: Card) => {
        setUpdateCardEntity(card);
        setUpdateCardOpen(true);
    };

    /**
     * CARD COLUMNS FILTER
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const [cardFilters, setCardFilters] = useState(initialFilters);

    // SET FILTERS ON CHANGE
    useEffect(() => {
        const params: Record<string, string> = {};

        if (cardFilters.search) {
            params.search = cardFilters.search;
        }

        if (cardFilters.responsibles?.length) {
            params.responsibles__id__in = cardFilters.responsibles?.join(',');
        }

        if (cardFilters.labels?.length) {
            params.labels__id__in = cardFilters.labels?.join(',');
        }

        if (cardFilters.closest_date?.length) {
            params.closest_date__in = cardFilters.closest_date?.join(',');
        }

        setSearchParams(
            axios.getUri({
                url: '',
                params: params,
                paramsSerializer: {
                    indexes: null,
                },
            })
        );
    }, [cardFilters]);

    /**
     * CARD COLUMNS LOAD
     */
    const fetchCardColumns = async () => {
        return await api_tenant
            .get(`api/kanban/boards/${boaId}/cards/`, {
                params: searchParams,
            })
            .then((res) =>
                res.data.results.map((column: Column) => ({
                    ...column,
                    cards: column.cards.map((card: Card) => ({
                        ...card,
                        subtitle: 'vehicle' in card && card.vehicle ? `Frota: ${card.vehicle.integration_id}` : '',
                    })),
                }))
            );
    };

    const {
        refetch,
        data: cardColumns,
        isLoading: cardColumnsLoad,
        isError: cardColumnsError,
    } = useQuery({
        queryKey: ['card-columns', boaId],
        queryFn: fetchCardColumns,
        enabled: !!boaId,
    });

    useEffect(() => {
        if (boaId) {
            refetch();
        }
    }, [searchParams]);

    /**
     * RENDER COMPONENT
     */
    if (!boaId) {
        return <></>;
    }

    if (cardColumnsError) {
        return (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '60vh' }}>
                <Typography variant={'body2'}>Erro ao carregar dados.</Typography>
            </Stack>
        );
    }

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-end'}
                sx={{
                    my: 1,
                }}
            >
                <Stack direction={'row'} alignItems={'center'}>
                    <IconButton onClick={() => refetch()} size={'small'}>
                        <RefreshIcon fontSize={'small'} />
                    </IconButton>
                    <CircularProgress
                        size={15}
                        sx={{
                            visibility: cardColumnsLoad ? 'visible' : 'hidden',
                        }}
                    />
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <CardFiltersMenu
                        boardId={parseInt(boaId)}
                        apiFilters={cardFilters}
                        setApiFilters={setCardFilters}
                    />
                    <SearchField filters={cardFilters} setFilters={setCardFilters} />

                    <Divider flexItem orientation={'vertical'} />

                    <ActionsMenu boardId={parseInt(boaId)} handleOpenCard={handleOpenCard} />
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        startIcon={<AddIcon />}
                        onClick={() => setAddCardOpen(true)}
                        children={'Novo'}
                    />
                </Stack>
            </Stack>

            <CardBoard boardId={boaId} cardColumns={cardColumns} refetch={refetch} onCardClick={handleOpenCard} />

            {/** CREATE CARD MODAL */}
            <CardCreateModal
                boardId={parseInt(boaId)}
                open={addCardOpen}
                setOpen={setAddCardOpen}
                refetch={refetch}
                boardType={boardType}
            />

            {/** UPDATE CARD MODAL */}
            {updateCardEntity && (
                <CardModal
                    open={updateCardOpen}
                    setOpen={setUpdateCardOpen}
                    boardType={boardType}
                    boardId={parseInt(boaId)}
                    cardSummary={updateCardEntity}
                    refetch={refetch}
                />
            )}
        </>
    );
};

export default KanbanBoard;
