import React, { SyntheticEvent, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToolTip from '@mui/material/Tooltip';

import AddResponsibleIcon from '@mui/icons-material/PersonAdd';
import RemoveIcon from '@mui/icons-material/Close';

import ApiSelectField from 'src/components/ApiSelect';
import CardAccordion from 'src/pages/kanban/CardModal/CardAccordion';
import { PersonBase } from 'src/pages/kanban/Kanban.d';
import { SectionResponsiblesProps } from './SectionResponsibles.d';
import useTenantAPI from 'src/services/api_tenant';

const SectionResponsibles = (props: SectionResponsiblesProps) => {
    const { boardId, card: originalCard, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * RESPONSIBLES CONTROL
     */
    const responsibles = originalCard.responsibles;

    /**
     * ADD RESPONSIBLE FIELD CONTROL
     */
    const [isAdding, setIsAdding] = useState(false);
    const [newResponsable, setNewResponsable] = useState<PersonBase | ''>('');

    const submitAddResponsible = () => {
        if (!newResponsable) {
            return Promise.resolve();
        }

        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${originalCard.id}/responsibles/`, {
                person: newResponsable.id,
            })
            .then((res) => res.data);
    };

    const { mutateAsync: handleAddResponsible, isLoading: addResponsibleLoading } = useMutation(submitAddResponsible, {
        onSuccess: () => {
            refetchCard();
            setNewResponsable('');
            setIsAdding(false);
        },
        onError: () => enqueueSnackbar('Erro ao adicionar responsável.', { variant: 'error' }),
    });

    /**
     * REMOVE RESPONSIBLE CONTROL
     */

    const submitRemoveResponsible = (person: PersonBase) => {
        return api_tenant
            .delete(`api/kanban/boards/${boardId}/cards/${originalCard.id}/responsibles/`, {
                data: {
                    person: person.id,
                },
            })
            .then((res) => res.data);
    };

    const { mutateAsync: handleRemoveResponsible, isLoading: removeResponsibleLoading } = useMutation(
        submitRemoveResponsible,
        {
            onSuccess: () => {
                refetchCard();
            },
            onError: () => enqueueSnackbar('Erro ao remover responsável.', { variant: 'error' }),
        }
    );

    return (
        <CardAccordion title={'Reponsáveis'}>
            <Stack spacing={2}>
                {responsibles?.map((person, index) => (
                    <TextField
                        fullWidth
                        size={'small'}
                        label={`Responsável ${index + 1}`}
                        value={person.full_name}
                        key={person.id}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={'end'}>
                                    <ToolTip title={'Remover Responsável'}>
                                        <IconButton
                                            size={'small'}
                                            onClick={() => handleRemoveResponsible(person)}
                                            edge={'end'}
                                            disabled={removeResponsibleLoading}
                                        >
                                            {removeResponsibleLoading ? <CircularProgress size={16} /> : <RemoveIcon />}
                                        </IconButton>
                                    </ToolTip>
                                </InputAdornment>
                            ),
                        }}
                    />
                ))}

                {isAdding ? (
                    <>
                        <ApiSelectField
                            size={'small'}
                            label={'Selecione o Responsável'}
                            endpoint={'/api/persons/'}
                            value={newResponsable}
                            onChange={(event: SyntheticEvent, value: PersonBase) => setNewResponsable(value)}
                            multiple={false}
                            getOptionLabel={(op: PersonBase) => (op ? `${op.full_name}` : '')}
                            queryParams={{
                                is_instructor: true,
                            }}
                        />

                        <Stack direction={'row'} justifyContent={'end'}>
                            {newResponsable && (
                                <Button
                                    disabled={addResponsibleLoading}
                                    variant={'contained'}
                                    size={'small'}
                                    onClick={() => handleAddResponsible()}
                                >
                                    {addResponsibleLoading && <CircularProgress size={16} />}
                                    Adicionar
                                </Button>
                            )}
                        </Stack>
                    </>
                ) : (
                    <Button
                        fullWidth
                        color={'secondary'}
                        onClick={() => setIsAdding(true)}
                        startIcon={<AddResponsibleIcon />}
                        children={'Adicionar Responsável'}
                    />
                )}
            </Stack>
        </CardAccordion>
    );
};

export default SectionResponsibles;
