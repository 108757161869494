import { Route, Routes } from './appRoutes.d';

const AppRoutes = {
    // public
    Presentation: { path: '/' },

    // auth
    Login: { path: '/login' },

    // user
    UserSettings: {
        path: '/usuario/configs',
        allowAny: true,
    },

    // reports
    AchievementsReport: {
        path: '/painel/conquistas',
        module: 'achievements',
        object: 'achievementrecord',
    },
    EventsReport: {
        path: '/painel/eventos',
        module: 'telemetry',
        object: 'eventrecord',
    },
    RankingReport: {
        path: '/painel/ranking',
        module: 'performances',
        object: 'performance',
        enable_key: 'enable_module_ranking',
    },

    // dashboards
    DashboardGroup: {
        path: '/dashboards/:group',
        allowAny: true,
    },
    Dashboard: {
        path: '/dashboard/:slug',
        allowAny: true,
    },

    // registers
    Achievements: {
        path: '/conquistas',
        module: 'achievements',
        object: 'achievement',
    },
    Goals: {
        path: '/metas',
        module: 'metrics',
        object: 'goal',
    },
    Groups: {
        path: '/grupos',
        module: 'persons',
        object: 'group',
    },
    Persons: {
        path: '/pessoas',
        module: 'persons',
        object: 'person',
    },
    Allocations: {
        path: '/trocas-de-motorista',
        module: 'persons',
        object: 'allocation',
    },
    Enterprises: {
        path: '/empresas',
        module: 'businesses',
        object: 'enterprise',
    },
    Vehicles: {
        path: '/veiculos',
        module: 'vehicles',
        object: 'vehicle',
    },
    Places: {
        path: '/locais',
        module: 'locations',
        object: 'place',
    },

    // access
    Users: {
        path: '/usuarios',
        module: 'users',
        object: 'user',
    },
    PermissionProfiles: {
        path: '/acessos/perfis',
        module: 'permissions',
        object: 'profile',
    },
    DashboardProfiles: {
        path: '/dashboards/perfis',
        module: 'dashboards',
        object: 'profile',
    },

    // checklists
    Checklists: {
        path: '/checklists',
        module: 'checklists',
        object: 'checklist',
        enable_key: 'enable_module_checklists',
    },
    ChecklistsRequest: {
        path: '/checklists/:checklistId/solicitacoes',
        module: 'checklists',
        object: 'checklistrequest',
        enable_key: 'enable_module_checklists',
    },
    ChecklistsResponse: {
        path: '/',
        module: 'checklists',
        object: 'checklistresponse',
        enable_key: 'enable_module_checklists',
    },

    // marketplace
    Products: {
        path: '/marketplace/produtos',
        module: 'marketplace',
        object: 'product',
        enable_key: 'enable_module_marketplace',
    },
    ProductsPhotos: {
        path: '/',
        module: 'marketplace',
        object: 'productphoto',
        enable_key: 'enable_module_marketplace',
    },
    Purchases: {
        path: '/marketplace/compras',
        module: 'marketplace',
        object: 'purchase',
        enable_key: 'enable_module_marketplace',
    },

    // kanban
    Kanban: {
        path: '/kanban/quadros/',
        module: 'kanban',
        object: 'board',
        enable_key: 'enable_module_kanban',
    },
    KanbanBoard: {
        path: '/kanban/quadros/:boaId',
        module: 'kanban',
        object: 'board',
        enable_key: 'enable_module_kanban',
    },
    KanbanProfiles: {
        path: '/kanban/perfis',
        module: 'kanban',
        object: 'profile',
        enable_key: 'enable_module_kanban',
    },

    // settings
    Contacts: {
        path: '/contatos',
        module: 'contacts',
        object: 'settings',
    },
    Departments: {
        path: '/contatos/departamentos',
        module: 'contacts',
        object: 'department',
    },
    Events: {
        path: '/eventos',
        module: 'telemetry',
        object: 'settings',
    },
    FuelTypes: {
        path: '/combustiveis',
        module: 'fuels',
        object: 'fueltype',
    },
    Settings: {
        path: '/configs',
        superuser: true,
    },
    Settlements: {
        path: '/acertos',
        module: 'settlements',
        object: 'settings',
    },
    AdvanceEvents: {
        path: '/adiantamentos',
        module: 'advances',
        object: 'settings',
    },

    // error
    NotFound: { path: '/404' },
    ServerError: { path: '/500' },
} as Routes;

export const getPermissionLabel = (route: Route, action: string) => {
    if (route.permissions) {
        if (route.permissions[action]) {
            return route.permissions[action];
        }
    }

    if (route.module && route.object) {
        return `${route.module}.${action}_${route.object}`;
    }

    return null;
};

export default AppRoutes;
