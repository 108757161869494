import React from 'react';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';

import { User } from 'src/pages/registry/persons/Persons';
import { Profile } from 'src/pages/access/profiles/permissions';
import { Columns } from 'src/components/crud/Crud.d';

import { AccessPermissionsModalProps } from './AccessPermissionsModal.d';

import { endpoint as userEndpoint } from 'src/pages/access/Users';

export const name = {
    singular: 'Permissões de Acesso',
    plural: 'Permissões de Acesso',
};

export const columns: Columns<User> = [
    {
        accessorKey: 'tenant_user.profiles',
        header: 'Perfis de Acesso',
        enableEditing: true,
        field: {
            type: 'search',
            name: 'tenant_user.profiles',
            endpoint: '/api/permissions/profiles/',
            labelKey: 'name',
            multiple: true,
            default: [],
        },
    },
    {
        accessorKey: 'tenant_user.user_permissions',
        header: 'Permissões do Usuário',
        enableEditing: true,
        field: {
            type: 'transfer',
            itemsEndpoint: (user) => `/api/permissions/users/${user.id}/permissions/`,
            descriptionsEndpoint: '/api/permissions/descriptions/',
        },
    },
];

const AccessPermissionsModal = (props: AccessPermissionsModalProps) => {
    const { open, setOpen, user } = props;

    /**
     * SUBMIT CONTROL
     */
    const formatPayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
        return {
            tenant_user: {
                user_permissions: values.tenant_user.user_permissions,
                profiles: values.tenant_user.profiles.map((p: Profile) => p.id),
            },
        };
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <CreateUpdateModal
            // STRUCTURE
            name={name}
            endpoint={userEndpoint}
            columns={columns}
            // MODAL CONTROL
            open={open}
            setOpen={setOpen}
            entity={user}
            // ACTIONS
            formatPayload={formatPayload}
            // VISUAL
            size={'lg'}
        />
    );
};

export default AccessPermissionsModal;
