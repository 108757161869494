import React, { Dispatch, SetStateAction } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import { Achievement } from 'src/pages/registry/achievements';

const HasDayStreakField = (
    values: Achievement,
    setValues: Dispatch<SetStateAction<Achievement>>,
    errors: any,
    readOnly: boolean
) => {
    if (values['is_cumulative'] ?? false) {
        values['has_day_streak'] = false;
        values['day_streak'] = 0;
        return;
    }

    return (
        <FormControl key={'has-day-streak-field'} error={errors?.hasOwnProperty('has_day_streak') ?? false}>
            <FormControlLabel
                sx={{
                    pb: 0,
                    pt: 0,
                    mb: 0,
                    mt: 0,
                }}
                label={'Meta de dias consecutivos'}
                control={
                    <Checkbox
                        sx={{
                            pb: 0,
                            pt: 0,
                            mb: 0,
                            mt: 0,
                        }}
                        name={'has_day_streak'}
                        checked={values['has_day_streak'] ? true : false}
                        disabled={readOnly}
                        onChange={(e) => {
                            setValues({
                                ...values,
                                has_day_streak: e.target.checked,
                            });
                        }}
                    />
                }
            />
            {errors?.hasOwnProperty('has_day_streak') ? (
                <FormHelperText>{errors['has_day_streak']}</FormHelperText>
            ) : (
                <></>
            )}
        </FormControl>
    );
};

export default HasDayStreakField;
