import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import EditIcon from '@mui/icons-material/Edit';
import LabelIcon from '@mui/icons-material/Label';
import MemberIcon from '@mui/icons-material/Person';
import MoveIcon from '@mui/icons-material/CompareArrows';
import ReminderIcon from '@mui/icons-material/Alarm';

import { TlItemProps } from './TlItem.d';
import TlItemRegister from 'src/pages/kanban/CardModal/TlItemRegister';
import TlItemContent from 'src/pages/kanban/CardModal/TlItemContent';

const TlItem = (props: TlItemProps) => {
    const { item } = props;

    const editable = 'boardId' in props && 'cardId' in props;

    /**
     * RENDER COMPONENT
     */
    if (item.type == 'register') {
        if (!editable) {
            return <TlItemRegister key={item.id} item={item} board={props.board} />;
        }

        return (
            <TlItemRegister
                key={item.id}
                item={item}
                boardId={props.boardId}
                cardId={props.cardId}
                refetchCard={props.refetchCard}
            />
        );
    }

    if (item.type == 'move') {
        return (
            <TlItemContent
                column={item.column}
                date={item.date}
                user={item.user}
                icon={MoveIcon}
                iconColor={'green'}
                board={'board' in props ? props.board : undefined}
                text={`Movido para a coluna ${item.new_column.name}`}
            />
        );
    }

    if (item.type == 'labelset') {
        const actionVerbose = item.action == 'ADD' ? 'Adicionada' : 'Removida';
        return (
            <TlItemContent
                column={item.column}
                date={item.date}
                user={item.user}
                icon={LabelIcon}
                iconColor={'orange'}
                board={'board' in props ? props.board : undefined}
                text={`${actionVerbose} a etiqueta ${item.label.name}`}
            />
        );
    }

    if (item.type == 'memberset') {
        const actionVerbose = item.action == 'ADD' ? 'Adicionado' : 'Removido';
        return (
            <TlItemContent
                column={item.column}
                date={item.date}
                user={item.user}
                icon={MemberIcon}
                iconColor={'red'}
                board={'board' in props ? props.board : undefined}
                text={`${actionVerbose} o membro ${item.member.full_name}`}
            />
        );
    }

    if (item.type == 'reminder') {
        const actionVerbose = item.action == 'ADD' ? 'Adicionado' : 'Removido';
        return (
            <TlItemContent
                column={item.column}
                date={item.date}
                user={item.user}
                icon={ReminderIcon}
                iconColor={'purple'}
                board={'board' in props ? props.board : undefined}
                text={`${actionVerbose} o lembrete ${item.reminder.description}`}
            />
        );
    }

    if (item.type == 'log') {
        const fields = Object.keys(item.changes);
        return (
            <TlItemContent
                column={item.column}
                date={item.date}
                user={item.user}
                icon={EditIcon}
                iconColor={'gray'}
                board={'board' in props ? props.board : undefined}
            >
                <Typography variant={'body2'}>
                    {'Alteração dos campos: '}
                    {fields.map((field) => {
                        const old_value = item.changes[field][0];
                        const new_value = item.changes[field][1];
                        const old_value_is_empty = old_value === '' || old_value === null || old_value === 'None';
                        const new_value_is_empty = new_value === '' || new_value === null || new_value === 'None';
                        const is_last = field === fields[fields.length - 1];

                        return (
                            <Tooltip
                                arrow
                                key={field}
                                title={
                                    <Typography variant={'caption'} sx={{ whiteSpace: 'pre-line' }}>
                                        <b>Valor Anterior:</b> {old_value_is_empty ? 'vazio' : old_value}
                                        <br />
                                        <b>Valor Atualizado:</b> {new_value_is_empty ? 'vazio' : new_value}
                                    </Typography>
                                }
                                children={
                                    <Typography
                                        variant={'body2'}
                                        component={'span'}
                                        children={`${field}${is_last ? '' : ', '}`}
                                    />
                                }
                            />
                        );
                    })}
                </Typography>
            </TlItemContent>
        );
    }

    return <></>;
};

export default TlItem;
