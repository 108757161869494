import React from 'react';
import { enqueueSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import GroupAddIcon from '@mui/icons-material/GroupAdd';

import ApiSelect from 'src/components/ApiSelect';
import useTenantAPI from 'src/services/api_tenant';

import { GroupAddFieldProps } from './GroupAddField.d';

const GroupAddField = (props: GroupAddFieldProps) => {
    const { personGroupsEntity, groupLoad, fetchPersonGroups, setGroupLoad, groupSearch, setGroupSearch } = props;

    const api_tenant = useTenantAPI();

    const handleSubmit = async () => {
        setGroupLoad(true);

        if (!groupSearch) {
            enqueueSnackbar('Selecione um grupo!', { variant: 'warning' });
            setGroupLoad(false);
            return;
        }

        api_tenant
            .put(`/api/persons/groups/${groupSearch.id}/members/`, {
                person: personGroupsEntity.id,
            })
            .then(async () => {
                await fetchPersonGroups(personGroupsEntity.id);
                setGroupSearch(null);
            })
            .catch(() => {
                enqueueSnackbar('Erro ao inserir usuário no grupo!', { variant: 'error' });
            })
            .finally(() => {
                setGroupLoad(false);
            });
    };

    return (
        <Grid container spacing={1} alignItems={'strech'} justifyContent={'space-between'}>
            <Grid item xs>
                <ApiSelect
                    label={'Grupo'}
                    endpoint={'/api/persons/groups/'}
                    value={groupSearch as Record<string, any>}
                    onChange={(e, val) => setGroupSearch(val)}
                    getOptionLabel={(option) => (option?.name ? option?.name ?? '' : '')}
                />
            </Grid>
            <Grid
                item
                xs={2}
                sx={{
                    display: 'flex',
                    alignItems: 'strech',
                }}
            >
                <Button onClick={handleSubmit} variant={'contained'} disabled={groupLoad} fullWidth>
                    {groupLoad ? (
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <GroupAddIcon />
                    )}
                </Button>
            </Grid>
        </Grid>
    );
};

export default GroupAddField;
