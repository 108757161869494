import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudPage from 'src/components/crud/CrudPage';

import { Column } from 'src/components/crud/Crud.d';
import { Enterprise } from './Enterprises.d';

export const name = {
    singular: 'Empresa',
    plural: 'Empresas',
};

export const columns = [
    {
        accessorKey: 'name',
        header: 'Nome',
    },
    {
        accessorKey: 'cnpj',
        header: 'CNPJ',
    },
    {
        accessorKey: 'is_customer',
        header: 'É Cliente',
        field: {
            type: 'check',
        },
    },
    {
        accessorKey: 'is_supplier',
        header: 'É Fornecedor',
        field: {
            type: 'check',
        },
    },
    {
        accessorKey: 'is_active',
        header: 'Ativo',
        field: {
            type: 'check',
            default: true,
        },
    },
] as Column<Enterprise>[];

export const endpoint = '/api/businesses/';

const EnterprisesPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: { xs: 0, md: 1 } }}>
                    <Breadcrumb items={['Cadastros', 'Empresas']} />
                </Box>
            </Stack>

            <CrudPage name={name} endpoint={endpoint} route={AppRoutes.Enterprises} columns={columns} />
        </>
    );
};

export default EnterprisesPage;
