import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import useTenantAPI from 'src/services/api_tenant';

import { Group } from 'src/pages/registry/persons/Persons/Persons.d';

import GroupAddField from './GroupAddField';
import GroupListTable from './GroupListTable';
import { AddGroupsModalProps } from './AddGroupsModal.d';

const AddGroupsModal = (props: AddGroupsModalProps) => {
    const { open, setOpen, personGroupsEntity } = props;

    const api_tenant = useTenantAPI();

    /**
     * HANDLE SEARCH GROUPS
     */
    const [groupLoad, setGroupLoad] = useState(false);
    const [groupSearch, setGroupSearch] = useState<Group | null>(null);
    const [groupList, setGroupList] = useState<Group[]>([]);

    /**
     * HANDLE GROUP MODAL BUTTONS
     */
    const handleGroupsModalClose = () => {
        setOpen(false);
        setGroupSearch(null);
        setGroupList([]);
    };

    /**
     * FETCH GROUPS FROM PERSON
     */
    async function fetchPersonGroups(id: number) {
        api_tenant
            .get(`/api/persons/${id}/`)
            .then((res) => {
                setGroupList(res.data.results[0].groups);
            })
            .catch(() => {
                enqueueSnackbar('Erro ao carregar grupos da pessoa!', { variant: 'error' });
            });
    }

    useEffect(() => {
        if (open && personGroupsEntity && personGroupsEntity.id) {
            fetchPersonGroups(personGroupsEntity.id);
        }
    }, [personGroupsEntity]);

    return (
        <Dialog open={open}>
            <DialogTitle textAlign={'center'}>{`Grupos de ${personGroupsEntity?.full_name ?? ''}`}</DialogTitle>
            <DialogContent>
                <Stack
                    sx={{
                        width: '100%',
                        minWidth: {
                            xs: '300px',
                            sm: '360px',
                            md: '400px',
                        },
                        gap: '1.5rem',
                        mt: 2,
                    }}
                >
                    <GroupAddField
                        personGroupsEntity={personGroupsEntity}
                        fetchPersonGroups={fetchPersonGroups}
                        groupLoad={groupLoad}
                        setGroupLoad={setGroupLoad}
                        groupSearch={groupSearch}
                        setGroupSearch={setGroupSearch}
                    />
                    <Container
                        disableGutters={true}
                        sx={{
                            border: 1,
                            borderRadius: 2,
                            borderColor: 'grey.500',
                            overflow: 'auto',
                            height: '50vh',
                        }}
                    >
                        <GroupListTable
                            groupList={groupList}
                            fetchPersonGroups={fetchPersonGroups}
                            personGroupsEntity={personGroupsEntity}
                            setGroupLoad={setGroupLoad}
                        />
                    </Container>
                </Stack>
            </DialogContent>
            <DialogActions
                sx={{
                    p: '1.25rem',
                }}
            >
                <Button onClick={handleGroupsModalClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddGroupsModal;
