import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';

import DoneIcon from '@mui/icons-material/Check';
import UnDoneIcon from '@mui/icons-material/Close';

import useTenantAPI from 'src/services/api_tenant';

import CrudForm from 'src/components/crud/CrudForm';

import { Reminder } from 'src/pages/kanban/Kanban.d';
import { ReminderMenuProps } from './ReminderMenu.d';
import { Column } from 'src/components/crud/Crud.d';

const columns: Column<Reminder>[] = [
    {
        accessorKey: 'description',
        header: 'Descrição',
        enableEditing: true,
        field: {
            type: 'text',
            default: '',
            inputProps: {
                maxLength: 50,
            },
        },
    },
    {
        accessorKey: 'datetime',
        header: 'Data e Hora',
        enableEditing: true,
        field: {
            type: 'datetime',
            default: new Date(),
        },
    },
];

const defaultReminder = {
    id: 0,
    datetime: new Date(),
    description: '',
    is_done: false,
};

const ReminderMenu = (props: ReminderMenuProps) => {
    const { open, onClose, anchor, refetchCard, boardId, cardId, reminder } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * ADD REMINDER FIELD CONTROL
     */
    const [values, setValues] = useState(defaultReminder);
    const [errors, setErrors] = useState<Record<string, string>>({});

    useEffect(() => {
        setValues(reminder || defaultReminder);
        setErrors({});
    }, [reminder]);

    const submitCreateUpdateReminder = () => {
        if (reminder) {
            return api_tenant
                .patch(`api/kanban/boards/${boardId}/cards/${cardId}/reminders/${reminder.id}/`, values)
                .then((res) => res.data);
        }

        return api_tenant
            .post(`api/kanban/boards/${boardId}/cards/${cardId}/reminders/`, values)
            .then((res) => res.data);
    };

    const { mutateAsync: handleAddReminder, isLoading: isAddReminderLoading } = useMutation(
        submitCreateUpdateReminder,
        {
            onSuccess: () => {
                setValues(defaultReminder);
                setErrors({});
                refetchCard();
                onClose();
            },
            onError: (err: AxiosError<any>) => {
                try {
                    let fieldErrors: Record<string, string> = {};
                    let errors: Record<string, any> = err.response?.data ?? {};

                    for (let [key, val] of Object.entries(errors)) {
                        fieldErrors[key] = val.join(',');
                    }

                    setErrors(err.response?.data ?? null);
                } catch (e) {
                    console.error(e);
                }

                enqueueSnackbar('Erro ao adicionar lembrete.', { variant: 'error' });
            },
        }
    );

    /**
     * REMOVE REMINDER CONTROL
     */
    const submitRemoveReminder = () => {
        return api_tenant
            .delete(`api/kanban/boards/${boardId}/cards/${cardId}/reminders/${reminder?.id}/`)
            .then((res) => res.data);
    };

    const { mutateAsync: handleRemoveReminder, isLoading: isRemoveReminderLoading } = useMutation(
        submitRemoveReminder,
        {
            onSuccess: () => {
                refetchCard();
                onClose();
            },
            onError: () => {
                enqueueSnackbar('Erro ao remover lembrete.', { variant: 'error' });
            },
        }
    );

    /**
     * IS DONE REMINDER CONTROL
     */
    const submitToggleIsDoneReminder = (is_done: boolean) => {
        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${cardId}/reminders/${reminder?.id}/`, { is_done })
            .then((res) => res.data);
    };

    const { mutateAsync: handleIsDoneReminder, isLoading: isUpdateIsDoneLoading } = useMutation(
        (data: { is_done: boolean }) => submitToggleIsDoneReminder(data.is_done),
        {
            onSuccess: () => {
                refetchCard();
                onClose();
            },
            onError: () => {
                enqueueSnackbar('Erro ao atualizar lembrete.', { variant: 'error' });
            },
        }
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Menu open={open} onClose={onClose} anchorEl={anchor}>
                <ListSubheader
                    sx={{
                        my: 0,
                        textAlign: 'center',
                    }}
                >
                    {reminder ? 'Editar ' : 'Adicionar '}
                    lembrete
                </ListSubheader>

                <Divider sx={{ mb: 2 }} />

                <Stack
                    spacing={2}
                    sx={{
                        p: 2,
                        width: 325,
                        maxWidth: '95vh',
                    }}
                >
                    <CrudForm
                        columns={columns}
                        values={values}
                        setValues={setValues as Dispatch<SetStateAction<Record<string, any>>>}
                        errors={errors}
                        size={'small'}
                    />

                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        spacing={1}
                        sx={{
                            pt: 1,
                        }}
                    >
                        <Box>
                            {reminder && (
                                <Button
                                    size={'small'}
                                    color={'error'}
                                    variant={'contained'}
                                    disabled={isRemoveReminderLoading}
                                    onClick={() => handleRemoveReminder()}
                                    startIcon={isRemoveReminderLoading && <CircularProgress size={16} />}
                                    children={'Excluir'}
                                />
                            )}
                        </Box>

                        <Stack direction={'row'} justifyContent={'end'} spacing={1}>
                            <Button
                                size={'small'}
                                onClick={() => {
                                    onClose();
                                }}
                                children={'Cancelar'}
                            />
                            <Button
                                disabled={isAddReminderLoading}
                                variant={'contained'}
                                size={'small'}
                                onClick={() => {
                                    handleAddReminder();
                                }}
                                startIcon={isAddReminderLoading && <CircularProgress size={16} />}
                                children={'Salvar'}
                            />
                        </Stack>
                    </Stack>
                </Stack>

                {reminder && (
                    <Stack
                        sx={{
                            p: 2,
                        }}
                    >
                        <Divider sx={{ mb: 2 }} />

                        <Button
                            fullWidth
                            variant={'outlined'}
                            color={values.is_done ? 'error' : 'success'}
                            startIcon={
                                isUpdateIsDoneLoading ? (
                                    <CircularProgress size={16} />
                                ) : values.is_done ? (
                                    <UnDoneIcon />
                                ) : (
                                    <DoneIcon />
                                )
                            }
                            disabled={isUpdateIsDoneLoading}
                            onClick={() =>
                                handleIsDoneReminder({
                                    is_done: !values.is_done,
                                })
                            }
                        >
                            Marcar como {values.is_done ? 'NÃO REALIZADO' : 'REALIZADO'}
                        </Button>
                    </Stack>
                )}
            </Menu>
        </>
    );
};

export default ReminderMenu;
