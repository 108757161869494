import React, { Dispatch, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import CrudMultiSelect from 'src/components/crud/fields/CrudMultiSelect/CrudMultiSelect';
import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect/CrudApiSelect';

import { Purchase, PurchaseItem } from 'src/pages/marketplace/Products.d';

const Purchases = () => {
    const ItemsField = (
        values: Purchase,
        setValues: Dispatch<SetStateAction<Purchase>>,
        errors: Record<string, any>,
        readOnly: boolean | undefined
    ) => {
        return (
            <CrudMultiSelect
                key={'items'}
                column={{
                    accessorKey: 'items',
                    header: 'Item',
                    field: {
                        type: 'multi-select',
                        name: 'items',
                        endpoint: '/api/marketplace/products/',
                        labelKey: (option) => (option ? `${option.name} (${option.cost} pontos)` : ''),
                        item: {
                            labelKey: (option) =>
                                option ? `${option.product.name} (${option.product.cost} pontos)` : '',
                        },
                    },
                }}
                values={values}
                setValues={setValues}
                errors={errors}
                readOnly={readOnly}
            />
        );
    };

    const PersonField = (
        values: Purchase,
        setValues: Dispatch<SetStateAction<Purchase>>,
        errors: Record<string, any>,
        readOnly: boolean | undefined
    ) => {
        const balance = values.person?.balance ?? 0;
        return (
            <Stack key={'person'}>
                <CrudApiSelect
                    column={{
                        accessorKey: 'person',
                        header: 'Pessoa',
                        field: {
                            type: 'search',
                            name: 'person',
                            endpoint: '/api/persons/balances/',
                            labelKey: 'full_name',
                        },
                    }}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    readOnly={readOnly}
                />

                {!readOnly && (
                    <Typography
                        align={'right'}
                        variant={'body2'}
                        sx={{
                            pt: 1.5,
                        }}
                    >
                        Saldo: <b>{balance} pontos</b>
                    </Typography>
                )}
            </Stack>
        );
    };

    const formatPayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
        return {
            person: values.person.id,
            items: values.items.map((item: PurchaseItem) => ({
                product: item.product.id,
                quantity: item.quantity,
            })),
        };
    };

    const getIsReadOnly = (entity: Purchase) => {
        return !!entity.id;
    };

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Marketplace', 'Compras']} />
                </Box>
            </Stack>

            <CrudAPI
                // CRUD INFO
                name={{
                    singular: 'Compra',
                    plural: 'Compras',
                }}
                endpoint={'/api/marketplace/purchases/'}
                route={AppRoutes.Purchases}
                // ACTIONS
                crudEnableUpdate={false}
                crudEnableDelete={false}
                formatPayload={formatPayload}
                getIsReadOnly={getIsReadOnly}
                // COLUMNS
                columns={[
                    {
                        accessorKey: 'date',
                        header: 'Data',
                        enableEditing: false,
                        field: {
                            type: 'datetime',
                        },
                    },
                    {
                        accessorKey: 'person.full_name',
                        header: 'Pessoa',
                        field: {
                            type: 'custom',
                            component: PersonField,
                        },
                    },
                    {
                        accessorKey: 'items',
                        header: 'Itens',
                        hideColumn: true,
                        field: {
                            type: 'custom',
                            component: ItemsField,
                            default: [],
                        },
                    },
                    {
                        accessorKey: 'total_cost',
                        header: 'Custo Total',
                        enableEditing: false,
                    },
                ]}
                // VISUAL
                modalSize={'xl'}
            />
        </>
    );
};

export default Purchases;
