import React from 'react';
import { keyframes } from '@emotion/react';

import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import EcoTrackLogo from 'src/assets/img/brand/ecotrack/ecotrack-icon.svg';
import { PreloaderProps } from './Preloader.d';

const Wrapper = styled(Box)<{ show: number }>(({ theme, show }) => ({
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    left: 0,
    height: show ? '100vh' : '0', // Set height based on the 'show' prop
    width: '100%',
    backgroundColor: '#EFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'height 0.4s ease',
}));

const spinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const SpinningLogo = styled('img')(({ theme }) => ({
    animation: `${spinAnimation} 2s linear infinite`,
}));

const Preloader = (props: PreloaderProps) => {
    const { show } = props;

    return (
        <Wrapper show={show ? 1 : 0}>
            {show && <SpinningLogo alt={'Logomarca EcoTrack'} src={EcoTrackLogo} height={40} />}
        </Wrapper>
    );
};

export default Preloader;
