import React from 'react';

import { useQuery } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import GoBackIcon from '@mui/icons-material/ArrowForward';
import HistoryIcon from '@mui/icons-material/History';

import useTenantAPI from 'src/services/api_tenant';

import { EntityTimelineItem } from 'src/pages/kanban/Kanban.d';
import { CardModalEntityLogProps } from './CardModalEntityLog.d';
import TlContent from 'src/pages/kanban/CardModal/TlContent';

const CardModalEntityLog = (props: CardModalEntityLogProps) => {
    const { setCardMode, entityId, boardType } = props;

    const api_tenant = useTenantAPI();

    /**
     * LOAD TIMELINE
     */
    const fetchTimeline = () => {
        return api_tenant.get(`api/kanban/timeline/${boardType}/${entityId}/`).then((res) => res.data.results);
    };

    const {
        data: timeline,
        isLoading,
        isError,
    } = useQuery<EntityTimelineItem[]>({
        queryKey: ['timeline', boardType, entityId],
        queryFn: fetchTimeline,
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{
                    mt: 3,
                }}
            >
                <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{ color: '#555' }}>
                    <HistoryIcon fontSize={'small'} />
                    <Typography variant={'h6'} fontWeight={500} children={'Histórico completo da pessoa'} />
                </Stack>

                <Button
                    size={'small'}
                    color={'secondary'}
                    endIcon={<GoBackIcon />}
                    onClick={() => setCardMode('card')}
                    children={'Voltar'}
                />
            </Stack>

            <Divider sx={{ my: 1 }} />

            {isLoading || isError || !timeline ? (
                <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                        height: '50vh',
                    }}
                >
                    {isError ? <Typography children={'Erro ao carregar card.'} /> : <CircularProgress />}
                </Stack>
            ) : (
                <Box sx={{ pl: 3.5 }}>
                    <TlContent
                        endpoint={`api/kanban/timeline/${boardType}/${entityId}/`}
                        columnsEndpoint={`api/kanban/columns/`}
                        timeline={timeline}
                    />
                </Box>
            )}
        </>
    );
};

export default CardModalEntityLog;
