import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';

import CrudModal from 'src/components/crud/modals/CrudModal';

import useTenantAPI from 'src/services/api_tenant';

import { TData } from 'src/components/crud/Crud.d';
import { CreateUpdateModalProps } from './CreateUpdateModal.d';

const CreateUpdateModal = <T extends TData>(props: CreateUpdateModalProps<T>) => {
    const {
        // STRUCTURE
        name,
        endpoint,
        columns,

        // MODAL CONTROL
        open,
        setOpen,

        // ENTITY CONTROL
        entity,
        setEntity,

        // ACTIONS
        refetch,
        formatPayload,
        getIsReadOnly,

        // EVENTS
        afterSubmit,

        // ACTIONS
        extraActions,

        // VISUAL
        size,
    } = props;

    const { enqueueSnackbar } = useSnackbar();
    const api_tenant = useTenantAPI();

    /**
     * CREATE/UPDATE MODAL
     */
    const [errors, setErrors] = useState<Record<string, any>>({});

    // Remove errors from modal when the entity changes
    useEffect(() => {
        setErrors({});
    }, [entity]);

    const handleSubmitError = (error: any) => {
        try {
            let detail = null;
            let fieldErrors = {} as Record<string, string>;
            let errors = error.response.data as Record<string, string[]>;

            if (error.response.data.hasOwnProperty('detail')) {
                detail = errors.detail;
                delete errors.detail;
            }

            for (let [key, val] of Object.entries(errors)) {
                fieldErrors[key] = val.join(',');
            }

            setErrors(fieldErrors);

            if (detail) {
                enqueueSnackbar(detail, { variant: 'error' });
            } else {
                enqueueSnackbar(Object.values(fieldErrors)[0], { variant: 'error' });
            }
        } catch (e) {
            enqueueSnackbar('Erro ao cadastrar!', { variant: 'error' });
        }
    };

    const handleSubmitSuccess = (res: AxiosResponse<any, any>) => {
        if (setOpen) {
            setOpen(false);
        }

        if (setEntity) {
            setEntity(null);
        }

        if (afterSubmit) {
            afterSubmit(res);
        }

        if (refetch) {
            refetch();
        }

        setErrors({});
    };

    const handleCreateUpdateSubmit = async (formData: FormData, entity_id: number, values: T) => {
        if (formatPayload) {
            formData = formatPayload(formData, entity_id, values);
        }

        if (entity_id) {
            return await api_tenant
                .patch(`${endpoint}${entity_id}/`, formData)
                .then(handleSubmitSuccess)
                .catch(handleSubmitError);
        } else {
            return await api_tenant.post(`${endpoint}`, formData).then(handleSubmitSuccess).catch(handleSubmitError);
        }
    };

    const handleCreateUpdateCancel = () => {
        if (setOpen) {
            setOpen(false);
        }

        if (setEntity) {
            setEntity(null);
        }
    };

    return (
        <>
            {/** CREATE/UPDATE MODAL */}
            <CrudModal
                // States
                open={open}
                entity={entity}
                errors={errors}
                readOnly={entity && getIsReadOnly ? getIsReadOnly(entity) : false}
                // Structure
                columns={columns.map((c) => (c.enableEditing === undefined ? { ...c, enableEditing: true } : c))}
                // Texts
                readTitle={`Visualizar ${name.singular}`}
                createTitle={`Criar ${name.singular}`}
                updateTitle={`Editar ${name.singular}`}
                // Events
                onSubmit={handleCreateUpdateSubmit}
                onCancel={handleCreateUpdateCancel}
                // Actions
                extraActions={extraActions}
                // Visual
                size={size}
            />
        </>
    );
};

export default CreateUpdateModal;
