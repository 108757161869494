import React from 'react';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import HomeIcon from '@mui/icons-material/HomeRounded';

import { BreadcrumbItem, BreadcrumbProps } from './Breadcrumb.d';

const Breadcrumb = (props: BreadcrumbProps) => {
    const { items } = props;

    const navigate = useNavigate();

    const handleClick = (item: BreadcrumbItem) => {
        if (typeof item.link === 'string') {
            navigate(item.link);
        }
    };

    return (
        <Breadcrumbs
            sx={{
                display: {
                    xs: 'none',
                    md: 'inline',
                },
            }}
        >
            <HomeIcon fontSize={'small'} sx={{ mt: 0.75 }} />
            {items.map((item, index) => {
                return (
                    <Typography key={index} variant={'body2'} color={index === items.length - 1 ? 'gray' : 'darkgray'}>
                        <Link
                            onClick={() => handleClick(item)}
                            color={'inherit'}
                            sx={{
                                textDecoration: 'none',
                                cursor: typeof item.link === 'string' ? 'pointer' : 'inherit',
                            }}
                            children={typeof item === 'string' ? item : item.text}
                        />
                    </Typography>
                );
            })}
        </Breadcrumbs>
    );
};

export default Breadcrumb;
