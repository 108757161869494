import useTenantAPI from 'src/services/api_tenant';
import { Company } from './company.d';

/**
 * Identifier for the company data on localStorage
 * @type {string}
 */
export const COMPANY_KEY = '@ecotrack-company';

/**
 * Set company data in localStorage
 * @param {string} company - The company data to be stored in localStorage
 */
export const setCompanyData = (company: Company) => {
    sessionStorage.setItem(COMPANY_KEY, JSON.stringify(company));
};

/**
 * Get company data from localStorage
 * @returns {string | null} - Returns the company data if present in localStorage, null otherwise
 */
export const getCachedCompanyData = () => {
    const data = sessionStorage.getItem(COMPANY_KEY);
    return data ? JSON.parse(data) : null;
};

/**
 * Get company data from localStorage or API
 * @returns {string | null} - Returns the company data if present in localStorage, null otherwise
 */
export const getCompanyData = async () => {
    let companyData = getCachedCompanyData();

    if (companyData == null) {
        companyData = await fetchCompanyData();
    }

    return companyData;
};

/**
 * Remove company data from localStorage
 */
export const removeCompanyData = () => {
    localStorage.removeItem(COMPANY_KEY);
};

/**
 * Fetch company data from the API
 */
export const fetchCompanyData = async () => {
    const api_tenant = useTenantAPI();
    return await api_tenant
        .get('/api/companies/my/')
        .then((res) => {
            setCompanyData(res.data);
            return res.data;
        })
        .catch((err) => {
            removeCompanyData();
        });
};
