import React from 'react';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

import getNestedValue from 'src/components/utils/getNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';

import { TData } from 'src/components/crud/Crud.d';

import { CrudDatePickerProps } from './CrudDatePicker.d';

const CrudDatePicker = <T extends TData>(props: CrudDatePickerProps<T>) => {
    const { column, values, setValues, errors, readOnly } = props;

    const { accessorKey, header } = column;

    return (
        <DatePicker
            label={header}
            value={dayjs(getNestedValue(values, accessorKey, ''))}
            disabled={readOnly}
            onChange={(e: any) =>
                setValues(setNestedValue(values, accessorKey, `${e['$y']}-${e['$M'] + 1}-${e['$D']}`))
            }
            slotProps={{
                textField: {
                    error: hasNestedValue(errors, accessorKey),
                    helperText: getNestedValue(errors, accessorKey, ''),
                },
            }}
        />
    );
};

export default CrudDatePicker;
