import React, { Fragment } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

import ListTableContentRow from 'src/components/crud/fields/CrudMultiSelect/ListTableContentRow';
import getOptionLabel from 'src/components/utils/getOptionLabel';

import { TData } from 'src/components/crud/Crud.d';
import { ListTableContentProps } from 'src/components/crud/fields/CrudMultiSelect/ListTableContent';

const ListTableContent = <T extends TData>(props: ListTableContentProps<T>) => {
    const {
        // CONTROL
        items,
        load,
        labelKey,
        readOnly,

        // HANDLERS
        updateItems,
    } = props;

    const handleRemoveItem = (id: number) => {
        updateItems(items.filter((item) => item.product.id !== id));
    };

    const handleUpdateQuantity = (id: number, quantity: number) => {
        updateItems(
            items.map((item) => {
                return item.product.id !== id
                    ? item
                    : {
                          ...item,
                          quantity,
                      };
            })
        );
    };

    if (load) {
        return (
            <>
                {Array.from({ length: 3 }).map((_, i) => {
                    return (
                        <TableRow key={`si-${i}`}>
                            {Array.from({ length: 3 }).map((_, j) => {
                                return (
                                    <TableCell key={`si-${i}-${j}`} align={'center'}>
                                        <Skeleton />
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </>
        );
    }

    return (
        <Fragment>
            {items.map((item) => {
                const label = getOptionLabel(item, labelKey);

                return (
                    <ListTableContentRow
                        key={label}
                        item={item}
                        label={label}
                        readOnly={readOnly}
                        // HANDLERS
                        handleRemoveItem={handleRemoveItem}
                        handleUpdateQuantity={handleUpdateQuantity}
                    />
                );
            })}
        </Fragment>
    );
};

export default ListTableContent;
