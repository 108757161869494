import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import TransferField from './TransferField/TransferField';

import useTenantAPI from 'src/services/api_tenant';
import getNestedValue from 'src/components/utils/getNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';

import { TData } from 'src/components/crud/Crud.d';
import { CrudTransferSelectProps } from './CrudTransferSelect.d';

const CrudTransferSelect = <T extends TData>(props: CrudTransferSelectProps<T>) => {
    const { column, values, setValues } = props;

    const { accessorKey, header, field } = column;

    const { itemsEndpoint, descriptionsEndpoint } = field;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * FIELD CONTROL
     */
    const fieldKey = accessorKey.split('.').pop();

    /**
     * HANDLE SEARCH ITEMS
     */
    const [itemsLoad, setItemsLoad] = useState<boolean>(false);
    const [itemsDescLoad, setItemsDescLoad] = useState<boolean>(true);
    const [itemsDesc, setItemsDesc] = useState<T[] | null>(null);

    const itemsList: number[] = getNestedValue(values, accessorKey, []) ?? [];
    const setItemsList = (items: number[]) => setValues(setNestedValue(values, accessorKey, items));

    /**
     * FETCH ITEMS AND DESCRIPTIONS
     */
    const fetchItems = async () => {
        if (!values.id) {
            setItemsLoad(false);
            return;
        }

        setItemsLoad(true);
        return await api_tenant
            .get(itemsEndpoint(values))
            .then((res) => {
                if (fieldKey) {
                    setItemsList(res.data.results[0][fieldKey]);
                }
            })
            .catch(() => {
                enqueueSnackbar(`Erro ao buscar ${header}!`, { variant: 'error' });
            })
            .finally(() => {
                setItemsLoad(false);
            });
    };

    const fetchDescriptions = async () => {
        setItemsDescLoad(true);
        return await api_tenant
            .get(descriptionsEndpoint)
            .then(async (res) => {
                setItemsDesc(res.data.results);
            })
            .catch(() => {
                enqueueSnackbar(`Erro ao buscar descrições de ${header}!`, { variant: 'error' });
            })
            .finally(() => {
                setItemsDescLoad(false);
            });
    };

    useEffect(() => {
        fetchDescriptions();
    }, []);

    useEffect(() => {
        fetchItems();
    }, [values.id]);

    /**
     * RENDER COMPONENT
     */
    return (
        <Box
            sx={{
                width: '100%',
                minWidth: {
                    xs: '400px',
                },
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
                padding: 2,
            }}
        >
            <Typography
                variant={'caption'}
                children={header}
                color={'textSecondary'}
                sx={{
                    position: 'relative',
                    top: -25,
                    left: -8,
                    bgcolor: 'background.paper',
                    px: 1,
                }}
            />
            {itemsLoad || itemsDescLoad || !itemsDesc ? (
                <Stack justifyContent={'center'} alignItems={'center'} children={<CircularProgress />} />
            ) : (
                <TransferField right={itemsList} setRight={setItemsList} descriptions={itemsDesc} />
            )}
        </Box>
    );
};

export default CrudTransferSelect;
