import React from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import CardAccordion from 'src/pages/kanban/CardModal/CardAccordion';

import { CustomerSectionInfoProps } from './CustomerSectionInfo.d';

const CustomerSectionInfo = (props: CustomerSectionInfoProps) => {
    const { card } = props;

    return (
        <CardAccordion title={'Informações'}>
            <Stack spacing={2}>
                <TextField size={'small'} label={'Empresa'} value={card.enterprise.name} fullWidth />
            </Stack>
        </CardAccordion>
    );
};

export default CustomerSectionInfo;
