import React from 'react';

import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import useTenantAPI from 'src/services/api_tenant';

import LabelsSelector from './LabelsSelector';

import { Label } from 'src/pages/kanban/Kanban.d';
import { LabelsFieldProps } from './LabelsField.d';

const LabelsField = (props: LabelsFieldProps) => {
    const { boardId, cardId, cardLabels, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * LABELS SAVE
     */
    const submitAddRemoveCardLabels = async (label: Label, add: boolean) => {
        if (add) {
            return await api_tenant
                .patch(`api/kanban/boards/${boardId}/cards/${cardId}/labels/`, { label: label.id })
                .then((response) => response.data);
        }

        return await api_tenant
            .delete(`api/kanban/boards/${boardId}/cards/${cardId}/labels/`, { data: { label: label.id } })
            .then((response) => response.data);
    };

    const { mutateAsync } = useMutation<void, Error, { label: Label; add: boolean }>(
        ({ label, add }) => submitAddRemoveCardLabels(label, add),
        {
            onError: () => {
                enqueueSnackbar('Erro ao atualizar etiquetas.', { variant: 'error' });
            },
        }
    );

    /**
     * CHECKBOX CONTROL
     */
    const handleCheckboxChange = (label: Label, isCheked: boolean) => {
        mutateAsync({
            label,
            add: isCheked,
        });
    };

    return (
        <LabelsSelector
            boardId={boardId}
            selectedLabels={cardLabels}
            onClose={refetchCard}
            onChange={handleCheckboxChange}
        />
    );
};

export default LabelsField;
