import React from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Footer = () => {
    return (
        <footer>
            <Typography
                sx={{
                    my: 2,
                }}
            >
                Desenvolvido por
                <Link
                    href={'https://predictconsultoria.com.br/'}
                    target={'_blank'}
                    sx={{
                        textDecoration: 'none',
                        ml: 0.8,
                    }}
                >
                    Predict
                </Link>
            </Typography>
        </footer>
    );
};

export default Footer;
