import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import GroupIcon from '@mui/icons-material/Group';

import AppRoutes from 'src/routes/appRoutes';
import { ACTIONS, hasRoutePermission } from 'src/services/storage/permissions';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';

import AddGroupsModal from 'src/pages/registry/persons/Persons/UserModal/AddGroupsModal/AddGroupsModal';
import UserField from 'src/pages/registry/persons/Persons/UserField/UserField';
import UserModal from 'src/pages/registry/persons/Persons/UserModal/UserModal';
import { Dashboard } from 'src/pages/dashboards/Dashboard.d';
import { RowAction } from 'src/components/crud/Crud.d';

import { Person, PersonWithGroupList, User } from './Persons.d';

export const endpoint = '/api/persons/';

export const name = {
    singular: 'Pessoa',
    plural: 'Pessoas',
};

const PersonsPage = () => {
    /**
     * MANAGE CREATE/UPDATE MODAL ENTITY
     */
    const [modalEntity, setModalEntity] = useState<Person | null>(null);
    const [rowActions, setRowActions] = useState<RowAction<Person>[]>([]);
    const [refresh, setRefresh] = useState(false);

    const refetch = () => {
        setRefresh((r) => !r);
    };

    /**
     * MANAGE GROUPS MODAL
     */
    const [personGroupsEntity, setPersonGroupsEntity] = useState<PersonWithGroupList | null>(null);
    const [groupsOpen, setGroupsOpen] = useState(false);

    const openGroupsModal = (entity: Person) => {
        setPersonGroupsEntity({
            ...entity,
            panels: entity.panels?.map((p: Dashboard) => p.id) ?? [],
        });
        setGroupsOpen(true);
    };

    const groupAction: RowAction<Person> = {
        onClick: openGroupsModal,
        tooltip: 'Grupos',
        icon: GroupIcon,
        color: 'primary',
    };

    useEffect(() => {
        if (hasRoutePermission(AppRoutes.Persons, ACTIONS.UPDATE)) {
            setRowActions([groupAction]);
        } else {
            setRowActions([]);
        }
    }, []);

    /**
     * MANAGE USER MODAL
     */
    const [userOpen, setUserOpen] = useState(false);

    const openUserModal = (entity: Person) => {
        setModalEntity(entity);
        setUserOpen(true);
    };

    const setUser = (user: User) => {
        if (modalEntity) {
            setModalEntity({
                ...modalEntity,
                user: user,
            });
        }
    };

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{
                    pt: 4,
                }}
            >
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Cadastros', 'Pessoas']} />
                </Box>
            </Stack>

            <CrudAPI
                // CRUD INFO
                name={name}
                route={AppRoutes.Persons}
                endpoint={endpoint}
                columns={[
                    {
                        accessorKey: 'first_name',
                        header: 'Nome',
                    },
                    {
                        accessorKey: 'last_name',
                        header: 'Sobrenome',
                    },
                    {
                        accessorKey: 'integration_id',
                        header: 'Id Integração',
                        filterLookup: 'exact',
                    },
                    {
                        accessorKey: 'user.username',
                        header: 'Usuário',
                        field: {
                            type: 'custom',
                            default: '',
                            component: (
                                values: Person,
                                setValues: Dispatch<SetStateAction<Person>>,
                                errors: Record<string, any>,
                                readOnly: boolean
                            ) => UserField(values, setValues, errors, readOnly, openUserModal),
                        },
                    },
                    {
                        accessorKey: 'is_instructor',
                        header: 'Instrutor',
                        hideColumn: true,
                        field: {
                            type: 'check',
                        },
                        size: 100,
                    },
                    {
                        accessorKey: 'instructors',
                        header: 'Instrutores',
                        hideColumn: true,
                        field: {
                            type: 'search',
                            multiple: true,
                            name: 'instructors',
                            endpoint: '/api/persons/',
                            default: [],
                            labelKey: (option: Person) => (option ? `${option.first_name} ${option.last_name}` : ''),
                            queryParams: {
                                is_instructor: true,
                            },
                        },
                    },
                    {
                        accessorKey: 'user.last_login',
                        header: 'Login',
                        Cell: ({ row }) =>
                            row.original.user?.last_login
                                ? new Date(row.original.user.last_login).toLocaleDateString()
                                : '',
                        enableEditing: false,
                        enableColumnFilter: false,
                        size: 80,
                    },
                ]}
                // ROW ACTIONS
                extraRowActions={rowActions}
                // MODALS ENTITY
                controlledModalEntity={modalEntity}
                setControlledModalEntity={setModalEntity}
                setControlledRefresh={setRefresh}
                controlledRefresh={refresh}
            />

            {personGroupsEntity && (
                <AddGroupsModal open={groupsOpen} setOpen={setGroupsOpen} personGroupsEntity={personGroupsEntity} />
            )}

            {modalEntity && (
                <UserModal
                    open={userOpen}
                    setOpen={setUserOpen}
                    modalPerson={modalEntity}
                    refetch={refetch}
                    setUser={setUser}
                />
            )}
        </>
    );
};

export default PersonsPage;
