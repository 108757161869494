import React, { useState } from 'react';

import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';

import BookIcon from '@mui/icons-material/LibraryBooks';
import ChartIcon from '@mui/icons-material/BarChart';
import CheckIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';
import ClockIcon from '@mui/icons-material/AccessTime';
import GasIcon from '@mui/icons-material/LocalGasStation';
import HandShakeIcon from '@mui/icons-material/Handshake';
import InstuctorsIcon from '@mui/icons-material/SupervisorAccount';
import KanbanIcon from '@mui/icons-material/ViewWeek';
import ListIcon from '@mui/icons-material/FactCheck';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import MoneyIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import TelemetryIcon from '@mui/icons-material/Map';
import ShopIcon from '@mui/icons-material/Shop';
import ScoreIcon from '@mui/icons-material/RocketLaunch';

import EcoTrackLogo from 'src/assets/img/brand/ecotrack/ecotrack-logo-h.svg';
import EcoTrackIcon from 'src/assets/img/brand/ecotrack/ecotrack-icon.svg';

import AppRoutes from 'src/routes/appRoutes';
import { getCachedPermissions, getCachedSuperUser, removePermissionsData } from 'src/services/storage/permissions';

import Item, { NavItemProps } from './NavItem';
import CollapsableNavItem from './CollapsableNavItem';
import { removeUserData } from 'src/services/storage/user';

const SideNavWrapper = styled(Box)<{ show: number }>(({ theme, show }) => ({
    [theme.breakpoints.up('xs')]: {
        width: '100%',
        display: show ? 'block' : 'none',
    },
    [theme.breakpoints.up('md')]: {
        width: '260px',
        display: 'block',
    },
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    maxHeight: '100%',
    backgroundColor: '#262B40',
}));

const dashRoute = (group_slug: string) => {
    return {
        ...AppRoutes.DashboardGroup,
        path: AppRoutes.DashboardGroup.path.replace(':group', group_slug),
    };
};

const reloadCachedData = () => {
    removePermissionsData();
    removeUserData();
    window.location.reload();
};

const SidebarDivider = styled(Divider)(({ theme }) => ({
    marginTop: '10px',
    marginBottom: '10px',
    borderColor: '#7B7B7B',
    borderWidth: 1.2,
    opacity: 0.5,
}));

const Sidebar = () => {
    const [show, setShow] = useState(false);
    const onCollapse = () => setShow(!show);

    /**
     * PERMISSIONS
     */
    const permissions = getCachedPermissions();
    const superuser = getCachedSuperUser();

    const NavItem = (props: Omit<NavItemProps, 'setShow' | 'permissions' | 'superuser'>) => {
        return <Item {...props} setShow={setShow} permissions={permissions} superuser={superuser} />;
    };

    return (
        <>
            <AppBar
                position={'static'}
                sx={{
                    px: 4,
                    backgroundColor: '#262B40',
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <img alt={'Icone Ecotrack'} src={EcoTrackIcon} width={25} />
                    <IconButton size={'large'} edge={'start'} onClick={onCollapse} sx={{ color: 'white' }}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <SideNavWrapper show={show ? 1 : 0}>
                <SimpleBarReact style={{ maxHeight: '100%' }}>
                    <Box
                        sx={{
                            px: 2,
                            py: 3,
                        }}
                    >
                        <Stack direction={'row-reverse'} sx={{ display: { md: 'none' } }}>
                            <IconButton size={'large'} edge={'start'} onClick={onCollapse} sx={{ color: 'white' }}>
                                <ClearIcon />
                            </IconButton>
                        </Stack>

                        <Stack>
                            <Box
                                sx={{
                                    py: 1.8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 1.2,
                                }}
                            >
                                <img
                                    alt={'Logomarca EcoTrack'}
                                    src={EcoTrackLogo}
                                    height={30}
                                    onDoubleClick={reloadCachedData}
                                />
                            </Box>

                            <SidebarDivider />

                            <CollapsableNavItem title={'Relatórios'} icon={ListIcon} permissions={permissions}>
                                <NavItem title={'Ranking'} route={AppRoutes.RankingReport} icon={TrophyIcon} />
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Dashboards'} icon={ChartIcon} permissions={permissions}>
                                <NavItem title={'Abastecimentos'} route={dashRoute('abastecimentos')} icon={GasIcon} />
                                <NavItem title={'Acertos'} route={dashRoute('acertos')} icon={HandShakeIcon} />
                                <NavItem title={'Faturamento'} route={dashRoute('faturamento')} icon={MoneyIcon} />
                                <NavItem title={'Instrutores'} route={dashRoute('instrutores')} icon={InstuctorsIcon} />
                                <NavItem title={'Produtividade'} route={dashRoute('produtividade')} icon={ClockIcon} />
                                <NavItem title={'RH'} route={dashRoute('rh')} icon={PersonIcon} />
                                <NavItem title={'Telemetria'} route={dashRoute('telemetria')} icon={TelemetryIcon} />
                            </CollapsableNavItem>

                            <SidebarDivider />

                            <NavItem title={'Gestão de Tarefas'} route={AppRoutes.Kanban} icon={KanbanIcon} />

                            <CollapsableNavItem title={'Pontuação'} icon={ScoreIcon} permissions={permissions}>
                                <NavItem title={'Conquistas'} route={AppRoutes.Achievements} />
                                <NavItem title={'Grupos'} route={AppRoutes.Groups} />
                                <NavItem title={'Metas'} route={AppRoutes.Goals} />
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Cadastros'} icon={BookIcon} permissions={permissions}>
                                <NavItem title={'Empresas'} route={AppRoutes.Enterprises} />
                                <NavItem title={'Pessoas'} route={AppRoutes.Persons} />
                                <NavItem title={'Veículos'} route={AppRoutes.Vehicles} />
                                <NavItem title={'Trocas de Motorista'} route={AppRoutes.Allocations} />
                                <NavItem title={'Locais'} route={AppRoutes.Places} />
                            </CollapsableNavItem>

                            <NavItem title={'Checklists'} route={AppRoutes.Checklists} icon={CheckIcon} />

                            <CollapsableNavItem title={'Acessos'} icon={LockIcon} permissions={permissions}>
                                <NavItem title={'Perfis'} route={AppRoutes.PermissionProfiles} />
                                <NavItem title={'Perfis de Dashboards'} route={AppRoutes.DashboardProfiles} />
                                <NavItem title={'Perfis de Kanban'} route={AppRoutes.KanbanProfiles} />
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Marketplace'} icon={ShopIcon} permissions={permissions}>
                                <NavItem title={'Produtos'} route={AppRoutes.Products} />
                                <NavItem title={'Compras'} route={AppRoutes.Purchases} />
                            </CollapsableNavItem>

                            <SidebarDivider />

                            <NavItem title={'Configurações'} route={AppRoutes.Settings} icon={SettingsIcon} />
                        </Stack>
                    </Box>
                </SimpleBarReact>
            </SideNavWrapper>
        </>
    );
};

export default Sidebar;
