import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppRoutes from 'src/routes/appRoutes';
import RouteWithLoader from './components/RouteWithLoader';

// pages
import Login from 'src/pages/auth/Login';

export default function PublicRoutes() {
    return (
        <Routes>
            {/* auth */}
            <Route path={AppRoutes.Login.path} element={<RouteWithLoader component={Login} />} />

            {/* On any route non specified, return login */}
            <Route path="*" element={<Navigate to={AppRoutes.Login.path} />} />
        </Routes>
    );
}
