import React, { useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import useTenantAPI from 'src/services/api_tenant';
import { TlRegisterFieldProps } from './TlRegisterField.d';

const TlRegisterField = (props: TlRegisterFieldProps) => {
    const { boardId, cardId, item, onSuccess } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * REGISTER CONTROL
     */
    const [isEditing, setIsEditing] = useState(!!item);
    const [regDescription, setRegDescription] = useState(item ? item.description : '');
    const [regDate, setRegDate] = useState<Date>(item ? new Date(item.date) : new Date());

    /**
     * SUBMIT HANDLERS
     */
    const submitRegister = () => {
        const data = {
            description: regDescription,
            date: dayjs(regDate).format('YYYY-MM-DDTHH:mm:ss'),
        };

        if (item) {
            return api_tenant
                .put(`api/kanban/boards/${boardId}/cards/${cardId}/timeline/registers/${item.id}/`, data)
                .then((res) => res.data);
        }

        return api_tenant
            .post(`api/kanban/boards/${boardId}/cards/${cardId}/timeline/registers/`, data)
            .then((res) => res.data);
    };

    const { mutateAsync, isLoading, isError } = useMutation(submitRegister, {
        onSuccess: () => {
            onSuccess();
            setRegDescription('');
            setIsEditing(false);
        },
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Erro ao salvar o registro.', { variant: 'error' });
        }
    }, [isError]);

    return (
        <Stack spacing={1}>
            <TextField
                multiline
                value={regDescription}
                placeholder={'Adicione um registro...'}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setRegDescription(e.target.value);
                    setIsEditing(true);
                }}
            />

            {isEditing && (
                <Stack direction={'row'} spacing={1}>
                    <Button
                        size={'small'}
                        variant={'contained'}
                        startIcon={isLoading && <CircularProgress size={16} />}
                        disabled={!regDescription || isLoading}
                        onClick={() => mutateAsync()}
                        children={'Salvar'}
                    />
                    <DateTimePicker
                        value={dayjs(regDate)}
                        onChange={(date: Dayjs | null) => {
                            if (date) {
                                setRegDate(date.toDate());
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                            },
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default TlRegisterField;
