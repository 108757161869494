import React from 'react';

import DriverSectionInfo from './DriveSectionInfo';
import CustomerSectionInfo from './CustomerSectionInfo';

import { SectionInfoProps } from './SectionInfo.d';
import { CustomerCardDetail, DriverCardDetail } from 'src/pages/kanban/Kanban.d';

const SectionInfo = (props: SectionInfoProps) => {
    const { boardType, card } = props;

    if (boardType === 'driver') {
        return <DriverSectionInfo {...props} card={card as DriverCardDetail} />;
    }

    if (boardType === 'customer') {
        return <CustomerSectionInfo {...props} card={card as CustomerCardDetail} />;
    }

    return <></>;
};

export default SectionInfo;
