import React, { useContext, useState } from 'react';

import dayjs from 'dayjs';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CommentIcon from '@mui/icons-material/Comment';

import { TlItemRegisterProps } from './TlItemRegister.d';
import TlRegisterField from 'src/pages/kanban/CardModal/TlRegisterField';
import TlItemContent from 'src/pages/kanban/CardModal/TlItemContent';
import { UserContext } from 'src/routes/privateRoutes';

const TlItemRegister = (props: TlItemRegisterProps) => {
    const { item } = props;

    const user = useContext(UserContext);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <TlItemContent
            {...item}
            icon={CommentIcon}
            iconColor={'primary.main'}
            tooltip={
                <Stack>
                    <Typography variant={'caption'}>
                        Criado em: <b>{dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}</b>
                    </Typography>
                    <Typography variant={'caption'}>
                        Atualizado em: <b>{dayjs(item.updated_at).format('DD/MM/YYYY HH:mm')}</b>
                    </Typography>
                </Stack>
            }
        >
            {isEditing && 'boardId' in props && 'cardId' in props && 'refetchCard' in props ? (
                <TlRegisterField
                    boardId={props.boardId}
                    cardId={props.cardId}
                    item={item}
                    onSuccess={() => {
                        props.refetchCard();
                        setIsEditing(false);
                    }}
                />
            ) : (
                <>
                    <Typography variant={'body2'} children={item.description} />

                    {user && user.id == item.user.id && 'boardId' in props && (
                        <Stack direction={'row'}>
                            <Link
                                href={'#'}
                                variant={'caption'}
                                color={'text.secondary'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsEditing(true);
                                }}
                                children={'editar'}
                            />
                        </Stack>
                    )}
                </>
            )}
        </TlItemContent>
    );
};

export default TlItemRegister;
