import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useTenantAPI from 'src/services/api_tenant';

import { TData } from 'src/components/crud/Crud.d';
import CrudForm from 'src/components/crud/CrudForm';

import { SettingsSectionProps } from './SettingsSection.d';

const SettingsSection = <T extends TData>(props: SettingsSectionProps<T>) => {
    const { endpoint, title, subtitle, columns, extraFields } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * SECTION CONTROL
     */
    const [values, setValues] = useState<Record<string, any>>({});
    const [errors, setErrors] = useState<Record<string, any>>({});

    /**
     * DISABLED MODULES
     */
    const sectionDisabled = columns.every((c) => c.disabledModule);

    /**
     * EVENT HANDLERS
     */
    const submitData = async () => {
        return await api_tenant.put(endpoint, values);
    };

    const handleCancel = () => {
        window.location.reload();
    };

    const { mutate: handleSubmit, isLoading } = useMutation({
        mutationFn: submitData,
        onSuccess: () => {
            setErrors({});
            enqueueSnackbar('Informações atualizadas.', { variant: 'success' });
        },
        onError: (err: AxiosError<any>) => {
            setErrors(err?.response?.data);
            enqueueSnackbar(`Erro ao atualizar informações.`, { variant: 'error' });
        },
    });

    /**
     * GET CONFIGURATION DATA ON PAGE LOAD
     */
    const fetchConfig = async () => {
        return await api_tenant.get(endpoint);
    };

    useEffect(() => {
        if (sectionDisabled) {
            setValues({});
            return;
        }
        fetchConfig().then((res) => setValues({ ...res.data }));
    }, []);

    /**
     * RE-BUILD COLUMNS LIST
     */
    const crudColumns = columns.map((c) => {
        c.enableEditing = true;
        c.readOnly = c.disabledModule;
        return c;
    });

    return (
        <>
            <Grid item xs={12} sm={4}>
                <Typography variant={'h5'} mb={2} children={title} />
                <Typography children={subtitle} />
            </Grid>
            <Grid item xs={12} sm={8}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        width={'100%'}
                        gap={'1.5rem'}
                        minWidth={{
                            xs: '300px',
                            sm: '360px',
                            md: '400px',
                        }}
                    >
                        {values ? (
                            <CrudForm columns={crudColumns} values={values} setValues={setValues} errors={errors} />
                        ) : (
                            <Stack direction={'row'} justifyContent={'center'} children={<CircularProgress />} />
                        )}
                        <Stack direction={'row'} gap={2}>
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                onClick={handleCancel}
                                disabled={sectionDisabled || isLoading}
                                children={'Cancelar'}
                            />
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => handleSubmit()}
                                disabled={sectionDisabled || isLoading}
                                children={'Salvar'}
                            />
                        </Stack>
                    </Stack>
                </form>
                <Grid mt={2} children={extraFields} />
            </Grid>
            <Grid item xs={12}>
                <Divider
                    sx={{
                        my: 4,
                        bgcolor: 'secondary.light',
                    }}
                />
            </Grid>
        </>
    );
};

export default SettingsSection;
