import React, { useMemo, useState } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';
import DeleteModal from 'src/components/crud/modals/DeleteModal';

import { CrudProps } from 'src/components/crud/Crud';
import { Column, TData } from 'src/components/crud/Crud.d';
import CrudTable from 'src/components/crud/CrudTable';

const Crud = <T extends TData>(props: CrudProps<T>) => {
    const {
        // STRUCTURE
        name,
        columns,
        endpoint,

        // ACTIONS
        rowActions,
        extraRowActions,
        handleCreateUpdate,
        rowDoubleClick,
        getIsReadOnly,

        // MODULES
        enableUpdate = false,
        enableCreate = false,
        enableDelete = false,

        // API CONTROL
        formatPayload,

        // TABLE CONTROL
        controlledRefresh,
        setControlledRefresh,
        defaultSorting,
        defaultFilters,

        // MODAL CONTROL
        modalEntity,
        setModalEntity,
        createUpdateOpen = false,
        setCreateUpdateOpen,

        // VISUAL
        modalSize,
    } = props;

    /**
     * TABLE CONTROL
     */
    const [uncontrolledRefresh, setUncontrolledRefresh] = useState(false);
    const refresh = controlledRefresh ?? uncontrolledRefresh;
    const setRefresh = setControlledRefresh ?? setUncontrolledRefresh;

    /**
     * DELETE MODAL CONTROL
     */
    const [deleteOpen, setDeleteOpen] = useState(false);

    const openDeleteModal = (entity: T) => {
        setModalEntity(entity);
        setDeleteOpen(true);
    };

    /**
     * COLUMN DEFINITION
     */
    const crudColumns = columns.map((col: Column<T>): Column<T> => {
        if (col.accessorKey === 'id') {
            return {
                ...col,
                enableEditing: false,
                enableSorting: false,
                enableColumnActions: false,
                size: 10,
                Cell: ({ row }) => row.original[col.accessorKey].padStart(5, '0'),
            };
        }

        const col_type = col.type ?? col.field?.type ?? '';

        if (col_type === 'check') {
            return {
                enableEditing: true,
                filterVariant: 'checkbox',
                filterLookup: 'exact',
                Cell: ({ row }) => (row.original[col.accessorKey] ? <CheckIcon /> : <CircleIcon />),
                ...col,
            };
        }

        if (col_type === 'select') {
            return {
                enableEditing: true,
                enableColumnFilter: false,
                enableSorting: false,
                ...col,
            };
        }

        if (col_type === 'date') {
            return {
                size: 125,
                enableEditing: true,
                Cell: ({ row }) => row.original[col.accessorKey].split('-').reverse().join('/'),
                filterVariant: 'date',
                filterLookup: 'exact',
                ...col,
            };
        }

        if (col_type === 'datetime') {
            return {
                enableEditing: true,
                Cell: ({ row }) => {
                    const value = row.original[col.accessorKey];
                    if (!value) return '-';

                    let [date, hour] = value.split('T');
                    date = date.split('-').reverse().join('/');
                    hour = hour.split('.')[0].split('-')[0].split('Z')[0];
                    return `${date} ${hour}`;
                },
                ...col,
            };
        }

        return {
            enableEditing: true,
            ...col,
        };
    });

    /**
     * ROW ACTIONS
     */
    const tableExtraRowActions = useMemo(() => {
        let actions = extraRowActions ?? [];
        if (enableDelete) {
            actions.push({
                onClick: openDeleteModal,
                tooltip: 'Apagar',
                icon: DeleteIcon,
                color: 'error',
            });
        }
        return actions;
    }, [extraRowActions, enableDelete]);

    /**
     * COMPONENT RENDER
     */
    return (
        <>
            {/** TABLE */}
            <CrudTable
                // STRUCTURE
                columns={crudColumns}
                endpoint={endpoint}
                // TABLE CONTROL
                defaultSorting={defaultSorting}
                defaultFilters={defaultFilters}
                // ACTIONS
                refresh={refresh}
                rowActions={rowActions}
                extraRowActions={tableExtraRowActions}
                handleCreateUpdate={handleCreateUpdate}
                rowDoubleClick={rowDoubleClick}
                getIsReadOnly={getIsReadOnly}
                // PERMISSIONS
                enableUpdate={enableUpdate}
            />

            {/** CREATE/UPDATE MODAL */}
            <CreateUpdateModal
                // STRUCTURE
                name={name}
                endpoint={endpoint}
                columns={crudColumns}
                // MODAL CONTROL
                open={createUpdateOpen}
                setOpen={setCreateUpdateOpen}
                // ENTITY CONTROL
                entity={modalEntity}
                setEntity={setModalEntity}
                // ACTIONS
                refetch={() => setRefresh((v: boolean) => !v)}
                formatPayload={formatPayload}
                getIsReadOnly={getIsReadOnly}
                // VISUAL
                size={modalSize}
            />

            {/** DELETE CONFIRM */}
            <DeleteModal
                // STRUCTURE
                name={name}
                endpoint={endpoint}
                // MODAL CONTROL
                modalEntity={modalEntity}
                setDeleteOpen={setDeleteOpen}
                deleteOpen={deleteOpen}
                // ACTIONS
                refresh={() => setRefresh((v: boolean) => !v)}
            />
        </>
    );
};

export default Crud;
