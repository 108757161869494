import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import useTenantAPI from 'src/services/api_tenant';

import { ColumnMoveMenuProps } from './ColumnMoveMenu.d';
import { Column } from 'src/pages/kanban/Kanban.d';

const ColumnMoveMenu = (props: ColumnMoveMenuProps) => {
    const { open, onClose, anchor, boardId, cardId, column: originalColumn } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * COLUMNS LOAD
     */

    const fetchColumns = () => {
        return api_tenant.get(`api/kanban/boards/${boardId}/columns/`).then((res) => res.data.results);
    };

    const { data: columns, isLoading } = useQuery<void, Error, Column[]>(
        ['kanban/boards/columns', boardId],
        fetchColumns,
        {
            enabled: open,
        }
    );

    /**
     * COLUMN MOVE FIELD CONTROL
     */
    const [column, setColumn] = useState<Column>(originalColumn);

    useEffect(() => {
        setColumn(originalColumn);
    }, [originalColumn]);

    const submitMove = () => {
        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${cardId}/move/`, { column: column.id })
            .then((res) => res.data);
    };

    const { mutateAsync: handleMove, isLoading: isMoveLoading } = useMutation(submitMove, {
        onSuccess: () => onClose(),
        onError: () => enqueueSnackbar('Erro ao mover.', { variant: 'error' }),
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Menu open={open} onClose={onClose} anchorEl={anchor}>
                <ListSubheader
                    sx={{
                        my: 0,
                        textAlign: 'center',
                    }}
                >
                    Mover cartão
                </ListSubheader>

                <Divider sx={{ mb: 2 }} />

                <Stack
                    spacing={2}
                    sx={{
                        p: 2,
                        width: 325,
                        maxWidth: '95vh',
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel children={'Coluna'} />
                        <Select fullWidth label={'Coluna'} value={column.id}>
                            {isLoading || !columns || !Array.isArray(columns) ? (
                                <MenuItem disabled={true} children={'Carregando...'} />
                            ) : (
                                columns.map((column) => (
                                    <MenuItem
                                        key={column.id}
                                        value={column.id}
                                        onClick={() => {
                                            setColumn(column);
                                        }}
                                        children={column.name}
                                    />
                                ))
                            )}
                        </Select>
                    </FormControl>

                    <Stack direction={'row'} justifyContent={'end'} spacing={1}>
                        <Button
                            size={'small'}
                            onClick={() => {
                                onClose();
                            }}
                            children={'Cancelar'}
                        />
                        <Button
                            disabled={isMoveLoading}
                            variant={'contained'}
                            size={'small'}
                            onClick={() => {
                                handleMove();
                            }}
                            startIcon={isMoveLoading && <CircularProgress size={16} />}
                            children={'Mover'}
                        />
                    </Stack>
                </Stack>
            </Menu>
        </>
    );
};

export default ColumnMoveMenu;
