import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AssignmentIcon from '@mui/icons-material/AssignmentTurnedIn';

import AppRoutes from 'src/routes/appRoutes';
import { hasRoutePermission } from 'src/services/storage/permissions';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns, RowAction } from 'src/components/crud/Crud.d';

import ItemsField from './ItemsField';
import { Checklist } from './Checklists.d';

export const columns: Columns<Checklist> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
    {
        accessorKey: 'items',
        header: 'Itens',
        hideColumn: true,
        field: {
            type: 'custom',
            component: ItemsField,
        },
    },
];

export const endpoint = '/api/checklists/';

export const name = {
    singular: 'Checklist',
    plural: 'Checklists',
};

const ChecklistsPage = () => {
    const navigate = useNavigate();

    /**
     * EXTRA ROW ACTIONS
     */
    const openChecklistRequests = (entity: Checklist) => {
        navigate(AppRoutes.ChecklistsRequest.path.replace(':checklistId', entity.id.toString()));
    };

    const checklistRequestsAction: RowAction<Checklist> = {
        onClick: openChecklistRequests,
        tooltip: 'Solicitações',
        icon: AssignmentIcon,
        color: 'primary',
    };

    const extraRowActions = useMemo<RowAction<Checklist>[]>(
        () => (hasRoutePermission(AppRoutes.ChecklistsRequest, 'view') ? [checklistRequestsAction] : []),
        []
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Cadastros', 'Checklists']} />
                </Box>
            </Stack>
            <CrudAPI
                // INFO
                name={name}
                endpoint={endpoint}
                route={AppRoutes.Checklists}
                crudEnableDelete={false}
                // STRUCTURE
                columns={columns}
                formatPayload={(formData: FormData, entity_id: number, values: Record<string, any>) => {
                    if (values.hasOwnProperty('items') && !Array.isArray(values.items)) {
                        delete values['items'];
                    }
                    return values;
                }}
                extraRowActions={extraRowActions}
            />
        </>
    );
};

export default ChecklistsPage;
