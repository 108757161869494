import React from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import LogoPBI from 'src/assets/pbi.svg';

import { ActionAreaCardProps } from './ActionAreaCard.d';

const ActionAreaCard = (props: ActionAreaCardProps) => {
    const { description, link } = props;

    const navigate = useNavigate();

    return (
        <Card>
            <CardActionArea onClick={() => navigate(link)}>
                <CardMedia
                    sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#EEEEEE',
                    }}
                >
                    <img width={75} src={LogoPBI} alt={'Logomarca PowerBi'} />
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant={'h6'}>
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ActionAreaCard;
