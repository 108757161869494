import React, { useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/PersonRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import api_shared from 'src/services/api_shared';
import { fetchUserData, logout } from 'src/services/storage/user';
import { setCompanyData } from 'src/services/storage/company';
import { setToken } from 'src/services/auth';

import EcoTrackLogo from 'src/assets/img/brand/ecotrack/ecotrack-logo-v.svg';
import { fetchPermissionsData } from 'src/services/storage/permissions';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLogged, setIsLogged] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleMouseDownPassword = () => {
        setShowPassword(true);
    };

    const handleMouseUpPassword = () => {
        setShowPassword(false);
    };

    /**
     * SUBMIT LOGIN
     */
    const submitLogin = async () => {
        // If it does not have one of the credentials (despite the required prop)
        if (!username || !password) {
            setError('Preencha e-mail e senha para continuar!');
            return;
        }

        // Call the login route
        await api_shared
            .post('/api/login/', {
                username,
                password,
            })
            .then(async (res: any) => {
                if (!res) {
                    setError('Erro ao fazer login.');
                    return;
                }

                // Store the access token and the company on the local storage
                setCompanyData(res.data['company']);
                setToken(res.data['access_token']);

                // Get user data and save on localstorage
                await fetchUserData().then(async (userData) => {
                    if (!userData?.is_company_staff) {
                        logout(false);
                        setError('Usuário sem permissão para acesso web.');
                        return;
                    }

                    setIsLogged(true);

                    // Get permissions data and save on localstorage
                    await fetchPermissionsData().then(() => {
                        // Redirect to the reports
                        window.location.replace('/');
                    });
                });
            })
            .catch(() => {
                setError('Usuário ou senha inválidos.');
            });
    };

    const { mutateAsync, isLoading } = useMutation(submitLogin, {
        onError: () => setError('Erro ao fazer login.'),
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <main>
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                    height: '100vh',
                    px: 3,
                    py: 0,
                    my: 0,
                }}
            >
                <Stack
                    sx={{
                        width: '100%',
                        maxWidth: '350px',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            mb: 3,
                        }}
                    >
                        <img src={EcoTrackLogo} alt={'Logomarca EcoTrack'} width={'90%'} />
                    </Box>
                    {error && (
                        <Alert severity={'error'} sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <form
                        onSubmit={(event) => {
                            // Preventing to submit form
                            event.preventDefault();
                            mutateAsync();
                        }}
                    >
                        <Stack
                            sx={{
                                width: '100%',
                                gap: '1.5rem',
                            }}
                        >
                            <TextField
                                name={'username'}
                                label={'Usuário'}
                                onChange={(e) => setUsername(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={'start'}>
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                label={'Senha'}
                                name={'password'}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position={'start'}>
                                            <KeyIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position={'end'}>
                                            <IconButton
                                                onMouseUp={handleMouseUpPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant={'contained'}
                                type={'submit'}
                                size={'large'}
                                startIcon={(isLoading || isLogged) && <CircularProgress size={20} />}
                                disabled={isLoading || isLogged}
                                sx={{ p: 1.5 }}
                                children={'Entrar'}
                            />
                        </Stack>
                    </form>
                </Stack>
            </Stack>
        </main>
    );
};

export default LoginPage;
