import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Edit from '@mui/icons-material/Edit';
import Image from '@mui/icons-material/Image';

import { Achievement } from 'src/pages/registry/achievements';

const BadgeField = (
    values: Achievement,
    setValues: Dispatch<SetStateAction<Achievement>>,
    errors: any,
    readOnly: boolean
) => {
    const [preview, setPreview] = useState<string | null>(null);

    useEffect(() => {
        // Se não há imagem no form, limpa o preview
        if (!(values['badge'] ?? false)) {
            setPreview(null);
            return;
        }

        // Se a imagem foi inserida no campo
        if (values['badge'] instanceof File) {
            // Insere imagem no preview
            setPreview(URL.createObjectURL(values['badge']));
            return;
        }

        // Insere imagem pela url
        setPreview(values['badge']);
    }, [values]);

    const handleUpload = (files: FileList | null) => {
        if (!files) {
            return;
        }

        setValues({
            ...values,
            badge: files[0],
        });
    };

    return (
        <FormGroup key={'badge-upload-btn'}>
            <Typography sx={{ mb: 1 }}>Medalha</Typography>
            <input
                disabled={readOnly}
                type={'file'}
                id={'upload-badge'}
                accept={'image/jpg, image/png'}
                onChange={(e) => handleUpload(e.target.files)}
                hidden
            />
            <Box
                style={{
                    justifyContent: preview ? 'center' : 'flex-start',
                    display: 'flex',
                }}
            >
                <label
                    htmlFor={'upload-badge'}
                    onClick={(e) => {
                        if (e.target !== e.currentTarget) e.currentTarget.click();
                    }}
                >
                    {preview ? (
                        <IconButton
                            sx={{
                                position: 'relative',
                                width: 150,
                                height: 150,
                                p: 0,
                                '&:hover': { backgroundColor: '#000000' },
                            }}
                        >
                            <Avatar
                                alt={'Badge Preview'}
                                src={preview}
                                sx={{
                                    width: 150,
                                    height: 150,
                                    transition: 'opacity 0.3s ease',
                                    '&:hover': { opacity: 0.3 },
                                    '&:hover + #edit-icon': { opacity: 1 },
                                }}
                            />
                            {readOnly ? null : (
                                <Edit
                                    id={'edit-icon'}
                                    sx={{
                                        color: '#FFFFFF',
                                        width: 30,
                                        height: 30,
                                        position: 'absolute',
                                        transition: 'opacity 0.3s ease',
                                        opacity: 0,
                                    }}
                                />
                            )}
                        </IconButton>
                    ) : (
                        <Button variant={'outlined'} component={'label'} color={'primary'}>
                            <Image sx={{ mr: 1 }} />
                            Escolher Imagem
                        </Button>
                    )}
                </label>
            </Box>
            {errors && errors.hasOwnProperty('badge') && (
                <Typography
                    variant={'caption'}
                    display={'block'}
                    sx={{
                        color: '#d32f2f',
                        pt: 1,
                        ml: 2,
                    }}
                    gutterBottom
                >
                    {errors['badge']}
                </Typography>
            )}
        </FormGroup>
    );
};

export default BadgeField;
