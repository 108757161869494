import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import ConfirmAttrRow from 'src/pages/registry/achievements/components/ConfirmAttrRow';
import { ConfirmContentProps } from 'src/pages/registry/achievements/components/ConfirmContent/ConfirmContent.d';
import { METRICS_MAP } from 'src/pages/registry/achievements/Achievements.d';

const ConfirmContent = (props: ConfirmContentProps) => {
    const { entity } = props;

    if (!entity) {
        return null;
    }

    const values = {
        badge: entity.badge,
        name: entity.name,
        description: entity.description,
        start_date: entity.start_date?.split('-').reverse().join('/') ?? '-',
        end_date: entity.end_date?.split('-').reverse().join('/') ?? '-',
        score: `${entity.score} pontos`,
        only_groups: entity.only_groups,
        groups: !entity.groups.length ? '-' : entity.groups.map((g) => g.name).join(', '),
        is_cumulative: entity.is_cumulative ? 'Sim' : 'Não',
        day_streak: entity.has_day_streak ? `${entity.day_streak} dias` : 'Não',
        goal: `${entity.goal} ${['Km', 'Km/L'][entity.metric - 1]}`,
        metric: METRICS_MAP[entity.metric],
    };

    return (
        <>
            <Typography variant={'subtitle1'}>
                <b>Informações Básicas</b>
            </Typography>
            <Box
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <Avatar
                    alt={'Badge Image'}
                    src={values.badge as string}
                    sx={{
                        width: 150,
                        height: 150,
                    }}
                />
            </Box>
            <Table sx={{ mb: 4 }}>
                <TableBody>
                    <ConfirmAttrRow name={'Conquista'} value={values.name} />
                    <ConfirmAttrRow name={'Descrição'} value={values.description} />
                    <ConfirmAttrRow name={'Pontuação'} value={values.score} />
                </TableBody>
            </Table>

            <Typography variant={'subtitle1'}>
                <b>Escopo</b>
            </Typography>
            <Table sx={{ mb: 4 }}>
                <TableBody>
                    <ConfirmAttrRow name={'Início'} value={values.start_date} />
                    <ConfirmAttrRow name={'Fim'} value={values.end_date} />
                    {values.only_groups && <ConfirmAttrRow name={'Grupos'} value={values.groups} />}
                </TableBody>
            </Table>

            <Typography variant={'subtitle1'}>
                <b>Indicador</b>
            </Typography>
            <Table sx={{ mb: 4 }}>
                <TableBody>
                    <ConfirmAttrRow name={'Indicador'} value={values.metric} />
                    <ConfirmAttrRow name={'Meta'} value={values.goal} />
                    <ConfirmAttrRow name={'Acumulada'} value={values.is_cumulative} />
                    <ConfirmAttrRow name={'Dias consecutivos'} value={values.day_streak} />
                </TableBody>
            </Table>
        </>
    );
};

export default ConfirmContent;
