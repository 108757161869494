import React, { SyntheticEvent, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';

import ApiSelectField from 'src/components/ApiSelect/ApiSelect';
import getOptionLabel from 'src/components/utils/getOptionLabel';

import { TData } from 'src/components/crud/Crud.d';
import { AddFieldProps } from 'src/components/crud/fields/CrudMultiSelect/AddField';

const AddField = <T extends TData>(props: AddFieldProps<T>) => {
    const {
        // CONTROL
        errors,
        readOnly,
        load,
        items,

        // STRUCTURE
        header,
        name,
        endpoint,
        labelKey,

        // HANDLERS
        updateItems,
    } = props;

    /**
     * SEARCH HANDLERS
     */
    const [search, setSearch] = useState<T | ''>('');

    /**
     * HANDLE ADD
     */

    const handleAddItem = (product: T) => {
        if (items.find((item) => item.product.id === product.id)) {
            return;
        }

        updateItems([
            ...items,
            {
                quantity: 1,
                product: product,
            },
        ]);
    };
    const handleAddClick = async () => {
        if (search) {
            handleAddItem(search);
            setSearch('');
        }
    };

    return (
        <Grid container spacing={1} alignItems={'strech'} justifyContent={'space-between'}>
            <Grid item xs>
                <ApiSelectField
                    label={header}
                    endpoint={endpoint}
                    value={search}
                    onChange={(e: SyntheticEvent, val: T) => setSearch(val)}
                    getOptionLabel={(option: T) => getOptionLabel(option, labelKey)}
                    error={errors?.hasOwnProperty(name) ?? false}
                    helperText={errors ? errors[name] ?? '' : ''}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid
                item
                xs={2}
                sx={{
                    display: 'flex',
                    alignItems: 'strech',
                }}
            >
                <Button onClick={handleAddClick} variant={'contained'} disabled={load || readOnly} fullWidth>
                    {load ? (
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <AddIcon />
                    )}
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddField;
