/**
 * STORE USER PERMISSIONS INFORMATION ON sessionStorage
 */

import useTenantAPI from 'src/services/api_tenant';
import { getPermissionLabel, Route } from 'src/routes/appRoutes';
import { ACTIONS, ApiPerms, PermArray } from 'src/services/storage/permissions';

export const PERMISSIONS_KEY = '@ecotrack-permissions';
export const SUPERUSER_KEY = '@ecotrack-superuser';

/**
 * Set user permissions data in sessionStorage
 * @param {object} permissions - The permissions data to be stored in sessionStorage
 */
export const setPermissionsData = (permissionsData: ApiPerms) => {
    sessionStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissionsData.permissions));
    sessionStorage.setItem(SUPERUSER_KEY, JSON.stringify(permissionsData.is_company_superuser));
};

/**
 * Get permissions data from sessionStorage
 */
export const getCachedPermissions = () => {
    const sessionPermissions = sessionStorage.getItem(PERMISSIONS_KEY);
    return sessionPermissions ? JSON.parse(sessionPermissions) : null;
};

export const getCachedSuperUser = () => {
    const sessionSuperuser = sessionStorage.getItem(SUPERUSER_KEY);
    return sessionSuperuser ? JSON.parse(sessionSuperuser) : null;
};

/**
 * Remove permissions data from sessionStorage
 */
export const removePermissionsData = () => {
    sessionStorage.removeItem(PERMISSIONS_KEY);
    sessionStorage.removeItem(SUPERUSER_KEY);
};

/**
 * Get permissions data from sessionStorage or API
 * @returns {object | null} - Returns the permissions data if present in sessionStorage, null otherwise
 */
export const getPermissions = async () => {
    let permissionsData = getCachedPermissions();

    if (permissionsData == null) {
        permissionsData = (await fetchPermissionsData()).permissions;
    }

    return permissionsData;
};

/**
 * Check if the user has the permission
 */
export const checkPermission = (permissions: PermArray, permission: string) => {
    if (!permissions) {
        return false;
    }

    return permissions.includes(permission);
};

export const checkRoutePermission = (permissions: PermArray, route: Route, action: string) => {
    if (route.allowAny) {
        return true;
    }

    const permLabel = getPermissionLabel(route, action);

    if (!permLabel) {
        return false;
    }

    return checkPermission(permissions, permLabel);
};

export const canAccessRoute = (permissions: PermArray, superuser: boolean, route: Route) => {
    return (route.superuser && superuser) || checkRoutePermission(permissions, route, ACTIONS.VIEW);
};

/**
 * Verify if the user has the given permission
 */
export const hasPermission = (permission: string) => {
    return checkPermission(getCachedPermissions(), permission);
};

/**
 * Given a route and action, verify if the user has the permission to access it
 */
export const hasRoutePermission = (route: Route, action: string) => {
    const permLabel = getPermissionLabel(route, action);

    if (!permLabel) {
        return false;
    }

    return hasPermission(permLabel);
};

/**
 * Return the four CRUD permissions for the given route
 */
export const getRoutePermissions = (route: Route) => {
    return {
        canCreate: hasRoutePermission(route, ACTIONS.CREATE),
        canView: hasRoutePermission(route, ACTIONS.VIEW),
        canUpdate: hasRoutePermission(route, ACTIONS.UPDATE),
        canDelete: hasRoutePermission(route, ACTIONS.DELETE),
    };
};

/**
 * Fetch permissions data from the API
 */
export const fetchPermissionsData = async () => {
    const api_tenant = useTenantAPI();
    return await api_tenant
        .get('/api/permissions/my/')
        .then((res) => {
            setPermissionsData(res.data);
            return res.data;
        })
        .catch((err) => {
            removePermissionsData();
        });
};
