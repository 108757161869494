import React from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import dayjs from 'dayjs';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ReminderIcon from '@mui/icons-material/Alarm';

import useTenantAPI from 'src/services/api_tenant';

import { ReminderCardProps } from './ReminderCard.d';

const ReminderCard = (props: ReminderCardProps) => {
    const { boardId, cardId, refetchCard, reminder, setMenuEntity, setMenuAnchor } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * IS DONE REMINDER CONTROL
     */
    const submitToggleIsDoneReminder = (is_done: boolean) => {
        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${cardId}/reminders/${reminder?.id}/`, { is_done })
            .then((res) => res.data);
    };

    const { mutateAsync: handleIsDoneReminder, isLoading: isUpdateIsDoneLoading } = useMutation(
        (data: { is_done: boolean }) => submitToggleIsDoneReminder(data.is_done),
        {
            onSuccess: () => refetchCard(),
            onError: () => enqueueSnackbar('Erro ao atualizar lembrete.', { variant: 'error' }),
        }
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <Card variant={'outlined'} key={reminder.id}>
            <CardActionArea
                onClick={(e) => {
                    setMenuEntity(reminder);
                    setMenuAnchor(e.currentTarget);
                }}
            >
                <CardContent>
                    <Stack
                        spacing={1}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        sx={{ pb: 1 }}
                    >
                        <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <ReminderIcon color={reminder.is_done ? 'success' : 'primary'} />
                            <Typography variant={'body2'} children={reminder.description} />
                        </Stack>

                        {isUpdateIsDoneLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <Checkbox
                                size={'small'}
                                checked={reminder.is_done}
                                color={reminder.is_done ? 'success' : 'warning'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleIsDoneReminder({ is_done: !reminder.is_done });
                                }}
                                sx={{
                                    p: 0,
                                    m: 0,
                                    alignSelf: 'flex-start',
                                }}
                            />
                        )}
                    </Stack>
                    <Typography variant={'caption'} children={dayjs(reminder.datetime).format('DD/MM/YYYY HH:mm')} />
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ReminderCard;
