import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import WarningRounded from '@mui/icons-material/WarningRounded';

import { TData } from 'src/components/crud/Crud.d';
import { ConfirmModalProps } from './ConfirmModal.d';

const ConfirmModal = <T extends TData>(props: ConfirmModalProps<T>) => {
    const { open, entity, title, btnText, onSubmit, onCancel, extraActions } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    /**
     * CONTROL OPEN STATE FROM OUTSIDE
     */
    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    /**
     * EVENT HANDLERS
     */
    const handleSubmit = () => onSubmit(entity, setLoading);
    const handleCancel = () => onCancel();

    /**
     * DIALOG CONTENT
     */
    const dialogContent = () => {
        return (
            <>
                {'message' in props && <DialogContentText children={props.message} />}
                {'warningMessage' in props && (
                    <DialogContentText
                        align={'right'}
                        sx={{
                            color: 'red',
                            mt: 2,
                        }}
                    >
                        <WarningRounded />
                        <small> {props.warningMessage}</small>
                    </DialogContentText>
                )}
            </>
        );
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={modalOpen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{'children' in props ? props.children : dialogContent()}</DialogContent>
            <DialogActions
                sx={{
                    p: '1.25rem',
                    display: 'flex',
                    backgroundColor: '#ECECEC',
                    justifyContent: extraActions ? 'space-between' : 'end',
                }}
            >
                {extraActions ? <span>{extraActions}</span> : null}
                <span>
                    <Button disabled={loading} onClick={handleCancel} sx={{ color: 'black' }} children={'Cancelar'} />
                    <Button disabled={loading} onClick={handleSubmit} color={'primary'}>
                        {loading ? <CircularProgress size={20} sx={{ mr: 2 }} /> : null} {btnText}
                    </Button>
                </span>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
