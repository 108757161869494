import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { DescriptionFieldProps } from './DescriptionField.d';
import useTenantAPI from 'src/services/api_tenant';

const DescriptionField = (props: DescriptionFieldProps) => {
    const { boardId, cardId, description: oldDescription, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * EDITING CONTROL
     */
    const [isEditing, setIsEditing] = useState(false);

    /**
     * DESCRIPTION FIELD CONTROL
     */
    const [description, setDescription] = useState(oldDescription);

    useEffect(() => {
        if (oldDescription !== description) {
            setDescription(oldDescription);
        }
    }, [oldDescription]);

    /**
     * DESCRIPTION SAVE
     */
    const submitCardLabels = async () => {
        return await api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${cardId}/`, {
                description: description,
            })
            .then((response) => response.data);
    };

    const { mutateAsync, isLoading } = useMutation(submitCardLabels, {
        onError: () => {
            enqueueSnackbar('Erro ao atualizar descrição.', { variant: 'error' });
        },
        onSuccess: () => {
            setIsEditing(false);
            refetchCard();
        },
    });

    /**
     * RENDER COMPONENT
     */
    if (!description && !isEditing) {
        return (
            <Card
                elevation={0}
                onClick={() => setIsEditing(true)}
                sx={{
                    cursor: 'pointer',
                    bgcolor: '#00000011',
                    '&:hover': {
                        bgcolor: '#00000022',
                    },
                }}
            >
                <CardContent>
                    <Typography variant={'body2'} color={'textSecondary'} children={'Adicionar descrição'} />
                </CardContent>
            </Card>
        );
    }

    if (isEditing) {
        return (
            <Stack spacing={1}>
                <TextField
                    autoFocus
                    multiline
                    fullWidth
                    rows={2}
                    variant={'outlined'}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <Stack direction={'row'} spacing={1}>
                    <Button
                        size={'small'}
                        variant={'contained'}
                        startIcon={isLoading && <CircularProgress size={16} />}
                        disabled={isLoading}
                        onClick={() => mutateAsync()}
                        children={'Salvar'}
                    />
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => {
                            setIsEditing(false);
                            setDescription(oldDescription);
                        }}
                        children={'Cancelar'}
                    />
                </Stack>
            </Stack>
        );
    }

    return (
        <Typography
            variant={'body2'}
            onClick={() => setIsEditing(true)}
            sx={{
                whiteSpace: 'pre-wrap',
            }}
            children={description}
        />
    );
};

export default DescriptionField;
