import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import AppRoutes from 'src/routes/appRoutes';
import useTenantAPI from 'src/services/api_tenant';
import { getRoutePermissions, hasRoutePermission } from 'src/services/storage/permissions';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import CrudModal from 'src/components/crud/modals/CrudModal';
import CrudTableBtn from 'src/components/crud/CrudTableBtn';
import NotFoundError from 'src/pages/checklists/Checklists/NotFoundError/NotFoundError';
import ResponseModal from 'src/pages/checklists/Checklists/ResponseModal/ResponseModal';

import { Checklist } from 'src/pages/checklists/Checklists/Checklists.d';
import { Group, Person } from 'src/pages/registry/persons/Persons';

import { ChecklistResponse } from './ChecklistRequest.d';

export const URGENCIES_MAP = {
    1: {
        label: 'Baixa',
        color: 'yellow',
    },
    2: {
        label: 'Média',
        color: 'orange',
    },
    3: {
        label: 'Alta',
        color: 'darkorange',
    },
    4: {
        label: 'Urgente',
        color: 'red',
    },
};

const ChecklistRequestsPage = () => {
    const api_tenant = useTenantAPI();
    const { checklistId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * PERMISSIONS
     */
    const { canCreate, canDelete } = getRoutePermissions(AppRoutes.ChecklistsRequest);

    /**
     * CHECKLIST DATA CONTROL
     */
    const [checklistData, setChecklistData] = useState<Checklist | null>(null);
    const [checklistLoad, setChecklistLoad] = useState(true);
    const [modalEntity, setModalEntity] = useState<ChecklistResponse | null>(null);

    // FETCH CHECKLIST DATA
    useEffect(() => {
        setChecklistLoad(true);
        api_tenant
            .get(`/api/checklists/${checklistId}/`)
            .then((res) => {
                setChecklistData(res.data.results[0]);
            })
            .catch((err) => {
                enqueueSnackbar(err.detail, { variant: 'error' });
            })
            .finally(() => {
                setChecklistLoad(false);
            });
    }, []);

    /**
     * CHECKLIST REQUEST MODAL CONTROL
     */
    const [requestModalOpen, setRequestModalOpen] = useState(false);
    const [requestModalEntity, setRequestModalEntity] = useState(null);
    const [requestModalErrors, setRequestModalErrors] = useState({});
    const [refresh, setRefresh] = useState(false);

    const openRequestModal = () => {
        setRequestModalOpen(true);
    };

    const closeRequestModal = () => {
        setRequestModalOpen(false);
    };

    const submitRequestModal = async (formData: FormData, entity_id: number, values: Record<string, any>) => {
        await api_tenant
            .post(`/api/checklists/${checklistId}/requests/batch/`, {
                persons: values.persons.map((person: Person) => person.id),
                groups: values.groups.map((group: Group) => group.id),
            })
            .then((res) => {
                setRequestModalOpen(false);
                setRequestModalEntity(null);
                setRefresh(!refresh);
            })
            .catch((error) => {
                try {
                    let detail = null;
                    let fieldErrors: Record<string, any> = {};
                    let errors = error.response.data;

                    if (error.response.data.hasOwnProperty('detail')) {
                        detail = errors.detail;
                        delete errors.detail;
                    }

                    for (let [key, val] of Object.entries(errors)) {
                        fieldErrors[key] = (val as string[]).join(',');
                    }

                    setRequestModalErrors(fieldErrors);

                    if (detail) {
                        enqueueSnackbar(detail, { variant: 'error' });
                    } else {
                        enqueueSnackbar(errors.join('; '), { variant: 'error' });
                    }
                } catch (e) {
                    enqueueSnackbar('Erro ao cadastrar!', { variant: 'error' });
                }
            });
    };

    /**
     * CHECKLIST RESPONSES
     */
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [responseModalEntity, setResponseModalEntity] = useState<ChecklistResponse | null>(null);

    const handleResponseModalOpen = (entity: ChecklistResponse) => {
        setResponseModalEntity(entity);
        setResponseModalOpen(true);
    };

    const extraRowActions = (entity: ChecklistResponse) => {
        const actions = [];

        if (entity.responded && hasRoutePermission(AppRoutes.ChecklistsResponse, 'view')) {
            actions.push(
                <CrudTableBtn
                    key={'view-row-btn'}
                    entity={entity}
                    onClick={handleResponseModalOpen}
                    tooltip={'Visualizar respostas'}
                    icon={VisibilityIcon}
                    color={'primary'}
                />
            );
        }

        return <Stack justifyContent={'end'}>{actions}</Stack>;
    };

    /**
     * CHECKLIST REQUESTS CONTROL
     */

    // LOADING
    if (checklistLoad) {
        return (
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ height: '70vh' }}>
                <CircularProgress />
            </Stack>
        );
    }

    // ERROR
    if (!checklistData) {
        return <NotFoundError />;
    }

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Checklists', checklistData.description, 'Solicitações']} />
                </Box>

                {canCreate && (
                    <Button onClick={openRequestModal} sx={{ m: 1 }}>
                        <AddIcon sx={{ mr: 1 }} /> Solicitação
                    </Button>
                )}
            </Stack>

            <CrudAPI
                // INFO
                name={{
                    singular: 'Solicitação',
                    plural: 'Solicitações',
                }}
                endpoint={`/api/checklists/${checklistId}/requests/`}
                route={AppRoutes.ChecklistsRequest}
                // ACTIONS
                rowActions={extraRowActions}
                crudEnableCreate={false}
                crudEnableUpdate={false}
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                // MODALS ENTITY
                controlledModalEntity={modalEntity}
                setControlledModalEntity={setModalEntity}
                columns={[
                    {
                        accessorKey: 'request_date',
                        header: 'Data',
                        field: {
                            type: 'datetime',
                        },
                        enableColumnFilter: false,
                    },
                    {
                        accessorKey: 'person.full_name',
                        header: 'Pessoa',
                    },
                    {
                        accessorKey: 'responded',
                        header: 'Respondido',
                        type: 'check',
                        size: 100,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'urgency_count.1',
                        header: 'Baixa',
                        size: 100,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'urgency_count.2',
                        header: 'Média',
                        size: 100,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'urgency_count.3',
                        header: 'Alta',
                        size: 100,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'urgency_count.4',
                        header: 'Emer.',
                        size: 100,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ]}
            />

            <CrudModal
                open={requestModalOpen}
                entity={requestModalEntity}
                errors={requestModalErrors}
                createTitle={`Solicitar '${checklistData.description}'`}
                onCancel={closeRequestModal}
                onSubmit={submitRequestModal}
                columns={[
                    {
                        accessorKey: 'groups',
                        header: 'Grupos',
                        enableEditing: true,
                        field: {
                            type: 'search',
                            multiple: true,
                            name: 'groups',
                            endpoint: '/api/persons/groups/',
                            labelKey: 'name',
                            default: [],
                        },
                    },
                    {
                        accessorKey: 'persons',
                        header: 'Pessoas',
                        enableEditing: true,
                        field: {
                            type: 'search',
                            multiple: true,
                            name: 'persons',
                            endpoint: '/api/persons/',
                            labelKey: 'full_name',
                            default: [],
                        },
                    },
                ]}
            />

            {responseModalEntity && (
                <ResponseModal open={responseModalOpen} setOpen={setResponseModalOpen} entity={responseModalEntity} />
            )}
        </>
    );
};
export default ChecklistRequestsPage;
