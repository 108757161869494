import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import BoardIcon from '@mui/icons-material/ViewWeek';
import CustomerIcon from '@mui/icons-material/SupportAgent';
import DriverIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import DownArrowIcon from '@mui/icons-material/KeyboardArrowDown';

import useTenantAPI from 'src/services/api_tenant';

import { Board } from './Kanban.d';
import KanbanBoard from './KanbanBoard/KanbanBoard';

import AppRoutes from 'src/routes/appRoutes';
import { CardApiFilters } from 'src/pages/kanban/CardFiltersMenu';
import { UserContext } from 'src/routes/privateRoutes';

export const BOARD_TYPE_ICON_MAP: Record<string, typeof SvgIcon> = {
    driver: DriverIcon,
    customer: CustomerIcon,
};

export const BOARD_TYPE_LABEL_MAP: Record<string, string> = {
    driver: 'Motoristas',
    customer: 'Clientes',
};

const KanbanPage = () => {
    const { boaId } = useParams();
    const navigate = useNavigate();
    const api_tenant = useTenantAPI();
    const user = useContext(UserContext);

    /**
     * BOARD SELECT CONTROL
     */

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const fetchBoards = async () => {
        return await api_tenant.get('api/kanban/boards/').then((res) => res.data.results);
    };

    /**
     * BOARD LIST CONTROL
     */
    const {
        data: boardList,
        isLoading: boardsListLoad,
        isError: boardsListError,
    } = useQuery({
        queryKey: ['boards'],
        queryFn: fetchBoards,
    });

    // Show error message if boards fail to load
    useEffect(() => {
        if (boardsListError) {
            enqueueSnackbar('Erro ao carregar quadros.', { variant: 'error' });
        }
    }, [boardsListError]);

    // Set default selectedBoard on page load
    useEffect(() => {
        if (!selectedBoard && boardList && boardList.length > 0) {
            navigate(AppRoutes.KanbanBoard.path.replace(':boaId', boardList[0].id));
        }
    }, [boardList]);

    const selectedBoard = boardList && boardList.find((item: Board) => item.id.toString() === boaId);

    /**
     * FILTERS CONTROL
     */
    const [searchParams, setSearchParams] = useSearchParams();
    const initialFilters = useMemo(() => {
        const params: CardApiFilters = {};

        if (searchParams.has('search')) {
            params.search = searchParams.get('search') as string;
        }

        params.labels = searchParams
            .get('labels__id__in')
            ?.split(',')
            .map((e) => parseInt(e));
        params.responsibles = searchParams
            .get('responsibles__id__in')
            ?.split(',')
            .map((e) => parseInt(e));
        params.closest_date = searchParams.get('closest_date__in')?.split(',');

        if (!params.responsibles || !params.responsibles.length) {
            if (user && user.person?.is_instructor) {
                params.responsibles = [user.person.id];
            }
        }

        return params;
    }, []);

    /**
     * RENDER COMPONENT
     */
    const Icon = selectedBoard ? BOARD_TYPE_ICON_MAP[selectedBoard.type] : BoardIcon;

    return (
        <>
            <Stack direction={'column'} flexGrow={1}>
                <Typography
                    variant={'h5'}
                    fontWeight={'500'}
                    onClick={() => setOpen(true)}
                    sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        width: 'fit-content',
                        color: '#262B40',
                    }}
                >
                    {selectedBoard ? `Quadro ${selectedBoard.name}` : 'Selecione o quadro'}
                    <DownArrowIcon ref={anchorRef} />
                    <Tooltip title={selectedBoard && `Quadro de ${BOARD_TYPE_LABEL_MAP[selectedBoard.type]}`}>
                        <Icon fontSize={'small'} color={'primary'} />
                    </Tooltip>
                </Typography>

                <KanbanBoard boardType={selectedBoard?.type} initialFilters={initialFilters} />
            </Stack>

            <Menu anchorEl={anchorRef.current} open={open} onClose={() => setOpen(false)}>
                {boardList &&
                    boardList.map((item: Board) => {
                        const Icon = BOARD_TYPE_ICON_MAP[item?.type];
                        return (
                            <MenuItem
                                key={item.id}
                                value={item.id}
                                disabled={boardsListLoad}
                                selected={selectedBoard?.id === item.id}
                                onClick={() => {
                                    navigate(AppRoutes.KanbanBoard.path.replace(':boaId', item.id.toString()));
                                    setOpen(false);
                                }}
                                sx={{
                                    pr: 5,
                                }}
                            >
                                <ListItemIcon children={<Icon />} />
                                <Typography variant={'inherit'} children={item.name} />
                            </MenuItem>
                        );
                    })}
                {boardsListLoad && <CircularProgress size={20} />}
            </Menu>
        </>
    );
};

export default KanbanPage;
