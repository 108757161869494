import React, { useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import ArchiveIcon from '@mui/icons-material/Inventory';
import DotsIcon from '@mui/icons-material/MoreHoriz';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import useTenantAPI from 'src/services/api_tenant';

import { ActionsMenuProps } from './ActionsMenu.d';

const getLabel = (isArchived: boolean) => (isArchived ? 'desarquivar' : 'arquivar');

const ActionsMenu = (props: ActionsMenuProps) => {
    const { boardId, cardDetail, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * MENU CONTROL
     */
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    /**
     * HANDLE TOGGLE ARCHIVE
     */
    const isArchived = cardDetail.is_archived;
    const submitToggleArchive = async () => {
        return await api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${cardDetail.id}/`, {
                is_archived: !isArchived,
            })
            .then((res) => res.data);
    };

    const { mutateAsync, isLoading } = useMutation(submitToggleArchive, {
        onError: () => enqueueSnackbar(`Erro ao ${getLabel(isArchived)}.`, { variant: 'error' }),
        onSuccess: () => {
            enqueueSnackbar(`Sucesso ao ${getLabel(isArchived)}.`, { variant: 'success' });
            setOpen(false);
            refetchCard();
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <IconButton ref={anchorRef} onClick={() => setOpen(true)} children={<DotsIcon />} />

            <Menu
                anchorEl={anchorRef.current}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 200,
                    },
                }}
            >
                <MenuItem dense={true} onClick={() => mutateAsync()}>
                    <ListItemIcon
                        children={
                            isLoading ? (
                                <CircularProgress size={15} />
                            ) : isArchived ? (
                                <UnarchiveIcon />
                            ) : (
                                <ArchiveIcon />
                            )
                        }
                    />
                    <Typography
                        children={getLabel(isArchived)}
                        sx={{
                            textTransform: 'capitalize',
                        }}
                    />
                </MenuItem>
            </Menu>
        </>
    );
};

export default ActionsMenu;
