import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';

import { TData } from 'src/components/crud/Crud.d';
import { TransferListProps } from './TransferList.d';

const TransferList = <T extends TData>(props: TransferListProps<T>) => {
    const { items, checked, handleToggle, itemsDescription } = props;

    const getDescription = (id: number) => {
        return itemsDescription.find((item: TData) => item.id === id)?.description ?? '';
    };

    return (
        <Paper
            sx={{
                height: 300,
                overflow: 'auto',
            }}
        >
            <List dense component={'div'} role={'list'}>
                {items &&
                    items.map((item_id: number) => (
                        <ListItemButton key={item_id} role={'listitem'} onClick={() => handleToggle(item_id)}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <Checkbox checked={checked.indexOf(item_id) !== -1} tabIndex={-1} disableRipple />
                            </ListItemIcon>
                            <ListItemText
                                id={`transfer-list-item-${item_id}-label`}
                                primary={getDescription(item_id)}
                                primaryTypographyProps={{
                                    noWrap: true,
                                    fontSize: '0.85rem',
                                }}
                            />
                        </ListItemButton>
                    ))}
            </List>
        </Paper>
    );
};

export default TransferList;
