import React, { useRef, useState } from 'react';

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import ArchiveIcon from '@mui/icons-material/Inventory';
import DotsIcon from '@mui/icons-material/MoreHoriz';

import ArchiveDrawer from './ArchiveDrawer';

import { ActionsMenuProps } from './ActionsMenu.d';

const ActionsMenu = (props: ActionsMenuProps) => {
    const { boardId, handleOpenCard } = props;

    /**
     * MENU CONTROL
     */
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    /**
     * ARCHIVE DRAWER CONTROL
     */
    const [archiveOpen, setArchiveOpen] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Button
                ref={anchorRef}
                size={'small'}
                onClick={() => setOpen(true)}
                sx={{
                    w: 30,
                    h: 30,
                    minWidth: 0,
                }}
                children={<DotsIcon />}
            />

            <Menu
                anchorEl={anchorRef.current}
                open={open}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 200,
                    },
                }}
            >
                <MenuItem
                    dense={true}
                    onClick={() => {
                        setOpen(false);
                        setArchiveOpen(true);
                    }}
                >
                    <ListItemIcon children={<ArchiveIcon />} />
                    <Typography children={'Itens Arquivados'} />
                </MenuItem>
            </Menu>

            <ArchiveDrawer
                boardId={boardId}
                open={archiveOpen}
                onClose={() => setArchiveOpen(false)}
                handleOpenCard={handleOpenCard}
            />
        </>
    );
};

export default ActionsMenu;
