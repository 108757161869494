import dayjs from 'dayjs';

import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';

import { Column, FILTER_LOOKUP_EXPRS, FilterLookup, TData } from 'src/components/crud/Crud.d';
import encodeFieldId from 'src/components/utils/encodeFieldId';

export const getLookupFilterField = (field: string, lookup: FilterLookup): string => {
    if (lookup === 'exact') {
        return field;
    }

    return `${field}__${lookup}`;
};

export const isLookupExpr = (expr: string): boolean => {
    return expr in FILTER_LOOKUP_EXPRS;
};

export const rmLookupFromFilter = (field: string): string => {
    const parts = field.split('__');

    if (isLookupExpr(parts[parts.length - 1])) {
        return parts.slice(0, -1).join('__');
    }

    return field;
};

export const rmLookups = (filters: MRT_ColumnFiltersState): MRT_ColumnFiltersState => {
    return filters.map((filter) => ({
        id: rmLookupFromFilter(filter.id),
        value: filter.value,
    }));
};

export const getParamsFromColumnFilters = <T extends TData>(
    columnFilters: MRT_ColumnFiltersState,
    columns: Column<T>[]
) => {
    const params = {} as Record<string, string>;

    columnFilters.forEach((filter) => {
        let filterValue = filter.value;
        let filterLookup: FilterLookup = 'icontains';

        // If the filter on the displayed columns
        if (columns.map((c) => c.accessorKey).includes(filter.id)) {
            const column = columns.filter((c) => c.accessorKey === filter.id)[0];

            // Format the field based on filter variant
            switch (column.filterVariant) {
                case 'date':
                    if (dayjs.isDayjs(filterValue)) {
                        filterValue = dayjs(filterValue).format('YYYY-MM-DD');
                    }
                    break;
            }

            if (column.filterLookup) {
                filterLookup = column.filterLookup;
            }
        }

        const filterField = getLookupFilterField(encodeFieldId(filter.id), filterLookup);
        params[filterField] = filterValue as string;
    });

    return params;
};

export const getSearchParams = <T extends TData>(
    globalFilter: string,
    columnFilters: MRT_ColumnFiltersState,
    columns: Column<T>[],
    pagination: MRT_PaginationState | null,
    sorting: MRT_SortingState | []
) => {
    const params = getParamsFromColumnFilters(columnFilters, columns);

    if (globalFilter) {
        params['search'] = globalFilter;
    }

    if (pagination) {
        params['limit'] = `${pagination.pageSize}`;
        params['offset'] = `${pagination.pageIndex * pagination.pageSize}`;
    }

    if (sorting.length > 0) {
        params['ordering'] = sorting.map((col) => (col.desc ? '-' : '') + encodeFieldId(col.id)).join(',');
    }

    return params;
};
