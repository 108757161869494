import React from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';

import useTenantAPI from 'src/services/api_tenant';

import ChecklistContent from 'src/pages/kanban/CardModal/ChecklistContent';
import { ChecklistSectionProps } from './ChecklistSection.d';

const ChecklistSection = (props: ChecklistSectionProps) => {
    const { boardId, cardId, refetchCard, current_checklists } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * CHECKLIST TEMPLATE
     */
    const submitAddChecklistTemplate = () => {
        return api_tenant
            .post(`api/kanban/boards/${boardId}/cards/${cardId}/checklists/template/add/`)
            .then((response) => response.data);
    };

    const {
        mutate: addChecklistTemplate,
        isLoading,
        isSuccess,
    } = useMutation(submitAddChecklistTemplate, {
        onSuccess: () => {
            refetchCard();
        },
        onError: (err: AxiosError<{ detail: string }>) => {
            if (err.response?.data?.detail) {
                enqueueSnackbar(err.response.data.detail, { variant: 'error' });
                return;
            }
            enqueueSnackbar('Erro ao adicionar template de checklist', { variant: 'error' });
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Stack spacing={1}>
            {(!current_checklists || current_checklists.length == 0) && (
                <Button
                    fullWidth
                    variant={'outlined'}
                    size={'small'}
                    color={'secondary'}
                    onClick={() => addChecklistTemplate()}
                    startIcon={isSuccess || isLoading ? <CircularProgress size={16} /> : <AddIcon fontSize={'small'} />}
                    disabled={isSuccess || isLoading}
                    children={'Add templates'}
                />
            )}

            {current_checklists.map((checklist, index) => (
                <ChecklistContent
                    key={index}
                    boardId={boardId}
                    cardId={cardId}
                    checklist={checklist}
                    refetchCard={refetchCard}
                />
            ))}
        </Stack>
    );
};

export default ChecklistSection;
