import RouteWithPermissions from 'src/routes/components/RouteWithPermissions';
import RouteWithSidebar from 'src/routes/components/RouteWithSidebar';
import { RouteOfSystemProps } from 'src/routes/components/RouteOfSystem';

const RouteOfSystem = (props: RouteOfSystemProps) => {
    const { page, superuser, permissions } = props;

    return (
        <RouteWithPermissions
            element={<RouteWithSidebar component={page.component} fullFrame={page.fullFrame} />}
            route={page.route}
            superuser={superuser}
            permissions={permissions}
        />
    );
};

export default RouteOfSystem;
