import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudPage from 'src/components/crud/CrudPage';

import { Column } from 'src/components/crud/Crud.d';
import { Vehicle } from './Vehicles.d';

export const name = {
    singular: 'Veículo',
    plural: 'Veículos',
};

export const columns = [
    {
        accessorKey: 'license_plate',
        header: 'Placa',
    },
    {
        accessorKey: 'integration_id',
        header: 'ID de Integração',
        filterLookup: 'exact',
    },
] as Column<Vehicle>[];

export const endpoint = '/api/vehicles/';

const EnterprisesPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: { xs: 0, md: 1 } }}>
                    <Breadcrumb items={['Cadastros', 'Veículos']} />
                </Box>
            </Stack>

            <CrudPage name={name} endpoint={endpoint} route={AppRoutes.Vehicles} columns={columns} />
        </>
    );
};

export default EnterprisesPage;
