import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useTenantAPI from 'src/services/api_tenant';

import { ChecklistContentProps } from './ChecklistContent.d';
import { ChecklistItem } from 'src/pages/kanban/Kanban.d';

const ChecklistContent = (props: ChecklistContentProps) => {
    const { boardId, cardId, checklist, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * CHECKLIST VALUES
     */
    const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>(checklist.items);

    useEffect(() => {
        if (checklistItems !== checklist.items) {
            setChecklistItems(checklist.items);
        }
    }, [checklist.items]);

    /**
     * CHECKLIST CHANGE CONTROL
     */
    const submitChecklistChange = (item_id: number, check_value: boolean) => {
        return api_tenant
            .put(`api/kanban/boards/${boardId}/cards/${cardId}/checklists/${checklist.id}/items/${item_id}/`, {
                check_value,
            })
            .then((res) => res.data);
    };

    const { mutateAsync: changeChecklist } = useMutation<void, Error, { item_id: number; check_value: boolean }>(
        ({ item_id, check_value }) => submitChecklistChange(item_id, check_value),
        {
            onError: (error) => {
                enqueueSnackbar('Erro ao marcar o checklist', { variant: 'error' });
                setChecklistItems(checklist.items);
                refetchCard();
            },
        }
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <Stack spacing={1}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography
                    variant={'body1'}
                    fontWeight={400}
                    sx={{
                        color: '#555',
                    }}
                    children={checklist.description}
                />
                <Divider sx={{ flex: 1, pt: 0.5 }} />
            </Stack>

            <Stack direction={'column'}>
                {checklistItems.map((item, index) => (
                    <Stack direction={'row'} key={index}>
                        <FormControlLabel
                            label={
                                <Typography
                                    variant={'body2'}
                                    children={item.description}
                                    sx={{
                                        color: item.check_value ? '#999' : '#555',
                                        textDecoration: item.check_value ? 'line-through' : 'none',
                                    }}
                                />
                            }
                            control={
                                <Checkbox
                                    // size={'small'}
                                    checked={item.check_value}
                                    onChange={(e) => {
                                        setChecklistItems((prev) =>
                                            prev.map((prevItem) => {
                                                if (prevItem.id === item.id) {
                                                    return { ...prevItem, check_value: e.target.checked };
                                                }
                                                return prevItem;
                                            })
                                        );
                                        changeChecklist({ item_id: item.id, check_value: e.target.checked });
                                    }}
                                    sx={{
                                        py: 0.3,
                                    }}
                                />
                            }
                        />
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default ChecklistContent;
