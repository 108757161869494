import React from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/WarningRounded';

const NotFoundError = () => {
    const navigate = useNavigate();

    return (
        <Stack justifyContent={'center'} alignItems={'center'} sx={{ height: '70vh' }}>
            <WarningIcon
                sx={{
                    color: 'red',
                    margin: 1,
                }}
            />
            <Typography variant={'h6'} children={'Erro ao carregar checklist!'} />
            <Typography variant={'subtitle1'} children={'Verifique se o checklist existe e tente novamente.'} />
            <Button
                variant={'contained'}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
                sx={{ mt: 2 }}
                children={'Voltar'}
            />
        </Stack>
    );
};

export default NotFoundError;
