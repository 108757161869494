import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import TodayIcon from '@mui/icons-material/Today';
import WarningIcon from '@mui/icons-material/WarningRounded';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/pt-br';

import useTenantAPI from 'src/services/api_tenant';

const Ranking = () => {
    const api_tenant = useTenantAPI();

    const [ranking, setRanking] = useState(null);
    const [rankMonth, setRankMonth] = useState(dayjs(new Date()).locale('pt-br'));

    /**
     * GET USER DATA ON PAGE LOAD
     */
    useEffect(() => {
        api_tenant.get(`api/metrics/performances/rank/${rankMonth.format('YYYY-MM')}/`).then((res) => {
            setRanking(res.data.data['ranking']);
        });
    }, [rankMonth]);

    /**
     * DATE PICKER
     */
    function ButtonField(props) {
        const { setOpen, InputProps: { ref } = {} } = props;

        return (
            <IconButton color="primary" variant="contained" ref={ref} onClick={() => setOpen?.((prev) => !prev)}>
                <TodayIcon fontSize={'small'} />
            </IconButton>
        );
    }

    function ButtonDatePicker(props) {
        const [open, setOpen] = React.useState(false);

        return (
            <DatePicker
                slots={{ field: ButtonField, ...props.slots }}
                slotProps={{ field: { setOpen } }}
                {...props}
                open={open}
                views={['year', 'month']}
                minDate={dayjs('2023-01-01')}
                maxDate={dayjs()}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />
        );
    }

    /**
     * TABLE CONTENT
     */
    function RankingTableContent() {
        if (ranking === null) {
            return (
                <>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <TableRow
                            key={`skeleton-row-${index}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {Array.from({ length: 5 }).map((_, index) => (
                                <TableCell key={`skeleton-row-${index}-cell-${index}`} align="center">
                                    <Skeleton />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </>
            );
        }

        return ranking?.map((row) => (
            <TableRow key={row['person']['id']} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align={'center'} component="th" scope="row">
                    {row['position']}º
                </TableCell>
                <TableCell align={'center'}>
                    {row['person']['first_name']} {row['person']['last_name']}
                </TableCell>
                <TableCell align={'center'}>{row['distance'].toFixed(2)}</TableCell>
                <TableCell align={'center'}>{row['consumption'].toFixed(2)}</TableCell>
                <TableCell align={'center'}>{row['average_consumption'].toFixed(2)}</TableCell>
            </TableRow>
        ));
    }

    function RankingContent() {
        if (ranking !== null && (ranking === undefined || ranking.length === 0)) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 1 }}>
                    <WarningIcon sx={{ color: 'red', margin: 1 }} />
                    <Typography variant={'h6'}>Ranking Indisponível!</Typography>
                    <Typography variant={'subtitle1'}>
                        Não foi possível determinar o ranking para o mês selecionado.
                    </Typography>
                </Box>
            );
        }

        return (
            <TableContainer sx={{ maxWidth: 800 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'center'}>
                                <b>#</b>
                            </TableCell>
                            <TableCell align={'center'}>
                                <b>Motorista</b>
                            </TableCell>
                            <TableCell align={'center'}>
                                <b>Distância (Km)</b>
                            </TableCell>
                            <TableCell align={'center'}>
                                <b>Consumo (L)</b>
                            </TableCell>
                            <TableCell align={'center'}>
                                <b>Média (Km/L)</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <RankingTableContent />
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <>
            <Typography variant={'h4'}>Ranking Média Diesel (Km/L)</Typography>
            <Divider sx={{ mt: 1, mb: 4, bgcolor: 'secondary.light' }} />

            <Stack alignItems={'center'}>
                <Box sx={{ display: 'inline-flex', alignContent: 'flex-start', alignItems: 'flex-start' }}>
                    <Typography variant={'h5'}>
                        {rankMonth.format('MMMM [de] YYYY').replace(/^\w/, (c) => c.toUpperCase())}
                    </Typography>
                    <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs}>
                        <ButtonDatePicker value={rankMonth} onChange={(newValue) => setRankMonth(newValue)} />
                    </LocalizationProvider>
                </Box>
                <RankingContent />
            </Stack>
        </>
    );
};

export default Ranking;
