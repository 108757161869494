import React, { Dispatch, SetStateAction, useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import UnCheckIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { Checklist, ChecklistItem } from 'src/pages/checklists/Checklists/Checklists.d';

const ItemsField = (
    values: Checklist,
    setValues: Dispatch<SetStateAction<Checklist>>,
    errors: any,
    readOnly: boolean
) => {
    /**
     * ITEMs HANDLERS
     */
    const [editingItem, setEditingItem] = useState<number | null>(null);

    const handleItemBlur = (index: number) => {
        setEditingItem(null);
        if (values.items[index].description === '') {
            setValues({
                ...values,
                items: values.items.filter((item: ChecklistItem, index: number) => index !== editingItem),
            });
        }
    };

    const handleItemChange = (newValue: string, index: number) => {
        const items = [...values.items];
        items[index].description = newValue;
        setValues({
            ...values,
            items: items,
        });
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const items = [...values.items];
        items[index].is_active = event.target.checked;
        setValues({
            ...values,
            items: items,
        });
    };

    const handleAddItem = () => {
        if (editingItem) {
            return;
        }
        setValues({
            ...values,
            items: [
                ...values.items,
                {
                    description: '',
                    is_active: true,
                },
            ],
        });
        setEditingItem(values.items.length);
    };

    return (
        <List key={'item-field'}>
            {values &&
                values.items &&
                values.items.map((item: ChecklistItem, index: number) => (
                    <ListItem disablePadding key={`item-${index}`}>
                        <ListItemButton disabled={readOnly} onClick={() => setEditingItem(index)}>
                            <ListItemIcon
                                sx={{
                                    minWidth: 'auto',
                                    mr: 1,
                                }}
                            >
                                <UnCheckIcon />
                            </ListItemIcon>
                            <ListItemText>
                                {editingItem === index ? (
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        size={'small'}
                                        variant={'standard'}
                                        value={item.description}
                                        disabled={readOnly}
                                        onBlur={() => handleItemBlur(index)}
                                        onChange={(e) => handleItemChange(e.target.value, index)}
                                    />
                                ) : (
                                    <Typography>{item.description}</Typography>
                                )}
                            </ListItemText>
                        </ListItemButton>
                        <Switch
                            edge={'end'}
                            disabled={readOnly}
                            onChange={(e) => handleSwitchChange(e, index)}
                            checked={item.is_active ?? true}
                        />
                    </ListItem>
                ))}
            <ListItem disablePadding>
                <ListItemButton onClick={handleAddItem} disabled={readOnly}>
                    <ListItemIcon
                        sx={{
                            minWidth: 'auto',
                            mr: 1,
                        }}
                    >
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Novo item'} />
                </ListItemButton>
            </ListItem>
        </List>
    );
};

export default ItemsField;
