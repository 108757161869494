import React, { Dispatch, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import UserAddIcon from '@mui/icons-material/PersonAdd';
import UserIcon from '@mui/icons-material/Person';

import AppRoutes from 'src/routes/appRoutes';
import { ACTIONS, hasRoutePermission } from 'src/services/storage/permissions';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect/CrudApiSelect';

import { Person } from 'src/pages/registry/persons/Persons';

const UserField = (
    values: Person,
    setValues: Dispatch<SetStateAction<Person>>,
    errors: any,
    readOnly: boolean,
    openUserModal: (values: Person) => void
) => {
    return (
        <Stack key={'user'} spacing={1} direction={'row'}>
            <Box flexGrow={1}>
                <CrudApiSelect
                    column={{
                        accessorKey: 'user',
                        header: 'Usuário',
                        field: {
                            type: 'search',
                            name: 'user',
                            endpoint: '/api/users/',
                            labelKey: (v) => v?.username ?? '',
                        },
                    }}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    readOnly={readOnly}
                />
            </Box>
            <Button
                color={'primary'}
                variant={'contained'}
                onClick={() => openUserModal(values)}
                disabled={!hasRoutePermission(AppRoutes.Users, ACTIONS.VIEW)}
            >
                {values['user'] ? <UserIcon /> : <UserAddIcon />}
            </Button>
        </Stack>
    );
};

export default UserField;
