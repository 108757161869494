import React from 'react';
import ReactDOM from 'react-dom/client';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';

import { createTheme, ThemeProvider } from '@mui/material';

import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// custom styles
import './styles/styles.css';

// vendor styles
import 'react-datetime/css/react-datetime.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
    palette: {
        primary: {
            main: '#006bf9',
        },
        secondary: {
            main: '#262b40',
        },
    },
    typography: {
        fontFamily: ['"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

dayjs.locale('pt-br');

root.render(
    <LocalizationProvider
        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}
        adapterLocale={'pt-br'}
    >
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider>
                    <App />
                </SnackbarProvider>
            </QueryClientProvider>
        </ThemeProvider>
    </LocalizationProvider>
);
