import React from 'react';

import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';

import getNestedValue from 'src/components/utils/getNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';

import { TData } from 'src/components/crud/Crud.d';
import { CrudDatetimePickerProps } from 'src/components/crud/fields/CrudDatetimePicker';

const CrudDatetimePicker = <T extends TData>(props: CrudDatetimePickerProps<T>) => {
    const { column, values, setValues, errors, readOnly = false, size = 'medium' } = props;

    return (
        <DateTimePicker
            label={column.header}
            value={dayjs(getNestedValue(values, column.accessorKey, null))}
            disabled={readOnly}
            onChange={(e) => {
                setValues(setNestedValue(values, column.accessorKey, e));
            }}
            slotProps={{
                textField: {
                    size: size,
                    error: hasNestedValue(errors, column.accessorKey),
                    helperText: getNestedValue(errors, column.accessorKey, ''),
                },
            }}
        />
    );
};

export default CrudDatetimePicker;
