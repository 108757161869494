import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import ReminderAddIcon from '@mui/icons-material/AlarmAdd';
import ShowIcon from '@mui/icons-material/Visibility';

import useTenantAPI from 'src/services/api_tenant';

import CardAccordion from 'src/pages/kanban/CardModal/CardAccordion';
import ReminderMenu from './ReminderMenu';
import { SectionDatesProps } from './SectionDates.d';
import { Reminder } from 'src/pages/kanban/Kanban.d';
import ReminderCard from './ReminderCard';

const SectionDates = (props: SectionDatesProps) => {
    const { boardId, card, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * DEADLINE FIELD CONTROL
     */
    const [deadline, setDeadline] = useState<Date | null>(card.deadline);

    useEffect(() => {
        setDeadline(card.deadline);
    }, [card.deadline]);

    /**
     * DEADLINE SUBMIT CONTROL
     */
    const [hasDeadlineChanges, setHasDeadlineChanges] = useState(false);
    const submitDeadlineChange = () => {
        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${card.id}/`, {
                deadline: deadline ?? null,
            })
            .then((res) => res.data);
    };

    const { mutateAsync: handleDeadlineChange, isLoading: isDeadlineLoading } = useMutation(submitDeadlineChange, {
        onSuccess: () => {
            refetchCard();
            setHasDeadlineChanges(false);
        },
        onError: () => enqueueSnackbar('Erro ao atualizar a data de prazo.', { variant: 'error' }),
    });

    /**
     * REMINDERS CONTROL
     */
    const [showDoneReminders, setShowDoneReminders] = useState(false);
    const [doneReminders, setDoneReminders] = useState<Reminder[]>(card.reminders.filter((r) => r.is_done));
    const [pendingReminders, setPendingReminders] = useState<Reminder[]>(card.reminders.filter((r) => !r.is_done));

    useEffect(() => {
        setDoneReminders(card.reminders.filter((r) => r.is_done));
        setPendingReminders(card.reminders.filter((r) => !r.is_done));
    }, [card.reminders]);

    /**
     * MENU CONTROL
     */
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [menuEntity, setMenuEntity] = useState<null | Reminder>(null);
    const open = Boolean(menuAnchor);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <CardAccordion title={'Datas'}>
                <Stack spacing={1}>
                    <DateTimePicker
                        label={'Prazo do Cartão'}
                        value={deadline ? dayjs(deadline) : null}
                        onChange={(date) => {
                            setDeadline(date?.toDate() ?? null);
                            setHasDeadlineChanges(true);
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                            },
                            actionBar: {
                                actions: ['clear', 'accept'],
                            },
                        }}
                    />
                    {hasDeadlineChanges && (
                        <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                            <Button
                                disabled={isDeadlineLoading}
                                variant={'contained'}
                                onClick={() => handleDeadlineChange()}
                                startIcon={isDeadlineLoading && <CircularProgress size={16} sx={{ mr: 1 }} />}
                                children={'Salvar'}
                            />
                        </Stack>
                    )}

                    <Stack spacing={1} sx={{ pt: 2 }}>
                        {pendingReminders.map((reminder) => (
                            <ReminderCard
                                key={reminder.id}
                                cardId={card.id}
                                boardId={boardId}
                                reminder={reminder}
                                refetchCard={refetchCard}
                                setMenuEntity={setMenuEntity}
                                setMenuAnchor={setMenuAnchor}
                            />
                        ))}

                        <Box
                            sx={{
                                maxHeight: showDoneReminders ? '100vh' : '0px',
                                transition: showDoneReminders ? 'max-height 0.3s ease-in' : 'max-height 0.3s ease-out',
                                overflow: 'hidden',
                            }}
                        >
                            <Stack spacing={1}>
                                {doneReminders.map((reminder) => (
                                    <ReminderCard
                                        key={reminder.id}
                                        cardId={card.id}
                                        boardId={boardId}
                                        reminder={reminder}
                                        refetchCard={refetchCard}
                                        setMenuEntity={setMenuEntity}
                                        setMenuAnchor={setMenuAnchor}
                                    />
                                ))}
                            </Stack>
                        </Box>

                        {doneReminders.length > 0 && (
                            <Stack
                                direction={'row'}
                                justifyContent={'flex-end'}
                                sx={{
                                    mt: '0px !important',
                                }}
                            >
                                <Button
                                    onClick={() => setShowDoneReminders((v) => !v)}
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '0.65rem',
                                    }}
                                >
                                    <ShowIcon sx={{ fontSize: '0.65rem', mr: 0.5 }} />
                                    {showDoneReminders ? 'Ocultar' : 'Mostrar'} Realizados
                                </Button>
                            </Stack>
                        )}

                        <Button
                            fullWidth
                            color={'secondary'}
                            onClick={(e) => {
                                setMenuAnchor(e.currentTarget);
                            }}
                            startIcon={<ReminderAddIcon />}
                            children={'Adicionar Lembrete'}
                        />
                    </Stack>
                </Stack>
            </CardAccordion>

            <ReminderMenu
                open={open}
                anchor={menuAnchor}
                reminder={menuEntity}
                onClose={() => {
                    setMenuAnchor(null);
                    setMenuEntity(null);
                }}
                boardId={boardId}
                cardId={card.id}
                refetchCard={refetchCard}
            />
        </>
    );
};

export default SectionDates;
