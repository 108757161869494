import React, { Dispatch, SetStateAction } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import { Achievement } from 'src/pages/registry/achievements';

const IsCumulativeField = (
    values: Achievement,
    setValues: Dispatch<SetStateAction<Achievement>>,
    errors: any,
    readOnly: boolean
) => {
    if (!values['metric'] || ['2', '3'].includes(values['metric'].toString())) {
        values['is_cumulative'] = false;
        return;
    }

    return (
        <FormControl key={'is-cumulative-field'} error={errors?.hasOwnProperty('has_day_streak') ?? false}>
            <FormControlLabel
                sx={{
                    pb: 0,
                    pt: 0,
                    mb: 0,
                    mt: 0,
                }}
                label={'Meta Acumulada'}
                control={
                    <Checkbox
                        sx={{
                            pb: 0,
                            pt: 0,
                            mb: 0,
                            mt: 0,
                        }}
                        name={'is_cumulative'}
                        checked={values['is_cumulative']}
                        disabled={readOnly}
                        onChange={(e) => {
                            setValues({
                                ...values,
                                is_cumulative: e.target.checked,
                            });
                        }}
                    />
                }
            />
            {errors?.hasOwnProperty('is_cumulative') ? (
                <FormHelperText>{errors['is_cumulative']}</FormHelperText>
            ) : (
                <></>
            )}
        </FormControl>
    );
};

export default IsCumulativeField;
