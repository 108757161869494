import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useTenantAPI from 'src/services/api_tenant';
import DashboardsBody from './components/DashboardsBody';
import { GroupMap } from './Dashboard.d';

export const groups = {
    abastecimentos: {
        id: 1,
        description: 'Abastecimentos',
    },
    acertos: {
        id: 2,
        description: 'Acertos',
    },
    produtividade: {
        id: 3,
        description: 'Produtividade',
    },
    rh: {
        id: 4,
        description: 'RH',
    },
    faturamento: {
        id: 5,
        description: 'Faturamento',
    },
    telemetria: {
        id: 6,
        description: 'Telemetria',
    },
    instrutores: {
        id: 7,
        description: 'Instrutores',
    },
} as GroupMap;

const DashboardsGroupPage = () => {
    const navigate = useNavigate();
    const api_tenant = useTenantAPI();

    const [dashboards, setDashboards] = useState(null);

    const { group } = useParams();

    /**
     * GET USER DATA ON PAGE LOAD
     */
    useEffect(() => {
        setDashboards(null);

        if (!group || !groups.hasOwnProperty(group)) {
            enqueueSnackbar('Grupo de paineis inválido!', { variant: 'error' });
            navigate('/dashboards/abastecimentos');
            return;
        }

        try {
            api_tenant
                .get(`api/dashboards/group/${groups[group].id}/my`)
                .then((res) => setDashboards(res.data.results));
        } catch (error) {
            enqueueSnackbar('Erro ao carregar paineis!', { variant: 'error' });
        }
    }, [group]);

    return (
        <>
            <Typography variant={'h4'}>Dashboards {groups[group ?? '']?.description}</Typography>
            <Divider
                sx={{
                    mt: 1,
                    mb: 4,
                    bgcolor: 'secondary.light',
                }}
            />

            <Grid container spacing={2} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <DashboardsBody dashboards={dashboards} />
            </Grid>
        </>
    );
};

export default DashboardsGroupPage;
