import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { LabelProps } from 'src/pages/kanban/Card/CardLabels';
import { Label } from 'src/pages/kanban/Kanban.d';

const CardLabels = (props: LabelProps) => {
    const { labels } = props;

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            sx={{
                pb: 0.5,
            }}
        >
            {labels.map((label: Label) => (
                <Box
                    key={`label-${label.id}`}
                    sx={{
                        bgcolor: `#${label.color}`,
                        width: 35,
                        height: 6,
                        borderRadius: 3,
                    }}
                />
            ))}
        </Stack>
    );
};

export default CardLabels;
