import React from 'react';

import { Droppable } from '@hello-pangea/dnd';
import styled from '@mui/material/styles/styled';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Card from 'src/pages/kanban/Card';
import { CardColumnProps } from './CardColumn.d';

const BoxWithFancyScroll = styled(Box)({
    scrollbarWidth: 'thin',
});

const CardColumn = (props: CardColumnProps) => {
    const { column, onCardClick } = props;

    return (
        <Stack flex={1} direction={'column'} spacing={1} p={0.5}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                gap={0.5}
                sx={{
                    px: 1,
                    py: 1.5,
                    color: '#262626',
                    bgcolor: '#f1f2f4',
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                }}
            >
                <Typography variant={'body2'} children={column.name} fontWeight={500} />
                {column.cards.length > 0 && '-'}
                {column.cards.length > 0 && (
                    <Typography
                        variant={'caption'}
                        fontWeight={300}
                        children={`${column.cards.length} ${column.cards.length > 1 ? 'itens' : 'item'}`}
                    />
                )}
            </Stack>

            <Droppable droppableId={column.id.toString()}>
                {(droppableProvided, snapshot) => (
                    <Box
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        className={snapshot.isDraggingOver ? ' isDraggingOver' : ''}
                        flexGrow={1}
                        sx={{
                            pt: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            '&.isDraggingOver': {
                                bgcolor: '#dadadf',
                            },
                            bgcolor: '#f1f2f4',
                            height: '0px', //  This is necessary to make the column not overflow the parent
                        }}
                    >
                        <BoxWithFancyScroll
                            sx={{
                                px: 0.5,
                                mx: 0.5,
                                height: '100%',
                                overflowY: 'auto',
                            }}
                        >
                            {column.cards.map((card, index) => (
                                <Card key={card.id} card={card} index={index} onCardClick={onCardClick} />
                            ))}
                            {droppableProvided.placeholder}
                        </BoxWithFancyScroll>
                    </Box>
                )}
            </Droppable>
        </Stack>
    );
};

export default CardColumn;
