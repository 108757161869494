import axios from 'axios';

import { getToken } from './auth';
import { getCachedCompanyData } from './storage/company';
import { logout } from './storage/user';

const useTenantAPI = () => {
    // API FROM ENV
    const API_URL = process.env.REACT_APP_API_URL ?? '';
    const [http, domain] = API_URL.split('//');
    const subdomain = getCachedCompanyData()?.subdomain;

    if (!subdomain) {
        logout();
        return axios.create({
            baseURL: `${http}//${domain}`,
        });
    }

    const api_tenant = axios.create({
        baseURL: `${http}//${subdomain}.${domain}`,
    });

    /**
     * Add an interceptor to attach the Authorization header with token to every request
     */
    api_tenant.interceptors.request.use(async (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    });

    /**
     * Add an interceptor to handle response errors, such as unauthorized access
     */
    api_tenant.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );

    return api_tenant;
};

export default useTenantAPI;
