import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';

const EventsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Configurações', 'Eventos']} />
                </Box>
            </Stack>

            <CrudAPI
                // CRUD INFO
                name={{
                    singular: 'Evento',
                    plural: 'Eventos',
                }}
                route={AppRoutes.Events}
                endpoint={'/api/telemetry/events/'}
                columns={[
                    {
                        accessorKey: 'description',
                        header: 'Descrição',
                    },
                    {
                        accessorKey: 'integration_id',
                        header: 'ID de Integração',
                        field: {
                            type: 'number',
                            step: 1,
                        },
                        filterLookup: 'exact',
                    },
                ]}
            />
        </>
    );
};

export default EventsPage;
