import React from 'react';

import Card from '@mui/material/Card';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import { ChecklistResponseChecklistItem, URGENCIES_MAP } from 'src/pages/checklists/Checklists/ChecklistRequestsPage';

import { ResponseModalProps } from './ResponseModal.d';

const ResponseModal = (props: ResponseModalProps) => {
    const { open, setOpen, entity } = props;

    const handleModalClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleModalClose} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle textAlign={'center'}>
                Solicitação #{String(entity?.id).padStart(4, '0')}
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                    onClick={handleModalClose}
                    children={<CloseIcon />}
                />
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {entity?.checklist?.items &&
                        entity?.checklist?.items.map((item: ChecklistResponseChecklistItem) => {
                            const urgency_str = item.response.urgency;
                            const urgency =
                                urgency_str in URGENCIES_MAP ? URGENCIES_MAP[urgency_str] : URGENCIES_MAP[1];

                            return (
                                <Card
                                    key={item.id}
                                    sx={{
                                        width: '100%',
                                        p: 2,
                                    }}
                                >
                                    <Stack direction={'row'}>
                                        {item.response.check_value ? (
                                            <CheckIcon color={'success'} />
                                        ) : (
                                            <CloseIcon color={'error'} />
                                        )}
                                        <Typography sx={{ ml: 1 }}>{item.description}</Typography>
                                    </Stack>
                                    {item.response.observation != '' && (
                                        <>
                                            <TextField
                                                fullWidth
                                                disabled
                                                multiline
                                                sx={{ mt: 1 }}
                                                value={item.response.observation}
                                            />
                                            <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ mt: 1.5 }}>
                                                <Typography>Urgência: </Typography>
                                                <Chip
                                                    size={'small'}
                                                    label={urgency.label}
                                                    sx={{
                                                        backgroundColor: `${urgency.color}`,
                                                        fontWeight: 'bold',
                                                    }}
                                                />
                                            </Stack>
                                        </>
                                    )}
                                </Card>
                            );
                        })}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ResponseModal;
