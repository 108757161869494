import React from 'react';

import { useQuery } from '@tanstack/react-query';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import EmptyIcon from '@mui/icons-material/Inbox';
import WarningIcon from '@mui/icons-material/WarningRounded';

import useTenantAPI from 'src/services/api_tenant';

import CardContent from 'src/pages/kanban/Card/CardContent';

import { Card as CardT } from 'src/pages/kanban/Kanban.d';
import { ArchiveDrawerProps } from 'src/pages/kanban/ActionsMenu/ArchiveDrawer';

const ArchiveDrawer = (props: ArchiveDrawerProps) => {
    const { open, onClose, boardId, handleOpenCard } = props;

    const api_tenant = useTenantAPI();

    /**
     * LOAD CARDS
     */
    const fetchArchivedCards = async () => {
        return await api_tenant.get(`api/kanban/boards/${boardId}/cards/archived/`).then((res) => res.data.results);
    };

    const {
        data: archivedCards,
        isLoading,
        isError,
        refetch,
    } = useQuery<CardT[]>({
        queryKey: ['archived-cards', boardId],
        queryFn: fetchArchivedCards,
        enabled: open,
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Drawer
            open={open && !!boardId}
            onClose={onClose}
            anchor={'right'}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 300,
                },
            }}
        >
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant={'h6'} children={'Itens Arquivados'} />
                    </ListItemText>
                    <IconButton onClick={onClose} children={<CloseIcon />} />
                </ListItem>
                <Divider />
            </List>

            {isLoading || isError || !archivedCards || archivedCards?.length === 0 ? (
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                    sx={{
                        p: 2,
                        height: '100%',
                    }}
                >
                    {isLoading && <CircularProgress />}
                    {isError && (
                        <Stack direction={'column'} alignItems={'center'} spacing={0.5}>
                            <WarningIcon sx={{ fontSize: 30 }} />
                            <Typography children={'Erro ao carregar itens arquivados'} />
                        </Stack>
                    )}
                    {archivedCards && archivedCards.length === 0 && (
                        <Stack direction={'column'} alignItems={'center'} spacing={0.5}>
                            <EmptyIcon sx={{ fontSize: 30 }} />
                            <Typography children={'Nenhum item arquivado'} />
                        </Stack>
                    )}
                </Stack>
            ) : (
                <Stack
                    sx={{
                        p: 2,
                    }}
                >
                    {archivedCards.map((card) => (
                        <CardContent
                            key={card.id}
                            card={card}
                            onClick={() => {
                                refetch();
                                handleOpenCard(card);
                            }}
                        />
                    ))}
                </Stack>
            )}
        </Drawer>
    );
};

export default ArchiveDrawer;
