import React from 'react';

import dayjs from 'dayjs';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import AlarmIcon from '@mui/icons-material/AccessAlarm';
import ClockIcon from '@mui/icons-material/AccessTime';

import { CardAlertProps } from 'src/pages/kanban/Card/CardAlert';

const CardDateAlert = (props: CardAlertProps) => {
    const { date } = props;

    const is_past = dayjs(date).isBefore(dayjs(), 'day');
    const Icon = is_past ? AlarmIcon : ClockIcon;

    return (
        <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            sx={{
                p: 0.5,
                mt: 0.5,
                borderRadius: 1,
                bgcolor: is_past ? '#FFE0DE' : '#FFF4E5',
            }}
        >
            <Icon
                color={is_past ? 'error' : 'warning'}
                sx={{
                    fontSize: 16,
                    p: 0,
                    pb: 0.2,
                }}
            />
            <Typography
                variant={'caption'}
                children={dayjs(date).format('DD [de] MMM [de] YYYY')}
                sx={{
                    p: 0,
                }}
            />
        </Stack>
    );
};

export default CardDateAlert;
