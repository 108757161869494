import React, { useEffect, useRef, useState } from 'react';

import styled from '@mui/material/styles/styled';

import TextField from '@mui/material/TextField';

import SearchIcon from '@mui/icons-material/Search';
import { SearchFieldProps } from './SearchField.d';

const StyledSearchField = styled(TextField)({
    '& .MuiInputBase-adornedStart': {
        paddingLeft: '8px',
    },
});

const SearchField = (props: SearchFieldProps) => {
    const { filters, setFilters } = props;

    const typingTimeoutRef = useRef<any>(null);
    const [searchText, setSearchText] = useState(filters.search || '');

    useEffect(() => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            setFilters((f) => ({ ...f, search: searchText }));
        }, 750);

        return () => clearTimeout(typingTimeoutRef.current);
    }, [searchText]);

    return (
        <StyledSearchField
            size={'small'}
            variant={'outlined'}
            placeholder={'Pesquisar'}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            InputProps={{
                startAdornment: <SearchIcon fontSize={'small'} sx={{ mr: 1, color: '#555' }} />,
            }}
        />
    );
};

export default SearchField;
