import React from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { ConfirmAttrRowProps } from './ConfirmAttrRow.d';

const ConfirmAttrRow = (props: ConfirmAttrRowProps) => {
    const { name, value } = props;

    return (
        <TableRow>
            <TableCell sx={{ color: '#5e5e5e' }}>
                <b>{name}</b>
            </TableCell>
            <TableCell align={'right'}>{value}</TableCell>
        </TableRow>
    );
};

export default ConfirmAttrRow;
