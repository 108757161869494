import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { TransitionGroup } from 'react-transition-group';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import ArchivedIcon from '@mui/icons-material/Inventory';
import CloseIcon from '@mui/icons-material/Close';

import useTenantAPI from 'src/services/api_tenant';

import ActionsMenu from './ActionsMenu';
import CardModalContent from './CardModalContent';
import CardModalEntityLog from './CardModalEntityLog';
import CardTitle from './CardTitle';

import { BOARD_TYPE_ICON_MAP } from 'src/pages/kanban/KanbanPage';
import { CardDetail, CardModalProps, TimelineItem } from 'src/pages/kanban/Kanban.d';

const getEntityId = (boardType: string, cardSummary: any) => {
    if (boardType === 'driver' && 'driver' in cardSummary) {
        return cardSummary.driver.id;
    }

    if (boardType === 'customer' && 'enterprise' in cardSummary) {
        return cardSummary.enterprise.id;
    }

    return null;
};

const CardModal = (props: CardModalProps) => {
    const { open, setOpen, boardType, boardId, cardSummary, refetch } = props;

    const api_tenant = useTenantAPI();

    /**
     * CARD LOAD
     */
    const [cardDetail, setCardDetail] = useState<CardDetail | null>(null);
    const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([]);

    const fetchCard = () => {
        return api_tenant
            .get(`api/kanban/boards/${boardId}/cards/${cardSummary.id}/`)
            .then((response) => response.data);
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        remove,
        refetch: refetchCard,
    } = useQuery<CardDetail>({
        enabled: open,
        queryKey: ['card', boardId, cardSummary.id],
        queryFn: fetchCard,
    });

    useEffect(() => {
        if (isSuccess) {
            if (data.timeline) {
                setTimelineItems(data.timeline);
            }

            delete data.timeline;
            setCardDetail(data);
        }
    }, [data]);

    /**
     * DIALOG ACTIONS
     */
    const handleClose = () => {
        setOpen(false);
        setCardDetail(null);
        setTimelineItems([]);
        if (refetch) {
            refetch();
        }
        remove();
    };

    /**
     * DIALOG CONTENT MODES
     */
    const [cardMode, setCardMode] = useState<'card' | 'log'>('card');
    const isArchived = cardDetail && cardDetail.is_archived;

    const BoardTypeIcon = BOARD_TYPE_ICON_MAP[boardType];

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} fullWidth={true} maxWidth={'lg'} onClose={handleClose}>
            <DialogContent
                sx={{
                    p: 0,
                    pb: 5,
                    overflowX: 'hidden',
                    minHeight: '90vh',
                    maxHeight: '90vh',
                }}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    sx={{
                        pt: 2,
                        px: 2,
                        background: isArchived
                            ? 'repeating-linear-gradient(-45deg, #f4efda, #f4efda 6px, #fff9e1 6px, #fff9e1 12px)'
                            : 'white',
                    }}
                >
                    <Stack sx={{ color: '#555' }}>
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            sx={{
                                pl: 3.5,
                            }}
                        >
                            <Typography variant={'caption'}>
                                {isLoading || isError || !cardDetail ? (
                                    <Skeleton animation={'wave'} width={50} />
                                ) : (
                                    cardDetail.board.name
                                )}
                            </Typography>
                            <Typography variant={'caption'} children={`/`} />
                            <Typography
                                variant={'caption'}
                                children={`Card ${String(cardSummary.id).padStart(4, '0')}`}
                            />
                        </Stack>

                        {isArchived && (
                            <Stack
                                direction={'row'}
                                spacing={0.5}
                                alignItems={'center'}
                                sx={{
                                    color: 'darkorange',
                                }}
                            >
                                <ArchivedIcon fontSize={'small'} />
                                <Typography variant={'h6'} children={'Cartão Arquivado'} fontWeight={'bold'} />
                            </Stack>
                        )}

                        <Stack direction={'row'} spacing={1} sx={{ zIndex: 1305 }}>
                            <BoardTypeIcon
                                fontSize={'small'}
                                sx={{
                                    mt: 0.75,
                                }}
                            />
                            <CardTitle
                                boardId={boardId}
                                cardId={cardSummary.id}
                                name={cardSummary.name}
                                refetchCard={refetchCard}
                            />
                        </Stack>
                    </Stack>

                    <Box>
                        <Stack direction={'row'} alignItems={'center'}>
                            {cardDetail ? (
                                <ActionsMenu boardId={boardId} cardDetail={cardDetail} refetchCard={refetchCard} />
                            ) : (
                                <Skeleton animation={'wave'} width={25} sx={{ m: 2 }} />
                            )}
                            <IconButton onClick={handleClose} children={<CloseIcon />} />
                        </Stack>
                    </Box>
                </Stack>

                <Box
                    sx={{
                        pl: 2,
                        pr: 2,
                    }}
                >
                    {isLoading || isError || !cardDetail ? (
                        <Stack
                            direction={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{ height: '70vh' }}
                        >
                            {isError ? <Typography children={'Erro ao carregar card.'} /> : <CircularProgress />}
                        </Stack>
                    ) : (
                        <TransitionGroup>
                            {cardMode === 'card' ? (
                                <Slide unmountOnExit key={'card'} direction={'right'}>
                                    <Box>
                                        <CardModalContent
                                            boardId={boardId}
                                            boardType={boardType}
                                            cardId={cardSummary.id}
                                            cardDetail={cardDetail}
                                            timelineItems={timelineItems}
                                            refetchCard={refetchCard}
                                            setCardMode={setCardMode}
                                        />
                                    </Box>
                                </Slide>
                            ) : (
                                <Slide unmountOnExit key={'log'} direction={'left'}>
                                    <div>
                                        <CardModalEntityLog
                                            boardType={boardType}
                                            entityId={getEntityId(boardType, cardSummary)}
                                            setCardMode={setCardMode}
                                        />
                                    </div>
                                </Slide>
                            )}
                        </TransitionGroup>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CardModal;
