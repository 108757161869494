import React, { useState } from 'react';

import Grid from '@mui/material/Grid';

import TransferList from 'src/components/crud/fields/CrudTransferSelect/TransferList';
import TransferButton from 'src/components/crud/fields/CrudTransferSelect/TransferButton';

import { TData } from 'src/components/crud/Crud.d';
import { TransferFieldProps } from './TransferField.d';

function not(a: number[], b: number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: number[], b: number[]) {
    return a.filter((item) => b.indexOf(item) !== -1);
}

const TransferField = <T extends TData>(props: TransferFieldProps<T>) => {
    const { descriptions, right, setRight } = props;

    const [left, setLeft] = useState<number[]>(
        not(
            descriptions.map((item) => item.id),
            right
        )
    );

    const [checked, setChecked] = useState<number[]>([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const toggleSelected = (id: number) => {
        const currentIndex = checked.indexOf(id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    return (
        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={5}>
                <TransferList
                    items={left}
                    checked={checked}
                    handleToggle={toggleSelected}
                    itemsDescription={descriptions}
                />
            </Grid>
            <Grid item xs={2}>
                <Grid container direction={'column'} alignItems={'center'}>
                    <TransferButton onClick={handleAllRight} disabled={left.length === 0} icon={'≫'} />
                    <TransferButton onClick={handleCheckedRight} disabled={leftChecked.length === 0} icon={'>'} />
                    <TransferButton onClick={handleCheckedLeft} disabled={rightChecked.length === 0} icon={'<'} />
                    <TransferButton onClick={handleAllLeft} disabled={right.length === 0} icon={'≪'} />
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <TransferList
                    items={right}
                    checked={checked}
                    handleToggle={toggleSelected}
                    itemsDescription={descriptions}
                />
            </Grid>
        </Grid>
    );
};

export default TransferField;
