import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import getNestedValue from 'src/components/utils/getNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';

import { CrudCheckBoxProps } from 'src/components/crud/fields/CrudCheckBox';
import { TData } from 'src/components/crud/Crud.d';

const CrudCheckBox = <T extends TData>(props: CrudCheckBoxProps<T>) => {
    const { column, values, setValues, errors, readOnly = false } = props;

    return (
        <FormControl error={hasNestedValue(errors, column.accessorKey)}>
            <FormControlLabel
                label={column.header}
                control={
                    <Checkbox
                        name={column.accessorKey}
                        checked={!!getNestedValue(values, column.accessorKey, column.field?.default)}
                        disabled={readOnly}
                        onChange={(e) => {
                            setValues(setNestedValue(values, column.accessorKey, e.target.checked));
                        }}
                        sx={{
                            py: 0,
                            my: 0,
                        }}
                    />
                }
                sx={{
                    py: 0,
                    my: 0,
                }}
            />
            {hasNestedValue(errors, column.accessorKey) && (
                <FormHelperText children={getNestedValue(errors, column.accessorKey, '')} />
            )}
        </FormControl>
    );
};

export default CrudCheckBox;
