import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import ArrowIcon from '@mui/icons-material/ArrowForwardIosSharp';

import { CollapsableNavItemProps } from 'src/components/sidebar/CollapsableNavItem';

import { checkRoutePermission } from 'src/services/storage/permissions';
import { isModuleEnabled } from 'src/services/storage/modules';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    minHeight: 40,
    maxHeight: 40,
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'white',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-root': {
        paddingRight: 0,
        paddingLeft: 0,
    },
    '& .MuiAccordionSummary-content': {
        paddingRight: 0,
        maxWidth: '92%',
    },
}));

const CollapsableNavItem = (props: CollapsableNavItemProps) => {
    const { title, icon: Icon, imageSrc, children, permissions } = props;

    const childList = Array.isArray(children) ? children : [children];
    const hasChildren = childList.reduce((acc, child) => {
        return (
            acc ||
            (!(child.props.route.enable_key && !isModuleEnabled(child.props.route.enable_key)) &&
                checkRoutePermission(permissions, child.props.route, 'view'))
        );
    }, false);

    if (!hasChildren) {
        return null;
    }

    return (
        <Accordion
            elevation={0}
            disableGutters
            sx={{
                p: 0,
                background: 'none',
                '&:before': { display: 'none' },
            }}
        >
            <AccordionSummary
                expandIcon={<ArrowIcon sx={{ fontSize: '0.9rem' }} />}
                sx={{
                    m: 0,
                    px: 1,
                    borderRadius: 1,
                    '&:hover': {
                        backgroundColor: '#2e3650',
                    },
                }}
                color={'secondary'}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'start'} spacing={1} maxWidth={'90%'}>
                    {Icon && <Icon sx={{ color: 'white', fontSize: '0.9rem' }} />}
                    {imageSrc && <img src={imageSrc} height={'20px'} />}
                    <Typography
                        color={'white'}
                        fontSize={'0.9rem'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        sx={{
                            textTransform: 'none',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {title}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    pr: 0,
                    pl: 2,
                }}
            >
                <Stack>{children}</Stack>
            </AccordionDetails>
        </Accordion>
    );
};

export default CollapsableNavItem;
