import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import WarningIcon from '@mui/icons-material/WarningRounded';

import AppRoutes from 'src/routes/appRoutes';

import ActionAreaCard from 'src/pages/dashboards/components/ActionAreaCard';
import { DashboardsBodyProps } from './DashboardsBody.d';

const DashboardsBody = (props: DashboardsBodyProps) => {
    const { dashboards } = props;

    if (dashboards == null) {
        return (
            <Grid item xs>
                <Stack alignItems={'center'} sx={{ py: 5 }}>
                    <CircularProgress />
                </Stack>
            </Grid>
        );
    }

    if (dashboards.length === 0) {
        return (
            <Grid item xs>
                <Stack alignItems={'center'} sx={{ py: 5 }}>
                    <WarningIcon fontSize={'large'} sx={{ color: '#5D5D5D' }} />
                    <Typography variant={'h5'} textAlign={'center'} sx={{ color: '#5D5D5D' }}>
                        Nenhuma Dashboard Disponível.
                    </Typography>
                </Stack>
            </Grid>
        );
    }

    return (
        <>
            {dashboards.map((d) => {
                return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={d.id}>
                        <ActionAreaCard
                            description={d.description}
                            link={AppRoutes.Dashboard.path.replace(':slug', d.id.toString())}
                        />
                    </Grid>
                );
            })}
        </>
    );
};

export default DashboardsBody;
