import React from 'react';
import { enqueueSnackbar } from 'notistack';

import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

import useTenantAPI from 'src/services/api_tenant';

import { ListTableContentProps } from 'src/pages/registry/persons/Persons/UserModal/AddGroupsModal/GroupListTable/ListTableContent/ListTableContent.d';

const ListTableContent = (props: ListTableContentProps) => {
    const { personGroupsEntity, fetchPersonGroups, groupList, setGroupLoad } = props;

    const api_tenant = useTenantAPI();

    /**
     * HANDLE REMOVE GROUP FROM PERSON
     */
    const handleRemoveGroup = (id: number) => {
        setGroupLoad(true);

        api_tenant
            .delete(`/api/persons/groups/${id}/members/`, {
                data: {
                    person: personGroupsEntity.id,
                },
            })
            .then(async () => {
                await fetchPersonGroups(personGroupsEntity.id);
            })
            .catch((e) => {
                enqueueSnackbar('Erro ao remover usuário do grupo!', { variant: 'error' });
            })
            .finally(() => {
                setGroupLoad(false);
            });
    };

    if (groupList === null) {
        return (
            <>
                {Array.from({ length: 3 }).map((_, index) => (
                    <TableRow
                        key={`skeleton-row-${index}`}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                        }}
                    >
                        {Array.from({ length: 2 }).map((_, index) => (
                            <TableCell key={`skeleton-row-${index}-cell-${index}`} align={'center'}>
                                <Skeleton />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </>
        );
    }

    return (
        <>
            {groupList.map((row) => (
                <TableRow
                    key={row.name}
                    sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                    }}
                >
                    <TableCell>{row.name}</TableCell>
                    <TableCell align={'right'}>
                        <IconButton onClick={() => handleRemoveGroup(row.id)}>
                            <RemoveCircleOutline
                                sx={{
                                    color: 'red',
                                }}
                            />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

export default ListTableContent;
