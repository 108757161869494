import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import WarningRounded from '@mui/icons-material/WarningRounded';

import ListTableContent from './ListTableContent';
import { GroupListTableProps } from './GroupListTable.d';

const GroupListTable = (props: GroupListTableProps) => {
    const { personGroupsEntity, fetchPersonGroups, groupList, setGroupLoad } = props;

    if (groupList && groupList.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 1,
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <WarningRounded
                    sx={{
                        margin: 1,
                        color: 'grey',
                    }}
                />
                <Typography variant={'subtitle1'}>
                    <b>Usuário sem grupos vinculados!</b>
                </Typography>
                <Typography
                    variant={'subtitle2'}
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    Os grupos em que o usuário é membro
                    <br />
                    aparecerão aqui.
                </Typography>
            </Box>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <ListTableContent
                        personGroupsEntity={personGroupsEntity}
                        fetchPersonGroups={fetchPersonGroups}
                        groupList={groupList}
                        setGroupLoad={setGroupLoad}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default GroupListTable;
