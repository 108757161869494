import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import PublicRoutes from './routes/publicRoutes';
import PrivateRoutes from './routes/privateRoutes';

import { isAuthenticated } from './services/auth';

const MainComponent = () => {
    if (!isAuthenticated()) {
        return <PublicRoutes />;
    }

    return <PrivateRoutes />;
};

export default function App() {
    return (
        <Router>
            <MainComponent />
        </Router>
    );
}
