import React, { Dispatch, SetStateAction } from 'react';

import ApiSelect from 'src/components/ApiSelect/ApiSelect';

import { Achievement } from 'src/pages/registry/achievements';

const GroupsField = (
    values: Achievement,
    setValues: Dispatch<SetStateAction<Achievement>>,
    errors: any,
    readOnly: boolean
) => {
    if (!(values['only_groups'] ?? false)) {
        values['groups'] = [];
        return;
    }

    return (
        <ApiSelect
            key={'groups-selector'}
            label={'Grupos de Motoristas'}
            endpoint={'/api/persons/groups/'}
            value={values['groups']}
            readOnly={readOnly}
            onChange={(e, val) => {
                setValues({
                    ...values,
                    groups: val,
                });
            }}
            multiple={true}
            getOptionLabel={(option) => (option?.name ? option?.name ?? '' : '')}
            error={errors?.hasOwnProperty('groups') ?? false}
            helperText={errors ? errors['groups'] ?? '' : ''}
        />
    );
};

export default GroupsField;
