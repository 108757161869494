import React, { SyntheticEvent, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import KanbanIcon from '@mui/icons-material/ViewKanban';

import ApiSelectField from 'src/components/ApiSelect';

import { Board, Card } from 'src/pages/kanban/Kanban.d';
import CardModal from 'src/pages/kanban/CardModal';

const CardFab = () => {
    /**
     * FILTER MODAL
     */
    const [open, setOpen] = useState(false);
    const [board, setBoard] = useState<Board | ''>('');

    /**
     * KANBAN CARD MODAL
     */
    const [cardModalOpen, setCardModalOpen] = useState(false);
    const [card, setCard] = useState<Card | ''>('');

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            {/** KANBAN BUTTON */}
            <Tooltip title={'Gestão de Tarefas'} placement={'left'}>
                <Fab
                    color={'primary'}
                    sx={{
                        position: 'fixed',
                        bottom: 30,
                        right: 30,
                    }}
                    onClick={() => setOpen(true)}
                    children={<KanbanIcon />}
                />
            </Tooltip>

            {/** CARD SELECTOR */}
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle children={'Selecionar Cartão'} />
                <DialogContent>
                    <Stack
                        spacing={3}
                        sx={{
                            pt: 1,
                        }}
                    >
                        <ApiSelectField
                            label={'Quadro'}
                            endpoint={'/api/kanban/boards/'}
                            value={board}
                            onChange={(e: SyntheticEvent, val: Board) => setBoard(val)}
                            getOptionLabel={(op: Board) => op?.name ?? ''}
                        />

                        <Divider />

                        <ApiSelectField
                            label={'Cartão'}
                            endpoint={'/api/kanban/cards/'}
                            value={card}
                            onChange={(e: SyntheticEvent, val: Card) => setCard(val)}
                            getOptionLabel={(op: Card) =>
                                op ? `Cartão ${String(op.id).padStart(4, '0')} - ${op.name}` : ''
                            }
                            queryParams={Object.fromEntries(
                                Object.entries({
                                    column__board__id: board ? board.id : null,
                                }).filter(([_, v]) => v !== null)
                            )}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        sx={{
                            px: 2,
                            pb: 2,
                        }}
                    >
                        <Button onClick={() => setOpen(false)} children={'Cancelar'} />
                        <Button
                            variant={'contained'}
                            disabled={!card}
                            onClick={() => {
                                setOpen(false);
                                setCardModalOpen(true);
                            }}
                            children={'Abrir Cartão'}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>

            {/** CARD MODAL */}
            {card && card.board && (
                <CardModal
                    open={cardModalOpen}
                    setOpen={setCardModalOpen}
                    boardType={card.board.type}
                    boardId={card.board.id}
                    cardSummary={card}
                />
            )}
        </>
    );
};

export default CardFab;
