import React, { useEffect, useState } from 'react';

import Preloader from 'src/components/Preloader';
import { RouteWithLoaderProps } from './RouteWithLoader.d';

const RouteWithLoader = (props: RouteWithLoaderProps) => {
    const { component: Component, ...rest } = props;

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Preloader show={!loaded} />
            <Component {...rest} />
        </>
    );
};

export default RouteWithLoader;
