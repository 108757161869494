import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';

const FuelTypesPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} pt={4}>
                <Box mb={1}>
                    <Breadcrumb items={['Configurações', 'Combustíveis']} />
                </Box>
            </Stack>

            <CrudAPI
                // CRUD INFO
                name={{
                    singular: 'Combustível',
                    plural: 'Combustíveis',
                }}
                route={AppRoutes.Events}
                endpoint={'/api/fuels/fuel-types/'}
                columns={[
                    {
                        accessorKey: 'description',
                        header: 'Descrição',
                    },
                    {
                        accessorKey: 'integration_id',
                        header: 'ID de Integração',
                        field: {
                            type: 'number',
                            step: 1,
                        },
                        filterLookup: 'exact',
                    },
                ]}
            />
        </>
    );
};

export default FuelTypesPage;
