/**
 * STORE USER INFORMATION ON sessionStorage
 */

import useTenantAPI from 'src/services/api_tenant';
import { User } from './user.d';

/**
 * Identifier for the user data on sessionStorage
 * @type {string}
 */
export const USER_KEY = '@ecotrack-user';

/**
 * Set user data in sessionStorage
 * @param {object} user - The user data to be stored in sessionStorage
 */
export const setUserData = (user: User) => {
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));
};

/**
 * Fetch user data from the API
 */
export const fetchUserData = async () => {
    const api_tenant = useTenantAPI();
    return await api_tenant
        .get('/api/users/my/')
        .then((res) => {
            if (res?.data) {
                setUserData(res.data);
            }
            return res?.data;
        })
        .catch((err) => {
            removeUserData();
        });
};

/**
 * Get user data from sessionStorage
 */
export const getUserData = async () => {
    const userData = sessionStorage.getItem(USER_KEY);

    // If no user data is found, call the api
    if (userData == null) {
        return await fetchUserData();
    }

    return JSON.parse(userData);
};

/**
 * Remove user data from sessionStorage
 */
export const removeUserData = () => {
    sessionStorage.removeItem(USER_KEY);
};

/**
 * LOGOUT USER
 */
export const logout = (reload = true) => {
    localStorage.clear();
    sessionStorage.clear();
    if (reload) {
        window.location.replace('/login');
    }
};
