import React from 'react';

import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CalendarIcon from '@mui/icons-material/CalendarMonth';
import ClockIcon from '@mui/icons-material/AccessTime';

import { DateFilters, DateFilterSectionProps } from './DateFilterSection.d';
import { DateFilterOption } from 'src/pages/kanban/CardFiltersMenu';

const DATE_FILTERS = {
    not_date: {
        label: 'Sem datas',
        icon: CalendarIcon,
        color: 'dimgray',
        bgcolor: 'gainsboro',
    },
    overdue: {
        label: 'Em Atraso',
        icon: ClockIcon,
        color: 'white',
        bgcolor: 'red',
    },
    due_today: {
        label: 'Vencimento hoje',
        icon: ClockIcon,
        color: 'white',
        bgcolor: 'orange',
    },
    due_tomorrow: {
        label: 'Vencimento amanhã',
        icon: ClockIcon,
        color: 'dimgray',
        bgcolor: 'gainsboro',
    },
    due_in_week: {
        label: 'Vencimento em uma semana',
        icon: ClockIcon,
        color: 'dimgray',
        bgcolor: 'gainsboro',
    },
    due_in_month: {
        label: 'Vencimento em um mês',
        icon: ClockIcon,
        color: 'dimgray',
        bgcolor: 'gainsboro',
    },
} as DateFilters;

const DateFilterSection = (props: DateFilterSectionProps) => {
    const { menuFilters, setMenuFilters } = props;

    return (
        <>
            <Typography variant={'caption'} children={'Datas'} />
            <FormGroup
                sx={{
                    mt: '0 !important',
                }}
            >
                {Object.entries(DATE_FILTERS).map(([key, value]) => {
                    const Icon = value.icon;
                    return (
                        <FormControlLabel
                            key={key}
                            label={
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Avatar sx={{ width: 24, height: 24, bgcolor: value.bgcolor, color: value.color }}>
                                        <Icon sx={{ fontSize: 16 }} />
                                    </Avatar>
                                    <Typography children={value.label} />
                                </Stack>
                            }
                            control={
                                <Checkbox
                                    checked={!!menuFilters.closest_date?.includes(key as DateFilterOption)}
                                    onChange={(e) => {
                                        const newFilters = { ...menuFilters };
                                        if (e.target.checked) {
                                            newFilters.closest_date = [
                                                ...(newFilters.closest_date || []),
                                                key as DateFilterOption,
                                            ];
                                        } else {
                                            newFilters.closest_date = (newFilters.closest_date || []).filter(
                                                (item) => item !== key
                                            );
                                        }
                                        setMenuFilters(newFilters);
                                    }}
                                />
                            }
                        />
                    );
                })}
            </FormGroup>
        </>
    );
};

export default DateFilterSection;
