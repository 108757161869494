import React from 'react';
import styled from '@mui/material/styles/styled';

import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';
import Sidebar from 'src/components/sidebar/Sidebar';

import { RouteWithSidebarProps } from './RouteWithSidebar.d';

const Main = styled('main')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        marginLeft: '260px',
    },
    overflow: 'visible',
    padding: '0px 15px 0px 15px',
    margin: '0px',
}));

const RouteWithSidebar = (props: RouteWithSidebarProps) => {
    const { component: Component, fullFrame = false, ...rest } = props;

    return (
        <>
            <Sidebar />

            <Main
                sx={{
                    p: fullFrame ? 0 : '',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                }}
            >
                {!fullFrame && <Navbar />}
                <Component {...rest} />
                {!fullFrame && <Footer />}
            </Main>
        </>
    );
};

export default RouteWithSidebar;
