import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import WarningRounded from '@mui/icons-material/WarningRounded';

import ListTableContent from 'src/components/crud/fields/CrudMultiSelect/ListTableContent';

import { TData } from 'src/components/crud/Crud.d';
import { ListTableProps } from 'src/components/crud/fields/CrudMultiSelect/ListTable';

const ListTable = <T extends TData>(props: ListTableProps<T>) => {
    const {
        // CONTROL
        items,
        setItems,
        load,
        labelKey,
        readOnly,

        // STRUCTURE
        header,

        // HANDLERS
        updateItems,
    } = props;

    if (items && items.length === 0) {
        return (
            <Box
                sx={{
                    color: 'grey',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <WarningRounded />
                <Typography variant={'subtitle1'} fontWeight={'bold'}>
                    Nenhum {header} Encontrado.
                </Typography>
            </Box>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <ListTableContent
                        // CONTROL
                        items={items}
                        load={load}
                        labelKey={labelKey}
                        readOnly={readOnly}
                        // HANDLERS
                        updateItems={updateItems}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ListTable;
