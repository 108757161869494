import React, { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import LogoutIcon from '@mui/icons-material/LogoutRounded';
import PersonIcon from '@mui/icons-material/PersonRounded';

import AppRoutes from 'src/routes/appRoutes';

import ProfileImage from 'src/assets/img/user/avatar.png';
import { logout } from 'src/services/storage/user';

function Navbar() {
    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = () => {
        handleCloseUserMenu();
        logout();
    };

    const handleProfileClick = () => {
        handleCloseUserMenu();
        navigate(AppRoutes.UserSettings.path);
    };

    return (
        <AppBar position={'static'} elevation={0} color={'transparent'} sx={{ pt: 1 }}>
            <Container maxWidth={'xl'}>
                <Toolbar disableGutters sx={{ justifyContent: 'end' }}>
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar src={ProfileImage} />
                        </IconButton>
                        <Menu
                            id={'menu-appbar'}
                            keepMounted
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            sx={{
                                mt: '45px',
                            }}
                        >
                            <MenuItem onClick={handleProfileClick} sx={{ py: 1 }}>
                                <PersonIcon fontSize={'small'} sx={{ mr: 1 }} color={'primary'} />
                                <Typography sx={{ mr: 3 }} children={'Meu Perfil'} />
                            </MenuItem>
                            <MenuItem onClick={handleLogoutClick} sx={{ py: 1 }}>
                                <LogoutIcon fontSize={'small'} sx={{ mr: 1 }} color={'error'} />
                                <Typography sx={{ mr: 3 }} children={'Sair'} />
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;
