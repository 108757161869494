import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';

export const endpoint = '/api/users/';

const UsersPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Acessos', 'Usuários']} />
                </Box>
            </Stack>

            <CrudAPI
                name={{
                    singular: 'Usuário',
                    plural: 'Usuários',
                }}
                endpoint={endpoint}
                route={AppRoutes.Users}
                columns={[
                    {
                        accessorKey: 'username',
                        header: 'Usuário',
                    },
                    {
                        accessorKey: 'password',
                        header: 'Senha',
                        hideColumn: true,
                        field: {
                            type: 'password',
                        },
                    },
                    {
                        accessorKey: 'password_confirm',
                        header: 'Confirmação de Senha',
                        hideColumn: true,
                        field: {
                            type: 'password',
                        },
                    },
                    {
                        accessorKey: 'is_active',
                        header: 'Usuário Ativo',
                        Cell: ({ row }) => (row.original.is_active ? 'Ativo' : 'Inativo'),
                        field: {
                            type: 'check',
                            default: true,
                        },
                    },
                    {
                        accessorKey: 'is_driver',
                        header: 'Acesso App',
                        hideColumn: true,
                        field: {
                            type: 'check',
                        },
                    },
                ]}
            />
        </>
    );
};

export default UsersPage;
