import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PermissionsIcon from '@mui/icons-material/LockPerson';

import AppRoutes from 'src/routes/appRoutes';
import { ACTIONS, hasRoutePermission } from 'src/services/storage/permissions';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import PermissionsDialog from './PermissionsDialog/PermissionsDialog';

import { Dashboard } from 'src/pages/dashboards/Dashboard.d';

const DashboardProfilesPage = () => {
    /**
     * PERMISSIONS MODAL
     */
    const [permissionsOpen, setPermissionsOpen] = useState(false);
    const [permissionsModalEntity, setPermissionsModalEntity] = useState<Dashboard | null>(null);

    const handlePermissionsOpen = (entity: Dashboard) => {
        setPermissionsModalEntity(entity);
        setPermissionsOpen(true);
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Dashboards', 'Perfis de Dashboard']} />
                </Box>
            </Stack>

            <CrudAPI
                // INFO
                name={{
                    singular: 'Perfil de Dashboard',
                    plural: 'Perfis de Dashboard',
                }}
                route={AppRoutes.DashboardProfiles}
                endpoint={'/api/dashboards/profiles/'}
                // ACTIONS
                extraRowActions={[
                    {
                        onClick: handlePermissionsOpen,
                        tooltip: 'Permissões',
                        icon: PermissionsIcon,
                        color: 'primary',
                        show: hasRoutePermission(AppRoutes.DashboardProfiles, ACTIONS.UPDATE),
                    },
                ]}
                // STRUCTURE
                columns={[
                    {
                        accessorKey: 'name',
                        header: 'Nome',
                    },
                ]}
            />

            {permissionsModalEntity && (
                <PermissionsDialog
                    open={permissionsOpen}
                    setOpen={setPermissionsOpen}
                    modalEntity={permissionsModalEntity}
                />
            )}
        </>
    );
};

export default DashboardProfilesPage;
