import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import RegistryIcon from '@mui/icons-material/ListAlt';

import AppRoutes from 'src/routes/appRoutes';
import { getCachedModulesData } from 'src/services/storage/modules';

import Breadcrumb from 'src/components/Breadcrumb';
import SettingsSection from 'src/pages/settings/SettingsSection';

const Settings = () => {
    const enabledModules = getCachedModulesData();
    const isModuleDisabled = (enableKey: string) => {
        return !enabledModules[enableKey];
    };

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} pt={4}>
                <Box mb={1}>
                    <Breadcrumb items={['Configurações']} />
                </Box>
            </Stack>

            <Divider
                sx={{
                    mb: 5,
                    bgcolor: 'secondary.light',
                }}
            />

            <Stack alignItems={'center'}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        width: {
                            xs: '100%',
                            md: '80%',
                            xl: '60%',
                        },
                    }}
                >
                    <SettingsSection
                        endpoint={'api/metrics/settings/'}
                        title={'Performances'}
                        subtitle={"Configurações do módulo 'Performance'."}
                        columns={[
                            {
                                accessorKey: 'min_distance_monthly_ranking',
                                header: 'Distância Mínima Ranking Mensal',
                                disabledModule: isModuleDisabled('enable_module_ranking'),
                                field: {
                                    type: 'number',
                                    step: 50,
                                },
                            },
                            {
                                accessorKey: 'enable_section_rpm_ranges',
                                header: 'Faixas RPM Habilitadas no APP',
                                disabledModule: isModuleDisabled('enable_module_rpm_ranges'),
                                field: {
                                    type: 'check',
                                },
                            },
                            {
                                accessorKey: 'enable_section_ranking',
                                header: 'Ranking Habilitado no APP',
                                disabledModule: isModuleDisabled('enable_module_ranking'),
                                field: {
                                    type: 'check',
                                },
                            },
                            {
                                accessorKey: 'default_goal',
                                header: 'Meta Padrão',
                                field: {
                                    type: 'number',
                                    step: 0.1,
                                },
                            },
                        ]}
                    />
                    <SettingsSection
                        endpoint={'api/rewards/settings/'}
                        title={'Conquistas'}
                        subtitle={"Configurações do módulo 'Conquistas'."}
                        columns={[
                            {
                                accessorKey: 'min_distance_work_day',
                                header: 'Distância Mínima Dia Trabalhado',
                                field: {
                                    type: 'number',
                                    step: 5,
                                },
                            },
                        ]}
                    />
                    <SettingsSection
                        endpoint={'api/telemetry/settings/'}
                        title={'Eventos'}
                        subtitle={"Configurações do módulo 'Eventos'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Eventos Habilitados no APP',
                                disabledModule: isModuleDisabled('enable_module_telemetry'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                        extraFields={
                            <>
                                <Button
                                    variant={'outlined'}
                                    component={Link}
                                    to={AppRoutes.Events.path}
                                    disabled={isModuleDisabled('enable_module_telemetry')}
                                >
                                    <RegistryIcon sx={{ mr: 1 }} /> Cadastrar eventos de telemetria
                                </Button>
                            </>
                        }
                    />
                    <SettingsSection
                        endpoint={'api/fuels/settings/'}
                        title={'Abastecimentos'}
                        subtitle={"Configurações do módulo 'Abastecimentos'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Abastecimentos Habilitados no APP',
                                disabledModule: isModuleDisabled('enable_module_fuels'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                        extraFields={
                            <Button
                                variant={'outlined'}
                                component={Link}
                                to={AppRoutes.FuelTypes.path}
                                disabled={isModuleDisabled('enable_module_fuels')}
                                startIcon={<RegistryIcon />}
                                children={'Cadastrar combustível'}
                            />
                        }
                    />
                    <SettingsSection
                        endpoint={'api/settlements/settings/'}
                        title={'Acertos'}
                        subtitle={"Configurações do módulo 'Acertos'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Acertos Habilitados no APP',
                                disabledModule: isModuleDisabled('enable_module_settlements'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                        extraFields={
                            <>
                                <Button
                                    variant={'outlined'}
                                    component={Link}
                                    to={AppRoutes.Settlements.path}
                                    disabled={isModuleDisabled('enable_module_settlements')}
                                >
                                    <RegistryIcon sx={{ mr: 1 }} /> Cadastrar eventos de acerto
                                </Button>
                            </>
                        }
                    />
                    <SettingsSection
                        endpoint={'api/advances/settings/'}
                        title={'Adiantamentos'}
                        subtitle={"Configurações do módulo 'Adiantamentos'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Adiantamentos Habilitados no APP',
                                disabledModule: isModuleDisabled('enable_module_advances'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                        extraFields={
                            <>
                                <Button
                                    variant={'outlined'}
                                    component={Link}
                                    to={AppRoutes.AdvanceEvents.path}
                                    disabled={isModuleDisabled('enable_module_advances')}
                                >
                                    <RegistryIcon sx={{ mr: 1 }} /> Cadastrar eventos de adiantamento
                                </Button>
                            </>
                        }
                    />
                    <SettingsSection
                        endpoint={'api/contacts/settings/'}
                        title={'Contatos'}
                        subtitle={"Configurações do módulo 'Contatos'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Contatos Habilitados no APP',
                                disabledModule: isModuleDisabled('enable_module_contacts'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                        extraFields={
                            <>
                                <Button
                                    variant={'outlined'}
                                    component={Link}
                                    to={AppRoutes.Contacts.path}
                                    disabled={isModuleDisabled('enable_module_contacts')}
                                >
                                    <RegistryIcon sx={{ mr: 1 }} /> Cadastrar contatos
                                </Button>
                            </>
                        }
                    />
                    <SettingsSection
                        endpoint={'api/checklists/settings/'}
                        title={'Checklists'}
                        subtitle={"Configurações do módulo 'Checklists'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Checklists Habilitados no APP',
                                disabledModule: isModuleDisabled('enable_module_checklists'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                    />
                    <SettingsSection
                        endpoint={'api/marketplace/settings/'}
                        title={'Marketplace'}
                        subtitle={"Configurações do módulo 'Marketplace'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Marketplace Habilitado no APP',
                                disabledModule: isModuleDisabled('enable_module_marketplace'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                    />
                    <SettingsSection
                        endpoint={'api/vehicles/settings/'}
                        title={'Veículos'}
                        subtitle={"Configurações do módulo 'Veículos'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Veículos Habilitados no APP',
                                disabledModule: isModuleDisabled('enable_module_vehicles'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                    />
                    <SettingsSection
                        endpoint={'api/journeys/settings/'}
                        title={'Viagens'}
                        subtitle={"Configurações do módulo 'Viagens'."}
                        columns={[
                            {
                                accessorKey: 'enable_section',
                                header: 'Viagens Habilitadas no APP',
                                disabledModule: isModuleDisabled('enable_module_journeys'),
                                field: {
                                    type: 'check',
                                },
                            },
                        ]}
                    />
                </Grid>
            </Stack>
        </>
    );
};

export default Settings;
