import React from 'react';

import CustomerCardCreateModal from './CustomerCardCreateModal';
import DriverCardCreateModal from './DriverCardCreateModal';

import { CardCreateModalProps } from './CardCreateModal.d';

const CardCreateModal = (props: CardCreateModalProps) => {
    const { boardType } = props;

    /**
     * RENDER COMPONENT
     */
    if (boardType === 'driver') {
        return <DriverCardCreateModal {...props} />;
    }

    if (boardType === 'customer') {
        return <CustomerCardCreateModal {...props} />;
    }

    return <></>;
};

export default CardCreateModal;
