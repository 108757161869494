import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PermissionsIcon from '@mui/icons-material/LockPerson';

import AppRoutes from 'src/routes/appRoutes';
import { ACTIONS, hasRoutePermission } from 'src/services/storage/permissions';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import PermissionsDialog from './PermissionsDialog';

import { RowAction } from 'src/components/crud/Crud.d';
import { Profile } from './Profiles.d';

const ProfilesPage = () => {
    // PERMISSIONS MODAL
    const [permissionsOpen, setPermissionsOpen] = useState(false);
    const [permissionsModalEntity, setPermissionsModalEntity] = useState<Profile | null>(null);

    const handlePermissionsOpen = (entity: Profile) => {
        setPermissionsModalEntity(entity);
        setPermissionsOpen(true);
    };

    // ROW ACTIONS
    const permissionsAction: RowAction<Profile> = {
        onClick: handlePermissionsOpen,
        tooltip: 'Permissões',
        icon: PermissionsIcon,
        color: 'primary',
    };
    const rowActions: RowAction<Profile>[] = hasRoutePermission(AppRoutes.PermissionProfiles, ACTIONS.UPDATE)
        ? [permissionsAction]
        : [];

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Acessos', 'Perfis']} />
                </Box>
            </Stack>

            <CrudAPI
                // INFO
                name={{
                    singular: 'Perfil',
                    plural: 'Perfis',
                }}
                route={AppRoutes.PermissionProfiles}
                endpoint={'/api/permissions/profiles/'}
                // ACTIONS
                extraRowActions={rowActions}
                // STRUCTURE
                columns={[
                    {
                        accessorKey: 'name',
                        header: 'Nome',
                    },
                ]}
            />

            {permissionsModalEntity && (
                <PermissionsDialog
                    open={permissionsOpen}
                    setOpen={setPermissionsOpen}
                    modalEntity={permissionsModalEntity}
                />
            )}
        </>
    );
};

export default ProfilesPage;
