import React from 'react';

import { canAccessRoute } from 'src/services/storage/permissions';
import Forbidden from 'src/pages/errors/Forbidden';

import { RouteWithPermissionsProps } from 'src/routes/components/RouteWithPermissions';
import { isModuleEnabled } from 'src/services/storage/modules';

const RouteWithPermissions = (props: RouteWithPermissionsProps) => {
    const { element, route, superuser = false, permissions = [] } = props;

    // TODO: Cache module information
    if ((route.enable_key && !isModuleEnabled(route.enable_key)) || !canAccessRoute(permissions, superuser, route)) {
        return <Forbidden />;
    }

    return element;
};

export default RouteWithPermissions;
