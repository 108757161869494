import React, { Dispatch, SetStateAction } from 'react';

import CrudText from 'src/components/crud/fields/CrudText';

import { Achievement } from 'src/pages/registry/achievements';

const GoalField = (
    values: Achievement,
    setValues: Dispatch<SetStateAction<Achievement>>,
    errors: any,
    readOnly: boolean
) => {
    if (!values['metric'] || values['metric'].toString() === '3') {
        values['goal'] = 0;
        return;
    }

    return (
        <CrudText
            key={'goal-field'}
            column={{
                accessorKey: 'goal',
                header: 'Meta',
                field: {
                    type: 'number',
                    step: 0.1,
                },
            }}
            values={values}
            setValues={setValues}
            errors={errors}
            readOnly={readOnly}
        />
    );
};

export default GoalField;
