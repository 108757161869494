import React, { useEffect, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import PermissionsIcon from '@mui/icons-material/VpnKey';
import OpenIcon from '@mui/icons-material/ArrowDropDown';
import AccessIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/BarChart';
import KanbanIcon from '@mui/icons-material/ViewKanban';

import AppRoutes from 'src/routes/appRoutes';
import useTenantAPI from 'src/services/api_tenant';
import { ACTIONS, hasRoutePermission } from 'src/services/storage/permissions';

import CrudModal from 'src/components/crud/modals/CrudModal';

import { User } from 'src/pages/registry/persons/Persons';

import { UserModalProps } from './UserModal.d';
import AccessPermissionsModal from './AccessPermissionsModal';
import DashboardsPermissionsModal from './DashboardsPermissionsModal';
import KanbanPermissionsModal from './KanbanPermissionsModal';

const UserModal = (props: UserModalProps) => {
    const { open, setOpen, modalPerson, setUser, refetch } = props;

    const { enqueueSnackbar } = useSnackbar();
    const api_tenant = useTenantAPI();

    const handleClose = () => {
        setModalUser(null);
        setOpen(false);
    };

    /**
     * USER ENTITY
     */
    const [modalUser, setModalUser] = useState<User | null>(null);

    useEffect(() => {
        if (modalPerson) {
            setModalUser(modalPerson.user);
        }
    }, [modalPerson]);

    const createDefaultUsername = () => {
        let first_name = modalPerson?.first_name;
        let last_name = modalPerson?.last_name;

        if (!first_name || !last_name) {
            return;
        }

        return [first_name, last_name]
            .map((n) => n?.split(' ')[0])
            .join('.')
            .toLowerCase();
    };

    /**
     * SUBMIT CONTROL
     */
    const [errors, setErrors] = useState<Record<string, any>>({});

    const handleSubmitSuccess = (res: any) => {
        enqueueSnackbar('Usuário cadastrado com sucesso!', { variant: 'success' });
        setOpen(false);
        setErrors({});
        setModalUser(null);
        setUser(res.data);
        refetch();
    };

    const handleSubmitError = (error: AxiosError<any>) => {
        let detail = null;
        let fieldErrors: Record<string, any> = {};
        let errors = error?.response?.data as Record<string, string[]>;

        if (error?.response?.data.hasOwnProperty('detail')) {
            detail = errors.detail;
            delete errors.detail;
        }

        for (let [key, val] of Object.entries(errors)) {
            fieldErrors[key] = val.join(',');
        }

        setErrors(fieldErrors);

        if (detail) {
            enqueueSnackbar(detail, { variant: 'error' });
        }
    };

    const handleSubmit = async (formData: FormData, entity_id: number, values: Record<string, any>) => {
        const data: Record<string, any> = {
            username: values.username,
            tenant_user: {
                is_active: values.tenant_user.is_active,
                is_driver: values.tenant_user.is_driver,
            },
        };

        if (values.password) {
            data['password'] = values.password;
        }

        if (values.password_confirm) {
            data['password_confirm'] = values.password_confirm;
        }

        new Promise(async (resolve, reject) => {
            if (entity_id) {
                // On edit
                return await api_tenant.patch(`/api/users/${entity_id}/`, data).then(resolve).catch(reject);
            } else {
                // On create
                return await api_tenant.post(`/api/users/`, data).then(resolve).catch(reject);
            }
        })
            .then(handleSubmitSuccess)
            .catch(handleSubmitError)
            .catch((e) => {
                enqueueSnackbar('Erro ao cadastrar!', { variant: 'error' });
            });
    };

    /**
     * PERMISSIONS MODALS
     */
    const menuAnchorEl = useRef<HTMLButtonElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const [accessPermissionsOpen, setAccessPermissionsOpen] = useState(false);
    const [dashboardPermissionsOpen, setDashboardPermissionsOpen] = useState(false);
    const [kanbanPermissionsOpen, setKanbanPermissionsOpen] = useState(false);

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <CrudModal
                // States
                open={open}
                entity={modalUser}
                errors={errors}
                readOnly={!hasRoutePermission(AppRoutes.Users, modalUser?.id ? ACTIONS.UPDATE : ACTIONS.CREATE)}
                // Structure
                columns={[
                    {
                        accessorKey: 'username',
                        header: 'Usuário',
                        enableEditing: true,
                        field: {
                            type: 'text',
                            default: createDefaultUsername(),
                        },
                    },
                    {
                        accessorKey: 'password',
                        header: 'Senha',
                        enableEditing: true,
                        field: {
                            type: 'password',
                        },
                    },
                    {
                        accessorKey: 'password_confirm',
                        header: 'Confirmação de Senha',
                        enableEditing: true,
                        field: {
                            type: 'password',
                        },
                    },
                    {
                        accessorKey: 'tenant_user.is_active',
                        header: 'Usuário Ativo',
                        enableEditing: true,
                        field: {
                            type: 'check',
                            default: true,
                        },
                    },
                    {
                        accessorKey: 'tenant_user.is_driver',
                        header: 'Acesso App',
                        enableEditing: true,
                        field: {
                            type: 'check',
                            default: false,
                        },
                    },
                ]}
                // Texts
                createTitle={'Criar Usuário'}
                updateTitle={'Editar Usuário'}
                // Events
                onSubmit={handleSubmit}
                onCancel={handleClose}
                // Actions
                extraActions={
                    <Button
                        ref={menuAnchorEl}
                        variant={'outlined'}
                        startIcon={<PermissionsIcon />}
                        endIcon={<OpenIcon />}
                        disabled={!modalUser?.id}
                        onClick={() => setMenuOpen(true)}
                        children={'Permissões'}
                    />
                }
            />

            {modalUser && (
                <>
                    <AccessPermissionsModal
                        user={modalUser}
                        open={accessPermissionsOpen}
                        setOpen={setAccessPermissionsOpen}
                    />
                    <DashboardsPermissionsModal
                        user={modalUser}
                        open={dashboardPermissionsOpen}
                        setOpen={setDashboardPermissionsOpen}
                    />
                    <KanbanPermissionsModal
                        user={modalUser}
                        open={kanbanPermissionsOpen}
                        setOpen={setKanbanPermissionsOpen}
                    />
                </>
            )}

            <Menu
                anchorEl={menuAnchorEl.current}
                open={menuOpen}
                onClose={handleMenuClose}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 200,
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        setAccessPermissionsOpen(true);
                        handleMenuClose();
                    }}
                >
                    <ListItemIcon children={<AccessIcon fontSize={'small'} />} />
                    <ListItemText primary={'Acesso'} />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setDashboardPermissionsOpen(true);
                        handleMenuClose();
                    }}
                >
                    <ListItemIcon children={<DashboardIcon fontSize={'small'} />} />
                    <ListItemText primary={'Dashboards'} />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setKanbanPermissionsOpen(true);
                        handleMenuClose();
                    }}
                >
                    <ListItemIcon children={<KanbanIcon fontSize={'small'} />} />
                    <ListItemText primary={'Kanban'} />
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserModal;
