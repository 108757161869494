import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppRoutes from 'src/routes/appRoutes';
import privatePages from './privatePages';

// errors
import NotFound from 'src/pages/errors/NotFound';
import Blank from 'src/pages/Blank';

// pages
import Settings from 'src/pages/user/Settings';

// services
import { getCachedSuperUser, getPermissions } from 'src/services/storage/permissions';
import { getUserData, User } from 'src/services/storage/user';
import { getCachedCompanyData, getCompanyData } from 'src/services/storage/company';
import { getModulesData } from 'src/services/storage/modules';

import RouteWithSidebar from 'src/routes/components/RouteWithSidebar/RouteWithSidebar';
import Preloader from 'src/components/Preloader';
import RouteOfSystem from './components/RouteOfSystem';

export const UserContext = createContext<User | null>(null);

const PrivateAppRoutes = () => {
    const [loading, setLoading] = useState(true);

    /**
     * LOAD PERMISSIONS
     */
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [superuser, setSuperuser] = useState(false);

    useEffect(() => {
        getCompanyData().then((company) => {
            document.title = `EcoTrack - ${getCachedCompanyData().name}`;
            getModulesData();
            getUserData()
                .then((user) => setUser(user))
                .then(() => {
                    getPermissions().then((permissions) => {
                        setPermissions(permissions);
                        setSuperuser(getCachedSuperUser());
                        setTimeout(() => setLoading(false), 1000);
                    });
                });
        });
    }, []);

    return (
        <>
            <Preloader show={loading} />

            <UserContext.Provider value={user}>
                <Routes>
                    {/* user */}
                    <Route path={AppRoutes.UserSettings.path} element={<RouteWithSidebar component={Settings} />} />

                    {privatePages.map((page, index) => {
                        return (
                            <Route
                                key={index}
                                path={page.route.path}
                                element={<RouteOfSystem page={page} superuser={superuser} permissions={permissions} />}
                            />
                        );
                    })}

                    {/* redirect */}
                    <Route path={'/'} element={<RouteWithSidebar component={Blank} />} />

                    {/* error pages */}
                    <Route path={'*'} element={<NotFound />} />
                </Routes>
            </UserContext.Provider>
        </>
    );
};

export default function PrivateRoutes() {
    return <PrivateAppRoutes />;
}
