import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { CardSectionProps } from './CardSection.d';

const CardSection = (props: CardSectionProps) => {
    const { icon: Icon, title, actions } = props;

    return (
        <Stack direction={'column'} spacing={1}>
            <Stack
                pb={1}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{
                    color: '#555',
                }}
            >
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Icon fontSize={'small'} />
                    <Typography variant={'body2'} fontWeight={500} children={title} />
                </Stack>

                {actions}
            </Stack>
            <Box pl={3.5} children={props.children} />
        </Stack>
    );
};

export default CardSection;
