import { Dispatch, ReactElement, SetStateAction } from 'react';

import { MRT_ColumnDef } from 'material-react-table';
import { CrudTextFieldProps } from './fields/CrudText';
import { CrudDatetimePickerFieldProps } from './fields/CrudDatetimePicker';
import { CrudApiSelectFieldProps } from './fields/CrudApiSelect';
import { CrudDatePickerFieldProps } from './fields/CrudDatePicker';
import { CrudHeaderFieldProps } from './fields/CrudHeader';
import { CrudSelectFieldProps } from './fields/CrudSelect';
import { CrudTransferSelectFieldProps } from './fields/CrudTransferSelect';
import { CrudMultiSelectFieldProps } from 'src/components/crud/fields/CrudMultiSelect';
import { CrudCheckBoxFieldProps } from './fields/CrudCheckBox';
import { CrudPasswordFieldProps } from './fields/CrudPassword';
import { CrudTableBtnProps } from './CrudTableBtn';

export interface Name {
    plural: string;
    singular: string;
}

export interface TData extends Record<string, any> {
    id: number;
}

export type FieldSize = 'small' | 'medium';

export type FieldRenderFn<T extends TData> = (
    values: T,
    setValues: Dispatch<SetStateAction<T>>,
    errors: Record<string, any>,
    readOnly: boolean
) => ReactElement;

export interface CrudCustomFieldProps<T extends TData> {
    type: 'custom';
    component: FieldRenderFn<T>;
}

export const FILTER_LOOKUP_EXPRS = [
    'exact',
    'iexact',
    'contains',
    'icontains',
    'startswith',
    'endswith',
    'gt',
    'gte',
    'lt',
    'lte',
] as const;

export type FilterLookup = (typeof FILTER_LOOKUP_EXPRS)[number];

export interface Column<T extends TData> extends Omit<MRT_ColumnDef<T>, keyof { accessorKey: unknown }> {
    accessorKey: string;
    enableEditing?: boolean;
    field?: (
        | CrudApiSelectFieldProps<T>
        | CrudCheckBoxFieldProps<T>
        | CrudCustomFieldProps<T>
        | CrudDatePickerFieldProps<T>
        | CrudDatetimePickerFieldProps<T>
        | CrudHeaderFieldProps<T>
        | CrudSelectFieldProps<T>
        | CrudTextFieldProps<T>
        | CrudPasswordFieldProps<T>
        | CrudTransferSelectFieldProps<T>
        | CrudMultiSelectFieldProps<T>
    ) & {
        component?: any;
        default?: any;
        type?:
            | 'text'
            | 'number'
            | 'password'
            | 'check'
            | 'select'
            | 'search'
            | 'transfer'
            | 'multi-select'
            | 'date'
            | 'datetime'
            | 'header'
            | 'custom';
    };
    filterLookup?: FilterLookup;
    readOnly?: boolean;
    hideColumn?: boolean;
    type?: 'check' | 'date' | 'datetime';
}

export type Columns<T extends TData> = Column<T>[];

export interface CrudFieldProps<T extends TData> {
    column: Column<T>;
    values: T;
    setValues: Dispatch<SetStateAction<T>>;
    errors?: Record<string, any>;
    readOnly?: boolean;
    required?: boolean;
    size?: FieldSize;
}

export interface RowAction<T extends TData> extends CrudTableBtnProps<T> {
    buildLink?: (p: any) => string;
    show?: boolean;
}
