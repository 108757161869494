import React from 'react';

import Stack from '@mui/material/Stack';

import { TimelineSectionProps } from './TlSection.d';
import TlContent from 'src/pages/kanban/CardModal/TlContent';
import TlRegisterField from 'src/pages/kanban/CardModal/TlRegisterField';

const TlSection = (props: TimelineSectionProps) => {
    const { boardId, cardId, timeline, refetchCard } = props;

    return (
        <Stack spacing={1}>
            <TlRegisterField
                boardId={boardId}
                cardId={cardId}
                onSuccess={() => {
                    refetchCard();
                }}
            />

            <TlContent
                endpoint={`api/kanban/boards/${boardId}/cards/${cardId}/timeline/`}
                columnsEndpoint={`api/kanban/boards/${boardId}/columns/`}
                timeline={timeline}
                boardId={boardId}
                cardId={cardId}
                refetchCard={refetchCard}
            />
        </Stack>
    );
};

export default TlSection;
