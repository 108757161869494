import React from 'react';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';

import { User } from 'src/pages/registry/persons/Persons';
import { Profile } from 'src/pages/access/profiles/permissions';
import { Columns } from 'src/components/crud/Crud.d';
import { endpoint as userEndpoint } from 'src/pages/access/Users';

import { KanbanPermissionsModalProps } from './KanbanPermissionsModal.d';

export const name = {
    singular: 'Permissões de Kanban',
    plural: 'Permissões de Kanban',
};

export const columns: Columns<User> = [
    {
        accessorKey: 'tenant_user.kanban_profiles',
        header: 'Perfis de Kanban',
        enableEditing: true,
        field: {
            type: 'search',
            name: 'tenant_user.kanban_profiles',
            endpoint: '/api/kanban/profiles/',
            labelKey: 'name',
            multiple: true,
            default: [],
        },
    },
    {
        accessorKey: 'tenant_user.user_kanban_boards',
        header: 'Permissões de Kanban do Usuário',
        enableEditing: true,
        field: {
            type: 'transfer',
            itemsEndpoint: (values) => `/api/kanban/users/${values.id}/boards/`,
            descriptionsEndpoint: '/api/kanban/board/descriptions/',
        },
    },
];

const KanbanPermissionsModal = (props: KanbanPermissionsModalProps) => {
    const { open, setOpen, user } = props;

    /**
     * SUBMIT CONTROL
     */
    const formatPayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
        return {
            tenant_user: {
                user_kanban_boards: values.tenant_user.user_kanban_boards,
                kanban_profiles: values.tenant_user.kanban_profiles.map((p: Profile) => p.id),
            },
        };
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <CreateUpdateModal
            // STRUCTURE
            name={name}
            endpoint={userEndpoint}
            columns={columns}
            // MODAL CONTROL
            open={open}
            setOpen={setOpen}
            entity={user}
            // ACTIONS
            formatPayload={formatPayload}
            // VISUAL
            size={'lg'}
        />
    );
};

export default KanbanPermissionsModal;
