import * as React from 'react';

import Button from '@mui/material/Button';

import { TransferButtonProps } from './TransferButton.d';

const TransferButton = (props: TransferButtonProps) => {
    const { onClick, disabled, icon } = props;

    return (
        <Button
            sx={{
                my: 0.5,
            }}
            variant={'outlined'}
            size={'small'}
            onClick={onClick}
            disabled={disabled}
        >
            {icon}
        </Button>
    );
};

export default TransferButton;
