import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { DragDropContext, OnDragEndResponder } from '@hello-pangea/dnd';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import useTenantAPI from 'src/services/api_tenant';
import CardColumn from 'src/pages/kanban/CardColumn';

import { Card, Column } from 'src/pages/kanban/Kanban.d';
import { CardListProps } from './CardBoard.d';

const updateCardStatusLocal = (
    srcCard: Card,
    src: { column: Column; index: number },
    dst: {
        column: Column;
        index?: number; // undefined if dropped after the last item
    },
    columns: Column[]
): Column[] => {
    if (src.column.id === dst.column.id) {
        // moving deal inside the same column
        const colCards = src.column.cards;

        colCards.splice(src.index, 1);
        colCards.splice(dst.index ?? colCards.length + 1, 0, srcCard);

        return columns.map((col) => {
            if (col.id === src.column.id) {
                return { ...col, cards: colCards };
            }
            return col;
        });
    } else {
        // moving deal across columns
        const srcCards = src.column.cards;
        const dstCards = dst.column.cards;

        srcCards.splice(src.index, 1);
        dstCards.splice(dst.index ?? dstCards.length + 1, 0, srcCard);

        return columns.map((col) => {
            if (col.id === src.column.id) {
                return { ...col, cards: srcCards };
            } else if (col.id === dst.column.id) {
                return { ...col, cards: dstCards };
            }
            return col;
        });
    }
};

const getColumnById = (columns: Column[], id: number): Column | null => {
    return columns.find((column) => column.id === id) ?? null;
};

const CardBoard = (props: CardListProps) => {
    const { boardId, cardColumns, refetch, onCardClick } = props;

    const { enqueueSnackbar } = useSnackbar();
    const api_tenant = useTenantAPI();

    /**
     * CARD CONTROL
     */
    const [columns, setColumns] = useState<Column[]>([]);

    useEffect(() => {
        if (cardColumns) {
            setColumns(cardColumns);
        }
    }, [cardColumns]);

    /**
     * CARD API SYNC
     */
    const { mutateAsync, isLoading: loadingUpdate } = useMutation<void, Error, { srcCard: Card; dstColumn: Column }>(
        ({ srcCard, dstColumn }) => updateCardStatusRemote(srcCard, dstColumn),
        { onSettled: () => refetch() }
    );

    const updateCardStatusRemote = (srcCard: Card, dstColumn: Column) => {
        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${srcCard.id}/move/`, {
                column: dstColumn.id,
            })
            .then(() => {})
            .catch((err: AxiosError<{ detail: string }>) => {
                setColumns(cardColumns);
                if (err.response?.data?.detail) {
                    enqueueSnackbar(err.response.data.detail, { variant: 'error' });
                    return;
                }
                enqueueSnackbar('Erro ao mover.', { variant: 'error' });
            });
    };

    const onDragEnd: OnDragEndResponder = (result) => {
        const { destination, source } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const srcColumn = getColumnById(columns, parseInt(source.droppableId));
        const dstColumn = getColumnById(columns, parseInt(destination.droppableId));

        if (!srcColumn || !dstColumn) {
            return;
        }

        const srcCard = srcColumn.cards[source.index];

        // compute local state change synchronously
        setColumns(
            updateCardStatusLocal(
                srcCard,
                { column: srcColumn, index: source.index },
                { column: dstColumn, index: destination.index },
                columns
            )
        );

        if (destination.droppableId === source.droppableId) {
            return;
        }

        mutateAsync({
            srcCard: srcCard,
            dstColumn: dstColumn,
        });
    };

    /**
     * RENDER COMPONENT
     */
    if (!columns.length) {
        return (
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: '60vh' }}>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display={'flex'} alignItems={'stretch'} flexGrow={1}>
                {columns.map((column) => (
                    <CardColumn key={column.id} column={column} onCardClick={onCardClick} />
                ))}
            </Box>
        </DragDropContext>
    );
};

export default CardBoard;
