import React, { SyntheticEvent, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import useTenantAPI from 'src/services/api_tenant';

import ApiSelectField from 'src/components/ApiSelect';
import CardAccordion from 'src/pages/kanban/CardModal/CardAccordion';

import { Vehicle } from 'src/pages/registry/vehicles';
import { DriverSectionInfoProps } from './DriverSectionInfo.d';

const DriverSectionInfo = (props: DriverSectionInfoProps) => {
    const { boardId, card: originalCard, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * VEHICLE FIELD CONTROL
     */
    const [vehicle, setVehicle] = useState<Vehicle>(originalCard.vehicle);

    const submitVehicleChange = () => {
        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${originalCard.id}/`, {
                vehicle: vehicle?.id ?? null,
            })
            .then((res) => res.data);
    };

    const { mutateAsync: mutateVehicle } = useMutation(submitVehicleChange, {
        onSuccess: () => refetchCard(),
        onError: () => enqueueSnackbar('Erro ao atualizar o veículo.', { variant: 'error' }),
    });

    /**
     * HIRING DATE FIELD CONTROL
     */
    const [hiringDate, setHiringDate] = useState<Date | null>(originalCard.hiring_date);

    const submitHiringDateChange = () => {
        return api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${originalCard.id}/`, {
                hiring_date: hiringDate ? dayjs(hiringDate).format('YYYY-MM-DD') : null,
            })
            .then((res) => res.data);
    };

    const { mutateAsync: mutateHiringDate } = useMutation(submitHiringDateChange, {
        onSuccess: () => refetchCard(),
        onError: () => enqueueSnackbar('Erro ao atualizar a data de contratação.', { variant: 'error' }),
    });

    /**
     * CARD CHANGES CONTROL
     */
    useEffect(() => {
        setVehicle(originalCard.vehicle);
    }, [originalCard.vehicle]);

    useEffect(() => {
        setHiringDate(originalCard.hiring_date);
    }, [originalCard.hiring_date]);

    /**
     * RENDER COMPONENT
     */
    return (
        <CardAccordion title={'Informações'}>
            <Stack spacing={2}>
                <TextField size={'small'} label={'Pessoa'} value={originalCard.person.full_name} fullWidth />
                <ApiSelectField
                    size={'small'}
                    label={'Veículo'}
                    endpoint={'/api/vehicles/'}
                    value={vehicle ?? ''}
                    onChange={(event: SyntheticEvent, value: Vehicle) => {
                        setVehicle(value);
                        mutateVehicle();
                    }}
                    multiple={false}
                    getOptionLabel={(op: Vehicle) => (op ? `${op.integration_id} - ${op.license_plate}` : '')}
                />
                <DatePicker
                    label={'Data de contratação'}
                    value={hiringDate ? dayjs(hiringDate) : null}
                    onChange={(date) => {
                        setHiringDate(date?.toDate() ?? null);
                        mutateHiringDate();
                    }}
                    slotProps={{
                        textField: {
                            size: 'small',
                        },
                        actionBar: {
                            actions: ['clear', 'accept'],
                        },
                    }}
                />
            </Stack>
        </CardAccordion>
    );
};

export default DriverSectionInfo;
