import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CardAccordionProps } from './CardAccordion.d';

const CardAccordion = (props: CardAccordionProps) => {
    const { title } = props;

    return (
        <Accordion defaultExpanded variant={'outlined'} sx={{ borderRadius: 1 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
};

export default CardAccordion;
