import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LinkIcon from '@mui/icons-material/NorthEast';
import AddIcon from '@mui/icons-material/Add';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';
import { Columns } from 'src/components/crud/Crud.d';

import { Contact } from './Contacts.d';
import {
    columns as departmentColumns,
    endpoint as departmentEndpoint,
    name as departmentName,
} from 'src/pages/settings/contacts/Departments';

export const endpoint = '/api/contacts/';

export const name = {
    singular: 'Contato',
    plural: 'Contatos',
};

const ContactsPage = () => {
    const navigate = useNavigate();

    const columns = useMemo<Columns<Contact>>(
        () => [
            {
                accessorKey: 'name',
                header: 'Nome',
            },
            {
                accessorKey: 'phone_number',
                header: 'Número de telefone',
            },
            {
                accessorKey: 'department.name',
                header: 'Departamento',
                field: {
                    type: 'search',
                    name: 'department',
                    endpoint: '/api/contacts/departments/',
                    labelKey: 'name',
                    inputAction: {
                        label: <AddIcon />,
                        onClick: () => setOpenDepartment(true),
                    },
                },
            },
            {
                accessorKey: 'role',
                header: 'Cargo',
            },
        ],
        []
    );

    /**
     * DEPARTMENTS MODAL CONTROL
     */
    const [openDepartment, setOpenDepartment] = useState(false);

    const renderDepartmentButton = () => {
        return (
            <Button onClick={() => navigate(AppRoutes.Departments.path)}>
                <LinkIcon />
                <Typography variant={'button'} sx={{ ml: 1 }}>
                    Departamentos
                </Typography>
            </Button>
        );
    };

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb
                        items={[
                            {
                                text: 'Configurações',
                                link: AppRoutes.Settings.path,
                            },
                            'Contatos',
                        ]}
                    />
                </Box>
            </Stack>

            <CrudAPI
                // CRUD INFO
                name={name}
                endpoint={endpoint}
                route={AppRoutes.Contacts}
                columns={columns}
                // ACTIONS
                extraCrudActions={renderDepartmentButton}
            />

            {/** DEPARTMENTS MODAL */}
            <CreateUpdateModal
                // STRUCTURE
                name={departmentName}
                endpoint={departmentEndpoint}
                columns={departmentColumns}
                // MODAL CONTROL
                open={openDepartment}
                setOpen={setOpenDepartment}
            />
        </>
    );
};

export default ContactsPage;
