import { Dispatch, SetStateAction } from 'react';

import { Label, PersonBase } from 'src/pages/kanban/Kanban.d';

export const DATE_FILTER_OPTIONS = ['not_date', 'overdue', 'due_today', 'due_tomorrow', 'due_in_week', 'due_in_month'];

export type DateFilterOption = (typeof DATE_FILTER_OPTIONS)[number];

export interface CardApiFilters {
    labels?: number[];
    responsibles?: number[];
    search?: string;
    closest_date?: string[];
}

export interface CardFilters {
    labels?: Label[];
    responsibles?: PersonBase[];
    search?: string;
    closest_date?: DateFilterOption[];
}

export interface CardFiltersMenuProps {
    apiFilters: CardApiFilters;
    boardId: number;
    setApiFilters: Dispatch<SetStateAction<CardApiFilters>>;
}
