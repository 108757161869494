import React from 'react';

import Box from '@mui/material/Box';

const BlankPage = () => {
    return (
        <Box
            sx={{
                height: '75vh',
            }}
        />
    );
};

export default BlankPage;
