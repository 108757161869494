import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import useTenantAPI from 'src/services/api_tenant';

import CardFab from 'src/pages/kanban/CardFab';

const DashboardPage = () => {
    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    // Slug from url
    const { slug } = useParams();

    /**
     * GET PANEL AND LOAD INTO IFRAME
     */
    const [panelPath, setPanelPath] = useState<string | null>(null);

    useEffect(() => {
        const fetchPanel = async () => {
            try {
                const response = await api_tenant.get(`/api/dashboards/${slug}/my`);
                setPanelPath(response.data.results[0].path);
            } catch (error) {
                enqueueSnackbar('Erro ao carregar painel!', { variant: 'error' });
                window.location.replace('/dashboards/');
            }
        };

        fetchPanel();
    }, [enqueueSnackbar, slug]);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            {!panelPath && (
                <Stack sx={{ height: '100%' }} alignItems={'center'} justifyContent={'center'}>
                    <CircularProgress />
                </Stack>
            )}

            {panelPath && (
                <iframe
                    title={'bi-panel'}
                    width={'100%'}
                    height={'100%'}
                    allowFullScreen={true}
                    src={`${panelPath}&navContentPaneEnabled=false`}
                ></iframe>
            )}

            <CardFab />
        </>
    );
};

export default DashboardPage;
