import React, { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import CreateUpdateModal from 'src/components/crud/modals/CreateUpdateModal';

import { Card } from 'src/pages/kanban/Kanban.d';
import { Column as CrudColumn } from 'src/components/crud/Crud.d';
import { CustomerCardCreateModalProps } from './CustomerCardCreateModal.d';

import {
    columns as enterpriseColumns,
    endpoint as enterpriseEndpoint,
    name as enterpriseName,
} from 'src/pages/registry/enterprises/Enterprises';

const name = {
    singular: 'Card de Cliente',
    plural: 'Cards de Cliente',
};

const formatPayload = (fd: FormData, id: number, values: Record<string, any>) => {
    fd.append('name', values.enterprise.name);
    return fd;
};

const CustomerCardCreateModal = (props: CustomerCardCreateModalProps) => {
    const { boardId, open, setOpen, refetch } = props;

    /**
     * ENTERPRISE MODAL
     */
    const [enterpriseOpen, setEnterpriseOpen] = useState(false);

    /**
     * COLUMNS
     */
    const addCardColumns: CrudColumn<Card>[] = useMemo(
        () => [
            {
                accessorKey: 'enterprise',
                header: 'Empresa',
                field: {
                    type: 'search',
                    name: 'enterprise',
                    endpoint: 'api/businesses/',
                    labelKey: 'name',
                    default: '',
                    inputAction: {
                        label: <AddIcon />,
                        onClick: () => setEnterpriseOpen(true),
                    },
                },
            },
        ],
        []
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            {/** CREATE CARD MODAL */}
            <CreateUpdateModal
                // STRUCTURE
                name={name}
                endpoint={`api/kanban/boards/${boardId}/cards/create/`}
                columns={addCardColumns}
                // MODAL CONTROL
                open={open}
                setOpen={setOpen}
                // ACTIONS
                refetch={refetch}
                formatPayload={formatPayload}
            />

            {/** CREATE ENTERPRISE MODAL */}
            <CreateUpdateModal
                columns={enterpriseColumns}
                open={enterpriseOpen}
                setOpen={setEnterpriseOpen}
                endpoint={enterpriseEndpoint}
                name={enterpriseName}
            />
        </>
    );
};

export default CustomerCardCreateModal;
