import React, { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ListDivider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import useTenantAPI from 'src/services/api_tenant';

import { Label } from 'src/pages/kanban/Kanban.d';
import { LabelsSelectorProps } from './LabelsSelector.d';

const LabelsSelector = (props: LabelsSelectorProps) => {
    const { boardId, selectedLabels: originalCardLabels, onClose, onChange } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * CARD LABELS CONTROL
     */
    const [cardLabels, setCardLabels] = useState<Label[]>(originalCardLabels);

    useEffect(() => {
        setCardLabels(originalCardLabels);
    }, [originalCardLabels]);

    /**
     * LOAD BOARD LABELS
     */
    const fetchLabels = () => {
        return api_tenant.get(`api/kanban/boards/${boardId}/labels/`).then((response) => response.data.results);
    };

    const {
        data: boardLabels,
        isLoading,
        isError,
    } = useQuery<Label[]>({
        queryKey: ['labels', boardId],
        queryFn: fetchLabels,
    });

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Erro ao carregar labels.', { variant: 'error' });
        }
    }, [isError]);

    /**
     * MENU CONTROL
     */
    const [menuOpen, setMenuOpen] = useState(false);
    const buttonRef = useRef(null);

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
        if (onClose) {
            onClose();
        }
    };

    /**
     * CHECKBOX CONTROL
     */
    const handleCheckboxChange = (e: React.MouseEvent, isCheked: boolean, label: Label) => {
        e.stopPropagation();

        onChange(label, !isCheked);

        setCardLabels((oldLabels) => {
            if (isCheked) {
                return oldLabels.filter((l) => l.id !== label.id);
            }

            return [...oldLabels, label];
        });
    };

    return (
        <Stack direction={'column'}>
            <Typography variant={'caption'} children={'Etiquetas'} />
            <Stack
                direction={'row'}
                alignItems={'start'}
                sx={{
                    mb: 1,
                }}
            >
                {cardLabels.map((label) => (
                    <Box
                        key={label.id}
                        sx={{
                            bgcolor: `#${label.color}`,
                            width: '2.5rem',
                            height: '2rem',
                            borderRadius: '0.25rem',
                            mr: 1,
                            '&:hover': {
                                cursor: 'pointer',
                                bgcolor: `#${label.color}99`,
                            },
                        }}
                        onClick={handleMenuOpen}
                    />
                ))}

                <Button
                    variant={'outlined'}
                    onClick={handleMenuOpen}
                    ref={buttonRef}
                    sx={{
                        minWidth: '2.5rem',
                        width: '2.5rem',
                        height: '2rem',
                    }}
                >
                    <AddIcon />
                </Button>
            </Stack>

            <Menu
                open={menuOpen}
                anchorEl={buttonRef.current}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleMenuClose}
            >
                <ListSubheader
                    sx={{
                        my: 0,
                        textAlign: 'center',
                    }}
                    children={'Etiquetas'}
                />

                <ListDivider sx={{ mb: 2 }} />

                {isLoading && <MenuItem disabled={true}>Carregando...</MenuItem>}

                {isError && <MenuItem disabled={true}>Erro ao carregar</MenuItem>}

                {!isLoading &&
                    !isError &&
                    boardLabels.map((label) => {
                        const isChecked = cardLabels.some((l) => l.id === label.id);
                        return (
                            <MenuItem dense onClick={(e) => handleCheckboxChange(e, isChecked, label)} key={label.id}>
                                <ListItemIcon>
                                    <Checkbox size={'small'} checked={isChecked} />
                                </ListItemIcon>
                                <Box
                                    sx={{
                                        bgcolor: `#${label.color}`,
                                        width: '1rem',
                                        height: '1rem',
                                        borderRadius: '0.25rem',
                                    }}
                                />
                                <Box sx={{ ml: 1, mr: 5 }} children={label.name} />
                            </MenuItem>
                        );
                    })}
            </Menu>
        </Stack>
    );
};

export default LabelsSelector;
