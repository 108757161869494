import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';

const PersonsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Cadastros', 'Metas']} />
                </Box>
            </Stack>

            <CrudAPI
                name={{
                    singular: 'Meta',
                    plural: 'Metas',
                }}
                endpoint={'/api/metrics/goals/'}
                route={AppRoutes.Goals}
                columns={[
                    {
                        accessorKey: 'begin',
                        header: 'Início Meta',
                        field: {
                            type: 'date',
                        },
                    },
                    {
                        accessorKey: 'end',
                        header: 'Fim Meta',
                        field: {
                            type: 'date',
                        },
                    },
                    {
                        accessorKey: 'person.full_name',
                        header: 'Pessoa',
                        field: {
                            type: 'search',
                            name: 'person',
                            endpoint: '/api/persons/',
                            labelKey: 'full_name',
                        },
                    },
                    {
                        accessorKey: 'goal',
                        header: 'Meta',
                    },
                ]}
            />
        </>
    );
};

export default PersonsPage;
