import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { Allocation } from './Allocation.d';

export const name = {
    singular: 'Troca de Motorista',
    plural: 'Trocas de Motorista',
};

export const columns: Columns<Allocation> = [
    {
        accessorKey: 'integration_id',
        header: 'ID de Integração',
        filterLookup: 'exact',
    },
    {
        accessorKey: 'person.full_name',
        header: 'Pessoa',
        field: {
            type: 'search',
            name: 'person',
            endpoint: '/api/persons/',
            labelKey: 'full_name',
        },
    },
    {
        accessorKey: 'vehicle.license_plate',
        header: 'Veículo',
        field: {
            type: 'search',
            name: 'vehicle',
            endpoint: '/api/vehicles/',
            labelKey: 'license_plate',
        },
    },
    {
        accessorKey: 'start_date',
        header: 'Data Início',
        field: {
            type: 'datetime',
            default: new Date(),
        },
        enableColumnFilter: false,
    },
    {
        accessorKey: 'end_date',
        header: 'Data Fim',
        field: {
            type: 'datetime',
        },
        enableColumnFilter: false,
    },
];

export const endpoint = '/api/persons/allocations/';

const formatPayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
    const data: Record<string, any> = {};

    if (values.end_date) {
        data.end_date = new Date(values.end_date).toISOString();
    }

    if (values.integration_id) {
        data.integration_id = values.integration_id;
    }

    return {
        ...data,
        person: values.person.id,
        vehicle: values.vehicle.id,
        start_date: new Date(values.start_date).toISOString(),
    };
};

const AllocationsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} pt={4}>
                <Box mb={1}>
                    <Breadcrumb items={['Cadastros', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                name={name}
                endpoint={endpoint}
                route={AppRoutes.Allocations}
                columns={columns}
                formatPayload={formatPayload}
            />
        </>
    );
};

export default AllocationsPage;
