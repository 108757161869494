import React, { Dispatch, SetStateAction } from 'react';

import TextField from '@mui/material/TextField';

import { Achievement } from 'src/pages/registry/achievements';

const DayStreakField = (
    values: Achievement,
    setValues: Dispatch<SetStateAction<Achievement>>,
    errors: any,
    readOnly: boolean
) => {
    if (!(values['has_day_streak'] ?? false)) {
        values['day_streak'] = 0;
        return;
    }

    return (
        <TextField
            type={'number'}
            key={'day-streak-field'}
            label={'Dias Consecutivos'}
            name={'day_streak'}
            value={values['day_streak']}
            disabled={readOnly}
            onChange={(e) => {
                setValues({
                    ...values,
                    day_streak: parseInt(e.target.value),
                });
            }}
            error={errors?.hasOwnProperty('day_streak') ?? false}
            helperText={errors ? errors['day_streak'] ?? '' : ''}
        />
    );
};

export default DayStreakField;
