import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';

import FilterIcon from '@mui/icons-material/FilterList';

import ApiSelectField from 'src/components/ApiSelect';
import LabelsSelector from 'src/pages/kanban/CardModal/LabelsField/LabelsSelector';
import useTenantAPI from 'src/services/api_tenant';

import DateFilterSection from './DateFilterSection';
import { CardFilters, CardFiltersMenuProps, DATE_FILTER_OPTIONS } from './CardFiltersMenu.d';
import { Label, PersonBase } from 'src/pages/kanban/Kanban.d';

const CardFiltersMenu = (props: CardFiltersMenuProps) => {
    const { boardId, apiFilters, setApiFilters } = props;

    const api_tenant = useTenantAPI();

    /**
     * MENU CONTROL
     */
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    /**
     * FILTERS CONTROL
     */
    const [menuFilters, setMenuFilters] = useState<CardFilters>({
        closest_date: apiFilters.closest_date
            ? apiFilters.closest_date.filter((d) => DATE_FILTER_OPTIONS.includes(d as string))
            : [],
    });

    const updateFilters = (newFilters: CardFilters) => {
        setApiFilters({
            ...(newFilters.responsibles ? { responsibles: newFilters.responsibles.map((r) => r.id) } : {}),
            ...(newFilters.labels ? { labels: newFilters.labels.map((l) => l.id) } : {}),
            ...(newFilters.closest_date ? { closest_date: newFilters.closest_date } : {}),
        });
        setOpen(false);
    };

    /**
     * FETCH INITIAL FILTERS
     */
    const fetchInitialResponsibles = () => {
        return api_tenant
            .get('api/persons/', {
                params: { id__in: apiFilters.responsibles?.join(',') },
                paramsSerializer: {
                    indexes: null,
                },
            })
            .then((res) => setMenuFilters((f) => ({ ...f, responsibles: res.data.results })));
    };

    const fetchInitialLabels = () => {
        return api_tenant
            .get(`api/kanban/boards/${boardId}/labels/`, {
                params: { id__in: apiFilters.labels?.join(',') },
                paramsSerializer: {
                    indexes: null,
                },
            })
            .then((res) => setMenuFilters((f) => ({ ...f, labels: res.data.results })));
    };

    useEffect(() => {
        if (apiFilters.responsibles) {
            fetchInitialResponsibles();
        }
        if (apiFilters.labels) {
            fetchInitialLabels();
        }
    }, []);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Button
                variant={'text'}
                color={'primary'}
                startIcon={<FilterIcon />}
                ref={anchorRef}
                onClick={() => setOpen(true)}
                children={'Filtros'}
            />

            <Menu anchorEl={anchorRef.current} open={open} onClose={() => setOpen(false)}>
                <ListSubheader
                    sx={{
                        my: 0,
                        textAlign: 'center',
                    }}
                    children={'Filtros'}
                />
                <Divider sx={{ mb: 2 }} />
                <Stack direction={'column'} spacing={2} padding={2} width={350}>
                    <ApiSelectField
                        size={'small'}
                        multiple={true}
                        label={'Responsáveis'}
                        endpoint={'/api/persons/'}
                        value={menuFilters.responsibles ?? []}
                        onChange={(event: SyntheticEvent, value: PersonBase[]) => {
                            setMenuFilters({
                                ...menuFilters,
                                responsibles: value,
                            });
                        }}
                        getOptionLabel={(op: PersonBase) => op?.full_name ?? ''}
                        queryParams={{
                            is_instructor: true,
                        }}
                    />
                    <LabelsSelector
                        boardId={boardId}
                        selectedLabels={menuFilters.labels ?? []}
                        onChange={(label: Label, isChecked: boolean) => {
                            if (menuFilters.labels === undefined) {
                                menuFilters.labels = [];
                            }

                            setMenuFilters({
                                ...menuFilters,
                                labels: isChecked
                                    ? [...menuFilters.labels, label]
                                    : menuFilters.labels.filter((l) => l.id !== label.id),
                            });
                        }}
                    />
                    <DateFilterSection setMenuFilters={setMenuFilters} menuFilters={menuFilters} />
                    <Stack direction={'row'} justifyContent={'flex-end'} spacing={1}>
                        <Button
                            onClick={() => {
                                setMenuFilters({});
                                updateFilters({});
                            }}
                            children={'Limpar'}
                        />
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                updateFilters(menuFilters);
                            }}
                            children={'Aplicar'}
                        />
                    </Stack>
                </Stack>
            </Menu>
        </>
    );
};

export default CardFiltersMenu;
