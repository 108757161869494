import React from 'react';

import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

import { TData } from 'src/components/crud/Crud.d';
import { ListTableContentRowProps } from './ListTableContentRow.d';

const ListTableContentRow = <T extends TData>(props: ListTableContentRowProps<T>) => {
    const {
        label,
        item,

        // CONTROL
        readOnly = false,

        // HANDLERS
        handleRemoveItem,
        handleUpdateQuantity,
    } = props;

    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell align={'right'}>
                <TextField
                    // STRUCTURE
                    type={'number'}
                    label={'Qtd.'}
                    // CONTROL
                    disabled={readOnly}
                    value={item.quantity}
                    // VISUAL
                    size={'small'}
                    sx={{
                        width: '75px',
                    }}
                    // HANDLERS
                    onChange={(e) => {
                        handleUpdateQuantity(item.product.id, e.target.value ? parseInt(e.target.value) : 0);
                    }}
                />
            </TableCell>
            <TableCell align={'right'}>
                <IconButton onClick={() => handleRemoveItem(item.product.id)} disabled={readOnly}>
                    <RemoveCircleOutline color={'error'} />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default ListTableContentRow;
