import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import CrudAPI from 'src/components/crud/CrudPage';
import Breadcrumb from 'src/components/Breadcrumb';

const GroupsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Cadastros', 'Grupos']} />
                </Box>
            </Stack>

            <CrudAPI
                name={{
                    singular: 'Grupo',
                    plural: 'Grupos',
                }}
                route={AppRoutes.Groups}
                endpoint={'/api/persons/groups/'}
                columns={[
                    {
                        accessorKey: 'name',
                        header: 'Nome',
                    },
                ]}
            />
        </>
    );
};

export default GroupsPage;
