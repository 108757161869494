import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import getNestedValue from 'src/components/utils/getNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';

import { TData } from 'src/components/crud/Crud.d';

import { CrudSelectProps } from './CrudSelect.d';

const CrudSelect = <T extends TData>(props: CrudSelectProps<T>) => {
    const { column, values, setValues, errors, readOnly = false } = props;

    const handleChange = (event: SelectChangeEvent) => {
        setValues(setNestedValue(values, column.accessorKey, event.target.value));
    };

    const options = column.field.options;

    return (
        <FormControl fullWidth key={column.accessorKey}>
            <InputLabel id={column.accessorKey} children={column.header} />
            <Select
                id={column.accessorKey}
                labelId={column.accessorKey}
                value={getNestedValue(values, column.accessorKey, '')}
                label={column.header}
                disabled={readOnly}
                onChange={handleChange}
                error={hasNestedValue(errors, column.accessorKey)}
            >
                {Object.keys(options).map((option) => {
                    return <MenuItem key={option} value={option} children={options[option]} />;
                })}
            </Select>
            {hasNestedValue(errors, column.accessorKey) && (
                <FormHelperText children={getNestedValue(errors, column.accessorKey, '')} />
            )}
        </FormControl>
    );
};

export default CrudSelect;
