import React from 'react';

import BlockedIcon from '@mui/icons-material/TaskAlt';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import styled from '@mui/material/styles/styled';

import CardLabels from 'src/pages/kanban/Card/CardLabels';
import CardDateAlert from 'src/pages/kanban/Card/CardAlert';

import { CardContentProps } from './CardContent.d';

const StyledMuiCardContent = styled(MuiCardContent)(({ theme }) => ({
    padding: 0,
    '&:last-child': {
        paddingBottom: 0,
    },
}));

const CardContent = (props: CardContentProps) => {
    const { card, isDragging = false, onClick } = props;

    return (
        <MuiCard
            onClick={onClick}
            elevation={isDragging ? 3 : 1}
            sx={{
                p: 1.5,
                mb: 1,
                minHeight: 80,
                cursor: 'pointer',
                opacity: isDragging ? 0.9 : 1,
                transform: isDragging ? 'rotate(-2deg)' : '',
            }}
        >
            <StyledMuiCardContent>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                    <CardLabels labels={card.labels} />
                    {card.checklist_count > 0 && (
                        <Stack direction={'row'} alignItems={'center'} spacing={0.25}>
                            <Typography
                                fontSize={10}
                                variant={'caption'}
                                children={`${card.checklist_complete_count}/${card.checklist_count}`}
                                sx={{
                                    color: '#888',
                                }}
                            />
                            <Tooltip
                                arrow
                                title={card.has_incomplete_checklists && 'Existem itens de checklist não concluídos'}
                            >
                                <BlockedIcon
                                    color={card.has_incomplete_checklists ? 'warning' : 'success'}
                                    sx={{
                                        fontSize: 15,
                                    }}
                                />
                            </Tooltip>
                        </Stack>
                    )}
                </Stack>
                <Typography variant={'body2'} fontWeight={500} children={card.name} />
                {card.subtitle && (
                    <Typography
                        color={'text.secondary'}
                        sx={{
                            fontSize: 10,
                        }}
                        children={card.subtitle}
                    />
                )}
                {card.closest_date && <CardDateAlert date={card.closest_date} />}
            </StyledMuiCardContent>
        </MuiCard>
    );
};

export default CardContent;
