import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ImageIcon from '@mui/icons-material/Image';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ToolTip from '@mui/material/Tooltip';

import UpArrowIcon from '@mui/icons-material/KeyboardArrowUp';
import DownArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import useTenantAPI from 'src/services/api_tenant';
import AppRoutes from 'src/routes/appRoutes';
import { getRoutePermissions } from 'src/services/storage/permissions';

import { PhotosModalProps, ProductPhoto } from 'src/pages/marketplace/Products/PhotosModal';

const PhotosModal = (props: PhotosModalProps) => {
    const { open, onClose, modalEntity } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    const closePhotos = () => {
        onClose();
        setPhotos([]);
    };

    /**
     * USER PERMISSIONS
     */
    const { canCreate, canUpdate, canDelete } = getRoutePermissions(AppRoutes.ProductsPhotos);

    /**
     * PHOTO UPLOAD LOAD PHOTOS
     */
    const [photos, setPhotos] = useState<ProductPhoto[]>([]);
    const [photosLoad, setPhotosLoad] = useState(false);

    useEffect(() => {
        setPhotosLoad(true);
    }, [open]);

    const reloadPhotos = (showLoad = true) => {
        if (modalEntity) {
            if (showLoad) {
                setPhotosLoad(true);
            }
            api_tenant
                .get(`/api/marketplace/products/${modalEntity.id}/photos/`)
                .then((res) => {
                    setPhotos(res.data.results);
                })
                .catch((err) => {
                    enqueueSnackbar('Erro ao carregar as fotos', { variant: 'error' });
                })
                .finally(() => {
                    setPhotosLoad(false);
                });
        }
    };

    useEffect(reloadPhotos, [modalEntity]);

    /**
     * PHOTO UPLOAD
     */
    const [photoErrors, setPhotoErrors] = useState<Record<string, any>>({});

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        setPhotosLoad(true);

        let formData = new FormData();

        for (let i = 0; i < e.target.files.length; i++) {
            formData.append('images', e.target.files[i]);
        }

        api_tenant
            .post(`/api/marketplace/products/${modalEntity.id}/photos/`, formData)
            .then((res) => {
                enqueueSnackbar('Foto enviada com sucesso', { variant: 'success' });
                setPhotoErrors({});
            })
            .catch((err) => {
                setPhotoErrors(err.response.data);
                enqueueSnackbar('Erro ao enviar a foto', { variant: 'error' });
            })
            .finally(() => {
                setPhotosLoad(false);
                reloadPhotos(false);
            });
    };

    const handleDelete = (photo: ProductPhoto) => {
        setPhotosLoad(true);

        api_tenant
            .delete(`/api/marketplace/products/${modalEntity.id}/photos/${photo.id}/`)
            .then((res) => {
                enqueueSnackbar('Foto removida com sucesso', { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar('Erro ao remover a foto', { variant: 'error' });
            })
            .finally(() => {
                setPhotosLoad(false);
                reloadPhotos(false);
            });
    };

    /**
     * PHOTO ORDER
     */
    const handleOrderChange = (photo: ProductPhoto, direction: number) => {
        setPhotosLoad(true);

        api_tenant
            .patch(`/api/marketplace/products/${modalEntity.id}/photos/${photo.id}/`, {
                order_direction: direction,
            })
            .catch((err) => {
                enqueueSnackbar('Erro ao reordenar a foto', { variant: 'error' });
            })
            .finally(() => {
                setPhotosLoad(false);
                reloadPhotos(false);
            });
    };

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle textAlign={'center'}>Fotos do Produto "{modalEntity?.name}"</DialogTitle>
            <DialogContent sx={{ pt: 1 }}>
                <FormGroup>
                    <input
                        type={'file'}
                        id={'upload-badge'}
                        accept={'.jpg, .jpeg, .png'}
                        onChange={handleUpload}
                        disabled={photosLoad || !canCreate}
                        multiple
                        hidden
                    />
                    <label
                        htmlFor={'upload-badge'}
                        onClick={(e) => {
                            if (e.target !== e.currentTarget) e.currentTarget.click();
                        }}
                    >
                        <Button
                            component={'label'}
                            variant={'contained'}
                            color={'primary'}
                            size={'large'}
                            fullWidth={true}
                            disabled={photosLoad || !canCreate}
                        >
                            {photosLoad ? (
                                <>
                                    <CircularProgress size={'small'} /> Enviando...
                                </>
                            ) : (
                                <>
                                    <ImageIcon sx={{ mr: 1 }} /> Inserir Imagem
                                </>
                            )}
                        </Button>
                    </label>
                    {photoErrors && photoErrors.hasOwnProperty('image') && (
                        <Typography
                            variant={'caption'}
                            display={'block'}
                            gutterBottom={true}
                            sx={{
                                color: '#d32f2f',
                                pt: 1,
                            }}
                            children={photoErrors['image']}
                        />
                    )}
                </FormGroup>
                <Grid
                    container
                    direction={'row'}
                    spacing={2}
                    sx={{
                        mt: 2,
                        ml: 0,
                        p: 1,
                        pb: 2.5,
                        width: '100%',
                        minHeight: '200px',
                        border: '1px solid #d0d0d0',
                        borderRadius: '4px',
                        maxHeight: '50vh',
                        overflowY: 'auto',
                    }}
                >
                    {photos.length !== 0 &&
                        photos.map((photo) => (
                            <Grid item key={photo.id} columns={2} xs={6}>
                                <Card raised={true}>
                                    <CardMedia component={'img'} height={'200'} image={photo.image} />
                                    <CardActions
                                        sx={{
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box>
                                            <ToolTip title={'Subir Ordem'}>
                                                <span>
                                                    <IconButton
                                                        size={'small'}
                                                        children={<UpArrowIcon />}
                                                        disabled={!canUpdate}
                                                        onClick={() => {
                                                            handleOrderChange(photo, -1);
                                                        }}
                                                    />
                                                </span>
                                            </ToolTip>
                                            <ToolTip title={'Descer Ordem'}>
                                                <span>
                                                    <IconButton
                                                        size={'small'}
                                                        children={<DownArrowIcon />}
                                                        disabled={!canUpdate}
                                                        onClick={() => {
                                                            handleOrderChange(photo, 1);
                                                        }}
                                                    />
                                                </span>
                                            </ToolTip>
                                        </Box>
                                        <ToolTip title={'Remover Foto'}>
                                            <span>
                                                <IconButton
                                                    color={'error'}
                                                    size={'small'}
                                                    disabled={!canDelete}
                                                    onClick={() => {
                                                        handleDelete(photo);
                                                    }}
                                                    children={<RemoveIcon />}
                                                />
                                            </span>
                                        </ToolTip>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}

                    {!photosLoad && photos.length === 0 && (
                        <Stack
                            direction={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{
                                py: 5,
                                width: '100%',
                            }}
                        >
                            <Typography
                                variant={'h6'}
                                sx={{
                                    color: '#a0a0a0',
                                }}
                            >
                                Nehuma Imagem
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#808080',
                                }}
                            >
                                As imagens deste produto irão aparecer aqui.
                            </Typography>
                        </Stack>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={closePhotos}>Voltar</Button>
            </DialogActions>
            {photosLoad && (
                <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    height={'100%'}
                    sx={{
                        zIndex: '1000',
                        position: 'absolute',
                        backgroundColor: '#FFFFFF99',
                    }}
                >
                    <CircularProgress />
                </Stack>
            )}
        </Dialog>
    );
};

export default PhotosModal;
