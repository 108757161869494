import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';

export const endpoint = '/api/contacts/departments/';

export const name = {
    singular: 'Departamento',
    plural: 'Departamentos',
};

export const columns = [
    {
        accessorKey: 'name',
        header: 'Nome',
    },
];

const DepartmentsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb
                        items={[
                            {
                                text: 'Configurações',
                                link: AppRoutes.Settings.path,
                            },
                            {
                                text: 'Contatos',
                                link: AppRoutes.Contacts.path,
                            },
                            name.plural,
                        ]}
                    />
                </Box>
            </Stack>

            <CrudAPI
                // CRUD INFO
                name={name}
                endpoint={endpoint}
                route={AppRoutes.Departments}
                columns={columns}
            />
        </>
    );
};

export default DepartmentsPage;
