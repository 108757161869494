import React from 'react';

import TextField from '@mui/material/TextField';

import getNestedValue from 'src/components/utils/getNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';

import { TData } from 'src/components/crud/Crud.d';
import { CrudTextProps } from './CrudText.d';

const CrudText = <T extends TData>(props: CrudTextProps<T>) => {
    const { column, values, setValues, errors, readOnly = false, size = 'medium' } = props;

    return (
        <TextField
            autoComplete={'new-password'} // Forced disable of browser autocomplete
            type={column.field?.type ?? 'text'}
            label={column.header}
            name={column.accessorKey}
            value={getNestedValue(values, column.accessorKey, '')}
            multiline={column.field && 'multiline' in column.field && (column.field?.multiline ?? false)}
            size={size}
            disabled={readOnly}
            onChange={(e) => setValues(setNestedValue(values, column.accessorKey, e.target.value))}
            error={hasNestedValue(errors, column.accessorKey)}
            helperText={getNestedValue(errors, column.accessorKey, '')}
            InputProps={column.field?.InputProps && column.field.InputProps}
            inputProps={column.field?.inputProps && column.field?.inputProps}
        />
    );
};

export default CrudText;
