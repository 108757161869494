import React, { useEffect, useState } from 'react';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import AddField, { ListItem } from './AddField';
import ListTable from './ListTable/ListTable';

import { CrudMultiSelectProps } from './CrudMultiSelect.d';
import { TData } from 'src/components/crud/Crud.d';

const CrudMultiSelect = <T extends TData>(props: CrudMultiSelectProps<T>) => {
    const { values, setValues, errors, readOnly = false, column } = props;

    const [load, setLoad] = useState<boolean>(false);
    const [items, setItems] = useState<ListItem<T>[]>([]);

    useEffect(() => {
        setItems(values[column.accessorKey]);
    }, [values]);

    const updateItems = (items: ListItem<T>[]) => {
        setValues((values) => {
            return {
                ...values,
                [column.accessorKey]: items,
            };
        });
    };

    return (
        <Stack
            sx={{
                width: '100%',
                gap: '1.5rem',
            }}
        >
            <AddField
                // CONTROL
                errors={errors}
                readOnly={readOnly}
                load={load}
                items={items}
                // STRUCTURE
                header={column.header}
                name={column.field.name}
                endpoint={column.field.endpoint}
                labelKey={column.field.labelKey}
                // HANDLERS
                updateItems={updateItems}
            />

            <Container
                disableGutters={true}
                sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor: 'grey.500',
                    overflow: 'auto',
                    height: '40vh',
                }}
            >
                <ListTable
                    // CONTROL
                    items={items}
                    setItems={setItems}
                    load={load}
                    labelKey={column.field.item.labelKey}
                    readOnly={readOnly}
                    // STRUCTURE
                    header={column.header}
                    // HANDLERS
                    updateItems={updateItems}
                />
            </Container>
        </Stack>
    );
};

export default CrudMultiSelect;
