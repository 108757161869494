import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import useTenantAPI from 'src/services/api_tenant';

import { CardTitleProps } from './CardTitle.d';

const CardTitle = (props: CardTitleProps) => {
    const { boardId, cardId, name: originalName, refetchCard } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * EDITING CONTROL
     */
    const [isEditing, setIsEditing] = useState(false);

    /**
     * DESCRIPTION FIELD CONTROL
     */
    const [name, setName] = useState(originalName);

    useEffect(() => {
        if (originalName !== name) {
            setName(originalName);
        }
    }, [originalName]);

    /**
     * DESCRIPTION SAVE
     */
    const submitCardLabels = async () => {
        return await api_tenant
            .patch(`api/kanban/boards/${boardId}/cards/${cardId}/`, {
                name: name,
            })
            .then((response) => response.data);
    };

    const { mutateAsync, isLoading } = useMutation(submitCardLabels, {
        onError: () => {
            enqueueSnackbar('Erro ao atualizar nome.', { variant: 'error' });
        },
        onSuccess: () => {
            setIsEditing(false);
            refetchCard();
        },
    });

    /**
     * RENDER COMPONENT
     */
    if (isEditing) {
        return (
            <Stack
                spacing={1}
                direction={'row'}
                alignItems={'center'}
                sx={{
                    width: '100%',
                }}
            >
                <TextField
                    autoFocus
                    fullWidth
                    size={'small'}
                    variant={'outlined'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Button
                    size={'small'}
                    variant={'contained'}
                    startIcon={isLoading && <CircularProgress size={16} />}
                    disabled={isLoading}
                    onClick={() => mutateAsync()}
                    sx={{
                        px: 2,
                    }}
                    children={'Salvar'}
                />
                <Button
                    size={'small'}
                    variant={'outlined'}
                    onClick={() => {
                        setIsEditing(false);
                        setName(originalName);
                    }}
                    sx={{
                        px: 2,
                    }}
                    children={'Cancelar'}
                />
            </Stack>
        );
    }

    return (
        <Box onClick={() => setIsEditing(true)}>
            <Typography
                variant={'h6'}
                fontWeight={500}
                sx={{
                    whiteSpace: 'pre-wrap',
                }}
                children={name}
            />
        </Box>
    );
};

export default CardTitle;
